import {
  Component,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { NavLink as RouterNavLink, useNavigate } from 'react-router-dom'
import { Accordion, Image, Sidebar as SemanticSidebar } from 'semantic-ui-react'
import styled from 'styled-components'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { identifyUser } from '../../services/heapService'
import { fetchCurrentUserDetailsIfNeeded } from '../../actions/userActions'
import {
  getCurrentUser,
  getUserIsAdmin,
  selectIsUserMembershipPendingOrUnpaid,
  getFreeTrialStatus,
  isFreeTrialPromoCode,
} from '../../selectors/user.selectors'
import { Colors, Fonts } from '../../styles/theme'
import NavLink from './NavLink'
import { MESSAGES_ROUTE_KEY, useSidebarConfig } from './config'
import { useReselector, useSidebar } from '../../utils/sharedHooks'
import { inOperator, useAppDispatch } from '../../utils/typeHelpers'
import { SidebarConfigProps } from '../../reducers/sidebar.slice'
import { IconButton, Text, Dropdown } from '../BaseComponents'
import MobileMenu from './MobileMenu'
import {
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../utils/deviceWidthHelpers'
import { logoutUser } from '../../actions/authActions'
import { useAnalyticsReset } from '../../features/Amplitude'
import { clearOpenFeatureTargetingKey } from '../../features/OpenFeature'
import { fetchFinancialAdvisoryProfileIfNeeded } from '../../features/FinancialAdvisory/financialAdvisory.slice'
import { TrialStatus } from '../../reducers/auth/userReducer'
import FreeTrialBanner from '../../features/Signup/FreeTrialBanner'

interface SidebarProps {
  config: { [key: string]: SidebarConfigProps }
}

interface NavListProps extends SidebarProps {
  count?: number
  isUserMembershipPendingOrUnpaid?: boolean
}

const NavListItem = ({
  pageKey,
  end,
  exact,
  icon,
  label,
  match,
  ornament,
  pages,
  to,
  topRoute,
  sublabel,
}: SidebarConfigProps & Omit<NavListProps, 'config'> & { pageKey: string }) => {
  const { sidebarState, toggleCategory } = useSidebar()
  const isUserMembershipPendingOrUnpaid = useReselector(
    selectIsUserMembershipPendingOrUnpaid
  )

  const open = inOperator(pageKey, sidebarState) ? sidebarState[pageKey] : false

  const pagesConfig = useMemo(() => {
    return pages
      ? {
          pages,
          onClick: () => toggleCategory(pageKey),
          open,
          topRoute,
        }
      : {}
  }, [pageKey, open, pages, toggleCategory, topRoute])

  return (
    <NavLink
      {...(pageKey !== MESSAGES_ROUTE_KEY && {
        disabled: isUserMembershipPendingOrUnpaid,
      })}
      icon={icon}
      key={`NavLink_${pageKey}`}
      {...(sublabel && { sublabel })}
      {...(ornament && { ornament })}
      {...(to ? { end, exact, match, to } : pagesConfig)}
    >
      {label}
    </NavLink>
  )
}

const NavList = ({ config, ...rest }: NavListProps) => (
  <>
    {Object.entries(config).map(([key, val]) => (
      <NavListItem key={key} {...val} {...rest} pageKey={key} />
    ))}
  </>
)

interface Props {
  className?: string
}

const AccountDropdown = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useReselector(getCurrentUser)
  const resetAnalytics = useAnalyticsReset()

  const handleLogoutClick = useCallback(async () => {
    resetAnalytics()
    await logoutUser()(dispatch)
    localStorage.removeItem('targetLink')
    await clearOpenFeatureTargetingKey()
    navigate('/login')
  }, [dispatch, navigate, resetAnalytics])

  return (
    <Dropdown
      direction="right"
      inline
      color="white"
      $iconColor="green"
      style={{ marginBottom: 32 }}
      options={[
        {
          icon: 'setting',
          key: 'topbar-settings',
          onClick: () => navigate('/accounts'),
          selected: false,
          text: 'Settings',
        },
        {
          icon: 'log out',
          key: 'topbar-logout',
          onClick: handleLogoutClick,
          selected: false,
          text: 'Logout',
        },
      ]}
      trigger={
        <Text
          color="white"
          style={{ paddingLeft: 12, marginBottom: 0, marginRight: 8 }}
        >{`${user?.firstName} ${user?.lastName}`}</Text>
      }
      variant="text"
    />
  )
}

const Sidebar = ({ className }: Props) => {
  const dispatch = useAppDispatch()
  const isAdmin = useReselector(getUserIsAdmin)
  const user = useReselector(getCurrentUser)
  const config = useSidebarConfig()
  const isDesktopWidth = useIsDeviceWidth(DeviceWidthCombo.desktop)
  const trialStatus = useReselector(getFreeTrialStatus)
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  const { open: sidebarOpen, setSidebarState, toggleSidebar } = useSidebar()

  const scrollRef = useRef<
    Component<SidebarProps> & { ref: RefObject<HTMLDivElement> }
  >(null)

  useEffect(() => {
    identifyUser(user)
  }, [user])

  useEffect(() => {
    dispatch(fetchCurrentUserDetailsIfNeeded())
    dispatch(fetchFinancialAdvisoryProfileIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (config) {
      setSidebarState(config, isAdmin)
    }
  }, [config, isAdmin, setSidebarState])

  const sidebarProps =
    isDesktopWidth || sidebarOpen
      ? { width: sidebarOpen ? ('wide' as const) : ('thin' as const) }
      : { visible: false }

  return (
    <SemanticSidebar
      as={Accordion}
      animation="push"
      className={className}
      direction="left"
      visible
      ref={scrollRef}
      {...sidebarProps}
    >
      <div className={`headerLogo ${isDesktopWidth ? 'desktop' : 'mobile'}`}>
        {sidebarOpen && (
          <RouterNavLink to="/dashboard">
            {isDesktopWidth ? (
              <Image
                src="https://heard-images.s3.us-west-1.amazonaws.com/heard_logo_white_2x.png"
                alt="heard logo"
                className="heard-full-logo-image"
              />
            ) : (
              <MobileMenu />
            )}
          </RouterNavLink>
        )}
        <IconButton
          className="hideSidebarIcon"
          color="gray"
          icon={sidebarOpen ? solid('angles-left') : solid('angles-right')}
          onClick={() => toggleSidebar()}
          size="lg"
        />
      </div>
      {sidebarOpen && (
        <>
          <br />
          <AccountDropdown />
          {config && <NavList config={config} />}
          <div className="spacerDiv" />
          {trialStatus === TrialStatus.active && !hasFreeTrialPromoCode && (
            <FreeTrialBanner className="trialBanner" />
          )}
        </>
      )}
      <div style={{ height: 16 }} />
    </SemanticSidebar>
  )
}

interface SidebarComponent extends FC<Props> {
  Pushable: typeof SemanticSidebar.Pushable
  Pusher: typeof SemanticSidebar.Pusher
}

const StyledSidebar: SidebarComponent = styled((props: Props) => (
  <Sidebar {...props} />
))({
  '&&&&': {
    border: 0,
    margin: 0,
    boxShadow: 'none',
    backgroundColor: Colors.forest,
    padding: 16,
    transition: 'all 0.2s',
    width: 275,
    display: 'flex',
    flexDirection: 'column',

    '.header': {
      ...Fonts.eyebrow,
      color: Colors.blush,
      margin: '12px 0',
    },

    '&.thin': {
      width: 64,

      '.headerLogo': {
        paddingLeft: 8,
      },
    },

    '.headerLogo': {
      ...Fonts.eyebrow,
      alignItems: 'center',
      color: Colors.blush,
      display: 'flex',
      justifyContent: 'space-between',
      margin: '11px 0',
      paddingLeft: 12,

      '&.mobile': {
        margin: '8px 0',
      },

      '.heard-full-logo-image': {
        display: 'initial',
        width: 64,
      },
    },

    '.hideSidebarIcon': {
      marginRight: 12,
    },

    '.messagesLabel': {
      color: Colors.green,
      backgroundColor: Colors.white,
    },

    '.taxesItem': {
      display: 'flex',
      justifyContent: 'space-between',
    },

    '.spacerDiv': {
      flexGrow: 1,
    },

    '.trialBanner': {
      padding: '16px 24px',
      marginRight: 8,
      minHeight: 115,
    },
  },
})

StyledSidebar.Pushable = SemanticSidebar.Pushable
StyledSidebar.Pusher = SemanticSidebar.Pusher

export default StyledSidebar
