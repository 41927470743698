import { Divider, Grid, Image } from 'semantic-ui-react'
import {
  Card,
  GridRowColumn,
  Text,
  Icon,
  Button,
  Link,
} from '../../../../components/BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

const RecommendedForYou = () => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Text as="h1">Recommended for You</Text>
          <Text as="bodyMd">
            Resources to help you build and grow your practice.
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Card.Group itemsPerRow={3}>
        <Card
          type="section"
          backgroundColor="stone40"
          style={{ boxShadow: 'none', border: 'none' }}
        >
          <Card.Content>
            <Grid>
              <GridRowColumn centerContent>
                <Image
                  src="https://heard-images.s3.amazonaws.com/assets/recommendation_star.svg"
                  alt="image"
                  style={{
                    width: '100%',
                  }}
                />
              </GridRowColumn>
              <GridRowColumn>
                <Text as="eyebrow" color="darkGray">
                  article
                </Text>
                <Text as="h2">Complete list of tax deductions</Text>
                <br />
                <Text as="bodySm">
                  A comprehensive list of possible tax deductions for
                  self-employed therapists to help you get started
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Link
                  newPage
                  href={
                    'https://www.joinheard.com/articles/the-complete-list-of-tax-deductions-for-therapists'
                  }
                >
                  <Button onClick={() => {}} className="secondary" size="small">
                    Learn more
                    <Icon
                      icon={regular('arrow-right')}
                      style={{ marginLeft: 8 }}
                    />
                  </Button>
                </Link>
              </GridRowColumn>
            </Grid>
          </Card.Content>
        </Card>
        <Card
          type="section"
          backgroundColor="stone40"
          style={{ boxShadow: 'none', border: 'none' }}
        >
          <Card.Content>
            <Grid>
              <GridRowColumn centerContent>
                <Image
                  src="https://heard-images.s3.amazonaws.com/assets/recommendation_webinar.svg"
                  alt="image"
                  style={{
                    width: '100%',
                  }}
                />
              </GridRowColumn>
              <GridRowColumn>
                <Text as="eyebrow" color="darkGray">
                  webinar
                </Text>
                <Text as="h2">Bookkeeping and Taxes for Therapists</Text>
                <br />
                <Text as="bodySm">
                  Learn the fundamentals of bookkeeping and taxes for your
                  private practice.
                </Text>
              </GridRowColumn>

              <GridRowColumn>
                <Link
                  newPage
                  href={
                    'https://www.youtube.com/watch?v=cHoKXWRvtJs&ab_channel=Heard'
                  }
                >
                  <Button onClick={() => {}} className="secondary" size="small">
                    Learn more
                    <Icon
                      icon={regular('arrow-right')}
                      style={{ marginLeft: 8 }}
                    />
                  </Button>
                </Link>
              </GridRowColumn>
            </Grid>
          </Card.Content>
        </Card>
        <Card
          type="section"
          backgroundColor="stone40"
          style={{ boxShadow: 'none', border: 'none' }}
        >
          <Card.Content>
            <Grid>
              <GridRowColumn centerContent>
                <Image
                  src="https://heard-images.s3.amazonaws.com/assets/recommendation_article.svg"
                  alt="image"
                  style={{
                    width: '100%',
                  }}
                />
              </GridRowColumn>
              <GridRowColumn>
                <Text as="eyebrow" color="darkGray">
                  article
                </Text>
                <Text as="h2">
                  What to do if you miss a quarterly tax deadline
                </Text>
                <br />
                <Text as="bodySm">
                  Read on to learn what happens if you&apos;re late paying
                  estimate taxes, and what you can do to fix the problem
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Link
                  newPage
                  href={
                    'https://www.joinheard.com/articles/what-to-do-if-you-miss-a-quarterly-tax-deadline-for-your-therapy-practice'
                  }
                >
                  <Button onClick={() => {}} className="secondary" size="small">
                    Learn more
                    <Icon
                      icon={regular('arrow-right')}
                      style={{ marginLeft: 8 }}
                    />
                  </Button>
                </Link>
              </GridRowColumn>
            </Grid>
          </Card.Content>
        </Card>
      </Card.Group>
      <GridRowColumn>
        <Button variant="secondaryLink" color="green">
          View Resource Hub{' '}
          <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
        </Button>
      </GridRowColumn>
    </Grid>
  )
}

export default RecommendedForYou
