import { useCallback } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { ONBOARDING_V2_ROUTE_NAMES, OnboardingTaskIdents } from '../config'
import { UploadPrevMonthStatementStatus } from '../../Dashboard/UserActionItems/userActionItemStatuses'
import { markUserActionItemCompleteIfExists } from '../../Dashboard/UserActionItems/service'
import { useAnalyticsTrack } from '../../Amplitude'
import { useNavigate } from 'react-router-dom'

export const SkipUploadStatementsModal = ({
  closeModal,
}: {
  closeModal: () => void
}) => {
  const track = useAnalyticsTrack()
  const navigate = useNavigate()

  const handleSkipClick = useCallback(async () => {
    track('completed onboarding item upload statements', { status: 'skipped' })
    await markUserActionItemCompleteIfExists(
      OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS,
      track,
      UploadPrevMonthStatementStatus.skipped
    )
    navigate(ONBOARDING_V2_ROUTE_NAMES.ONBOARDING_COMPLETE)
  }, [track, navigate])

  const handleCancelClick = () => {
    track('cancelled skip upload statements')
    closeModal()
  }

  return (
    <Modal closeIcon onClose={handleCancelClick} open size="small">
      <Modal.Header>Are you sure you want to skip?</Modal.Header>
      <Modal.Content>
        <Card
          style={{
            backgroundColor: Colors.lightRed,
          }}
        >
          <Grid>
            <GridRowColumn short>
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/warning-logo.png"
                style={{
                  width: 80,
                  height: 80,
                }}
              />
            </GridRowColumn>
            <GridRowColumn>
              <Text>
                By uploading your bank statements, you can make the most of
                Heard:
              </Text>
            </GridRowColumn>
            <GridRowColumn
              style={{
                paddingTop: 0,
              }}
            >
              <Text>
                <ul>
                  <li>Your catch-up bookkeeping will be completed</li>
                  <li>You can receive quarterly tax estimates</li>
                  <li>
                    You&apos;ll have a clear picture of your practice&apos;s
                    finances
                  </li>
                </ul>
              </Text>
            </GridRowColumn>
          </Grid>
        </Card>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancelClick} variant="actionLink">
          Go Back
        </Button>
        <Button onClick={handleSkipClick} variant="warning">
          Skip
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
