import AhaMomentsBaseComponent from '../AhaMomentsBaseComponent'
import { Link, Text } from '../../../../components/BaseComponents'
import { Image } from 'semantic-ui-react'

const QuarterlyTaxes = ({
  handleContinue,
  handleBack,
}: {
  handleContinue: () => void
  handleBack: () => void
}) => {
  return (
    <AhaMomentsBaseComponent
      title="Get ready for taxes"
      subtitle="How do quarterly taxes work?"
      description="Because you own your own therapy business, you will likely need to make estimated tax payments four times a year. This is standard practice and required for any self-employed individual."
      media={
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/calculator_block.svg"
          style={{
            width: '100%',
          }}
        />
      }
      features={[
        {
          imgSrc: 'https://heard-images.s3.amazonaws.com/assets/quarters.svg',
          title: 'Do I need to pay quarterly taxes?',
          description:
            'Yes, anyone who is self-employed and expects to owe more than $1,000 in taxes at the end of the fiscal year. This is true even if you are also working for another employer.',
        },
        {
          imgSrc:
            'https://heard-images.s3.amazonaws.com/assets/tax_checklist.svg',
          title: 'How do I get a quarterly tax estimate?',
          description: (
            <>
              <Text>
                We provide your federal and state tax estimates in Heard once
                you have done the following:
              </Text>
              <Text>
                <ul
                  style={{
                    paddingLeft: '20px',
                    margin: 0,
                  }}
                >
                  <li>Completed your Quarterly Tax Checklist</li>
                  <li>Clarified all outstanding transactions</li>
                  <li>Uploaded all missing monthly statements</li>
                </ul>
              </Text>
            </>
          ),
        },
      ]}
      learnMoreItems={[
        {
          title: 'What are quarterly tax estimates?',
          content: (
            <Text>
              Four times a year, we utilize your therapy income to help
              determine how much you owe in estimated tax each quarter. The
              quarterly estimated tax is paid toward your tax bill in quarterly
              installments towards federal and state taxes.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/4416208037655-What-are-quarterly-tax-estimates"
                newPage
                as="secondaryLink"
              >
                Learn more
              </Link>
            </Text>
          ),
        },
        {
          title: 'How do I pay federal quarterly taxes?',
          content: (
            <Text>
              You can either make a payment via IRS Direct Pay online, or send a
              check or money order.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/4402339575319-How-to-pay-federal-quarterly-tax-estimates"
                newPage
                as="secondaryLink"
              >
                Learn more
              </Link>
              <br />
              <br />
              We recommend paying from your personal bank account or card, since
              quarterly estimated tax is paid towards your individual income
              tax, and considered a personal expense.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/4416233114647-Should-I-pay-quarterly-taxes-from-my-personal-or-business-bank-account"
                newPage
                as="secondaryLink"
              >
                Learn more
              </Link>
            </Text>
          ),
        },
        {
          title: 'What if I can’t afford to pay taxes for a quarter?',
          content: (
            <Text>
              The IRS expects you to pay estimated tax in full each quarter.
              Underpaying could result in an underpayment penalty.
              <br />
              <br />
              You can pay a partial amount, but this still carries the risk of a
              penalty. The &quot;minimum&quot; amount you want to make sure
              you&apos;ve paid by the end of the year is:
              <ul>
                <li>90% of the total taxes (before credits) you owe</li>
                <li>
                  100% of what you paid in taxes last year (before credits)
                </li>
              </ul>
              <Link
                href="https://www.joinheard.com/articles/what-to-do-if-you-cant-pay-your-quarterly-taxes-as-a-therapist"
                newPage
                as="secondaryLink"
              >
                Learn more
              </Link>
            </Text>
          ),
        },
        {
          title:
            'What if I didn’t pay my quarterly taxes in prior quarters this year?',
          content: (
            <Text>
              Based on how much you have paid in prior quarters, the estimate we
              calculate for you will &quot;true up&quot; how much you owe - in
              other words, it&apos;ll include any amount you owed for previous
              quarters. But keep in mind, even after paying your &quot;trued
              up&quot; amount, you may still be subject to underpayment
              penalties.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/4416196562967-What-if-I-didn-t-pay-my-quarterly-estimates-for-prior-quarters-this-year"
                newPage
                as="secondaryLink"
              >
                Learn more
              </Link>
            </Text>
          ),
        },
      ]}
      backButtonText="Back"
      continueButtonText="Continue"
      handleContinue={handleContinue}
      handleBack={handleBack}
    />
  )
}

export default QuarterlyTaxes
