export enum BOOKKEEPING_STEPS {
  learnHowBookkeepingWorks = 'learn_how_bookkeeping_works',
  bookkeepingAtHeard = 'bookkeeping_at_heard',
  howToClarifyTransactions = 'how_to_clarify_transactions',
  clarifyTransactionsExample = 'clarify_transactions_example',
  transactionsClarifiedSuccess = 'transactions_clarified_success',
}

export enum TAXES_STEPS {
  learnAboutTaxesAtHeard = 'learn_about_taxes_at_heard',
  taxesTimeline = 'taxes_timeline',
  annualTaxes = 'annual_taxes',
  quarterlyTaxes = 'quarterly_taxes',
  taxesSuccess = 'taxes_success',
}

// order is important
export const ahaMomentBookkeepingStepsOrder = [
  BOOKKEEPING_STEPS.learnHowBookkeepingWorks,
  BOOKKEEPING_STEPS.bookkeepingAtHeard,
  BOOKKEEPING_STEPS.howToClarifyTransactions,
  BOOKKEEPING_STEPS.clarifyTransactionsExample,
  BOOKKEEPING_STEPS.transactionsClarifiedSuccess,
]

// order is important
export const ahaMomentTaxesStepsOrder = [
  TAXES_STEPS.learnAboutTaxesAtHeard,
  TAXES_STEPS.taxesTimeline,
  TAXES_STEPS.annualTaxes,
  TAXES_STEPS.quarterlyTaxes,
  TAXES_STEPS.taxesSuccess,
]
