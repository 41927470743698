import { useCallback, useEffect, useMemo, useState } from 'react'
import { useReselector } from '../../utils/sharedHooks'
import {
  selectHeardProductPlanInterval,
  selectHeardProductPlanPrice,
  StripeInterval,
} from '../../reducers/subscription.slice'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { filterNulls, useAppDispatch } from '../../utils/typeHelpers'
import { getTransactionsByType } from '../Transactions/transactions.selectors'
import {
  CancellationStepIdentifier,
  FaAvailableConnectionType,
  fetchTotalCompletedReconciliationCount,
  updateAutomaticCancellationAnalyticsRecord,
} from './userCancellation.slice'
import { useBooleanFlagValue, useObjectFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'
import { selectNumberOfQuartersWithFederalEstimate } from '../Taxes/QuarterlyTaxEstimates/userTaxEstimates.selector'
import { isNil } from 'lodash'
import {
  centsToCurrency,
  centsToDollars,
  dollarsToCurrency,
  formatCurrency,
  formatCurrencyFromCents,
} from '../../utils/currencyHelpers'
import {
  selectEligibleForPlaidStatements,
  selectFinancialAccounts,
} from '../../selectors/financeSelectors'
import {
  getAccountUploadStatuses,
  StatementStatus,
  StatementStatusType,
} from '../../actions/financialAccountActions'
import { Grid } from 'semantic-ui-react'
import {
  Text,
  Card,
  GridRowColumn,
  Button,
  Link,
  Popup,
} from '../../components/BaseComponents'
import {
  CATCHUP_BOOKKEEPING_PRICE_PER_MONTH,
  fetchStripePlans,
} from '../../constants/pricingConstants'
import { Colors } from '../../styles/theme'
import { isEligibleForDiscount } from './userCancellation.selectors'

export interface CancellationFlowStaticValues {
  time_saved_per_account_reconciliation_in_seconds?: number
  time_saved_per_qte_calculation_in_seconds?: number
  average_money_saved_in_cents?: number
  effective_tax_rate?: number
  average_time_saved_in_seconds?: number
  time_saved_per_transaction_categorization_in_seconds?: number
  minimum_money_threshold_in_cents?: number
  minimum_time_threshold_in_seconds?: number
  average_number_of_reconciliations_per_year?: number
  average_number_of_transactions_per_year?: number
  bookkeeping_software_monthly_fee_in_cents?: number
  done_for_you_monthly_bookkeeping_monthly_fee_in_cents?: number
  tax_advisory_with_local_cpa_hourly_fee_in_cents?: number
  annual_personal_tax_return_filing_yearly_fee_in_cents?: number
  annual_business_tax_return_filing_yearly_fee_in_cents?: number
  payroll_monthly_fee_in_cents?: number
  s_corp_setup_and_compliance_in_cents?: number
  monthly_subscription_discount_code?: string
  yearly_subscription_discount_code?: string
}

export const getDefaultPrice = (isMonthly: boolean, isScorp: boolean) => {
  const prices = fetchStripePlans()
  if (isMonthly) {
    if (isScorp) {
      return prices.scorp_solo.monthly.amount
    } else {
      return prices.sole_prop_solo.monthly.amount
    }
  } else {
    if (isScorp) {
      return prices.scorp_solo.annual.amount
    } else {
      return prices.sole_prop_solo.annual.amount
    }
  }
}

export const useGetSaveMoneyAndTimePageData = (recordId?: number) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const enableLowerFrictionStatements = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )
  const enablePlaidStatementDisabling = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enablePlaidStatementDisabling,
    false
  )
  const cancellationSaveFlowStaticValues: CancellationFlowStaticValues =
    useObjectFlagValue(FEATURE_FLAG_KEYS.cancellationSaveFlowStaticValues, {})

  const [showPersonalized, setShowPersonalized] = useState<boolean>()
  const [saveTimeAndMoneyData, setSaveTimeAndMoneyData] = useState<{
    moneySavedInCents: number
    timeSavedInSeconds: number
    totalReconciliations: number
    totalCategorizedTransactions: number
    quartersWithEstimates: number
  }>()
  const [accountUploadStatuses, setAccountUploadStatuses] =
    useState<StatementStatus[]>()

  // For saving time and money
  const businessTransactions = useReselector(
    getTransactionsByType,
    'businessExpenses'
  )

  const allCategorizedTransactions = useReselector(
    getTransactionsByType,
    'categorized'
  )

  const quartersWithEstimates = useReselector(
    selectNumberOfQuartersWithFederalEstimate
  )
  const getSaveMoneyAndTimeData = useCallback(async () => {
    const {
      time_saved_per_account_reconciliation_in_seconds,
      time_saved_per_qte_calculation_in_seconds,
      effective_tax_rate,
      time_saved_per_transaction_categorization_in_seconds,
    } = cancellationSaveFlowStaticValues

    const totalReconciliations =
      (await fetchTotalCompletedReconciliationCount()(dispatch)) ?? 0

    const totalCategorizedTransactions = allCategorizedTransactions.length

    //if the static values aren't set, default to 0 so that we show the default
    //"average" user saves ... message
    if (
      !time_saved_per_account_reconciliation_in_seconds ||
      !time_saved_per_qte_calculation_in_seconds ||
      !effective_tax_rate ||
      !time_saved_per_transaction_categorization_in_seconds
    ) {
      return {
        moneySavedInCents: 0,
        timeSavedInSeconds: 0,
        totalReconciliations,
        totalCategorizedTransactions,
        quartersWithEstimates,
      }
    }

    const businessTransactionsTotalInCents = Math.abs(
      businessTransactions.reduce(
        (total, transaction) => total + transaction.amountInCents,
        0
      )
    )

    const moneySavedInCents = Math.ceil(
      centsToCurrency(businessTransactionsTotalInCents).multiply(
        effective_tax_rate
      ).intValue
    )

    const timeSavedInSeconds =
      totalCategorizedTransactions *
        time_saved_per_transaction_categorization_in_seconds +
      totalReconciliations * time_saved_per_account_reconciliation_in_seconds +
      quartersWithEstimates * time_saved_per_qte_calculation_in_seconds
    return {
      moneySavedInCents,
      timeSavedInSeconds,
      totalReconciliations,
      totalCategorizedTransactions,
      quartersWithEstimates,
    }
  }, [
    dispatch,
    cancellationSaveFlowStaticValues,
    businessTransactions,
    allCategorizedTransactions,
    quartersWithEstimates,
  ])

  const showPersonalizedSavingsPage = useCallback(async () => {
    const data = await getSaveMoneyAndTimeData()
    const {
      minimum_money_threshold_in_cents,
      minimum_time_threshold_in_seconds,
    } = cancellationSaveFlowStaticValues

    return (
      !isNil(minimum_money_threshold_in_cents) &&
      !isNil(minimum_time_threshold_in_seconds) &&
      data.moneySavedInCents > minimum_money_threshold_in_cents &&
      data.timeSavedInSeconds > minimum_time_threshold_in_seconds
    )
  }, [getSaveMoneyAndTimeData, cancellationSaveFlowStaticValues])

  const saveTimeAndMoneyValues = useMemo(() => {
    if (!saveTimeAndMoneyData) {
      return null
    }

    const {
      average_money_saved_in_cents,
      average_time_saved_in_seconds,
      average_number_of_reconciliations_per_year,
      average_number_of_transactions_per_year,
    } = cancellationSaveFlowStaticValues

    // if the average data isn't on the flag, we have no choice but to show their actual data
    const showPersonalizedPage =
      showPersonalized &&
      !isNil(average_money_saved_in_cents) &&
      !isNil(average_time_saved_in_seconds)

    const {
      moneySavedInCents,
      timeSavedInSeconds,
      totalReconciliations,
      totalCategorizedTransactions,
      quartersWithEstimates,
    } = saveTimeAndMoneyData

    const dollarsSaved = formatCurrency(
      Math.ceil(
        centsToDollars(
          showPersonalizedPage || !average_money_saved_in_cents
            ? moneySavedInCents
            : average_money_saved_in_cents
        )
      ),
      { hideDecimalsIfZero: true }
    )

    const timeSaved =
      showPersonalizedPage ||
      !average_money_saved_in_cents ||
      !average_time_saved_in_seconds
        ? timeSavedInSeconds
        : average_time_saved_in_seconds

    const timeString = Math.ceil(timeSaved / 3600).toLocaleString()

    const transactionsCount = showPersonalizedPage
      ? totalCategorizedTransactions
      : average_number_of_transactions_per_year
    const reconciliationsCount = showPersonalizedPage
      ? totalReconciliations
      : average_number_of_reconciliations_per_year

    return {
      dollarsSaved,
      timeString,
      transactionsCount,
      reconciliationsCount,
      quartersWithEstimates,
      showPersonalizedPage,
    }
  }, [showPersonalized, saveTimeAndMoneyData, cancellationSaveFlowStaticValues])

  //For financial account alert
  const eligibleForPlaidStatements = useReselector(
    selectEligibleForPlaidStatements,
    enableLowerFrictionStatements,
    enablePlaidStatementDisabling
  )

  const financialAccounts = useReselector(selectFinancialAccounts)

  const faAccountAlertType = useMemo(() => {
    const lbaEligibleAccountIds = accountUploadStatuses
      ?.filter(
        (status) => status.status === StatementStatusType.LIMITED_BANK_ACCESS
      )
      .map((status) => status.id)
    const showLba = Object.values(financialAccounts).some(
      (account) =>
        lbaEligibleAccountIds?.includes(account.id) &&
        account.bankAccessEnabledAt === null
    )

    const noSupport = accountUploadStatuses?.every(
      (status) => status.status === StatementStatusType.NO_SUPPORT
    )
    if (eligibleForPlaidStatements) {
      return FaAvailableConnectionType.PLAID_STATEMENTS
    } else if (showLba) {
      return FaAvailableConnectionType.LBA
    } else if (noSupport) {
      return FaAvailableConnectionType.CREATE_NEW_ACCOUNT
    } else {
      return undefined
    }
  }, [eligibleForPlaidStatements, accountUploadStatuses, financialAccounts])

  const financialAccountAlert = useMemo(() => {
    if (!faAccountAlertType) {
      return null
    }
    let alertBody
    let alertButton

    switch (faAccountAlertType) {
      case FaAvailableConnectionType.PLAID_STATEMENTS: {
        alertBody =
          'Connect your bank through Plaid to automatically upload all your statements each month.'
        alertButton = (
          <Button
            variant="secondary"
            onClick={() => {
              localStorage.setItem('plaidStatementReloginModalShown', 'false')
              if (recordId) {
                dispatch(
                  updateAutomaticCancellationAnalyticsRecord(recordId, {
                    finalStepReached:
                      CancellationStepIdentifier.FINANCIAL_ACCOUNT_UPDATE_CLICKED,
                  })
                )
              }

              navigate('/accounts')
            }}
          >
            Connect bank
          </Button>
        )
        break
      }
      case FaAvailableConnectionType.LBA: {
        alertBody =
          'You can grant Heard limited bank access through your bank, and it will allow us to automatically upload your statements each month.'
        //update with proper link
        alertButton = (
          <Link
            newPage
            href="https://support.joinheard.com/hc/en-us/articles/18202072914071-What-is-limited-bank-access"
          >
            <Button
              variant="secondary"
              onClick={() => {
                if (recordId) {
                  dispatch(
                    updateAutomaticCancellationAnalyticsRecord(recordId, {
                      finalStepReached:
                        CancellationStepIdentifier.FINANCIAL_ACCOUNT_UPDATE_CLICKED,
                    })
                  )
                }
              }}
            >
              Learn More
            </Button>
          </Link>
        )
        break
      }
      case FaAvailableConnectionType.CREATE_NEW_ACCOUNT: {
        alertBody =
          'By opening up a separate business bank account that is supported by Plaid, we can save your time by automatically uploading your statements each month.'
        alertButton = (
          <Link
            newPage
            href={
              'https://support.joinheard.com/hc/en-us/articles/23569956149399-Bank-Statements-at-Heard'
            }
          >
            <Button
              onClick={() => {
                if (recordId) {
                  dispatch(
                    updateAutomaticCancellationAnalyticsRecord(recordId, {
                      finalStepReached:
                        CancellationStepIdentifier.FINANCIAL_ACCOUNT_UPDATE_CLICKED,
                    })
                  )
                }
              }}
              className="secondary"
            >
              Learn more
            </Button>
          </Link>
        )
        break
      }
      default:
        break
    }

    return (
      <Card backgroundColor="natural">
        <Grid>
          <GridRowColumn>
            <Text as="h3">
              Save even more time with auto-upload of monthly statements
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyMd">{alertBody}</Text>
          </GridRowColumn>
          <GridRowColumn>{alertButton}</GridRowColumn>
        </Grid>
      </Card>
    )
  }, [faAccountAlertType, dispatch, recordId, navigate])

  useEffect(() => {
    const fetchData = async () => {
      const showPersonalizedVariable = await showPersonalizedSavingsPage()
      setShowPersonalized(showPersonalizedVariable)
      const saveTimeAndMoneyDataVariable = await getSaveMoneyAndTimeData()
      setSaveTimeAndMoneyData(saveTimeAndMoneyDataVariable)
      const statusesResponse = await dispatch(getAccountUploadStatuses())
      if (statusesResponse) {
        setAccountUploadStatuses(statusesResponse)
      }
    }
    fetchData()
  }, [showPersonalizedSavingsPage, getSaveMoneyAndTimeData, dispatch])

  return {
    ...saveTimeAndMoneyValues,
    faAccountAlertType,
    financialAccountAlert,
  }
}

export const useGetCostComparisonPageData = (isScorp: boolean) => {
  //should not return null, but if it does, default to monthly
  const currentSubscriptionInterval =
    useReselector(selectHeardProductPlanInterval) ?? StripeInterval.month

  const isMonthly = currentSubscriptionInterval === StripeInterval.month

  const defaultPrice = dollarsToCurrency(
    Number(getDefaultPrice(isMonthly, isScorp))
  )

  const currentSubscriptionPrice = formatCurrencyFromCents(
    useReselector(selectHeardProductPlanPrice) ?? defaultPrice,
    { hideDecimalsIfZero: true }
  )

  const cancellationSaveFlowStaticValues: CancellationFlowStaticValues =
    useObjectFlagValue(FEATURE_FLAG_KEYS.cancellationSaveFlowStaticValues, {})

  const {
    bookkeeping_software_monthly_fee_in_cents,
    done_for_you_monthly_bookkeeping_monthly_fee_in_cents,
    annual_personal_tax_return_filing_yearly_fee_in_cents,
    annual_business_tax_return_filing_yearly_fee_in_cents,
    tax_advisory_with_local_cpa_hourly_fee_in_cents,
    s_corp_setup_and_compliance_in_cents,
    payroll_monthly_fee_in_cents,
  } = cancellationSaveFlowStaticValues

  const subText = isScorp
    ? 'S corporations'
    : 'Solo Annual Plan for LLC or Sole Prop'

  const currentRateCard = (
    <div>
      <div
        style={{
          backgroundColor: Colors.moss,
          borderRadius: '16px 16px 0px 0px',
          padding: 12,
          position: 'relative',
        }}
      >
        <Text as="h2" textAlign="center">
          Your current rate
        </Text>
      </div>
      <Card variant="raised" style={{ marginTop: '-1px', borderTop: 'none' }}>
        <Grid>
          <GridRowColumn>
            <Text as="eyebrow" color="black">
              {isMonthly ? 'Monthly' : 'Annual'} plan
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
              }}
            >
              <Text as="display" color="green" textAlign="left">
                {currentSubscriptionPrice}
              </Text>
              {isMonthly && (
                <Text
                  style={{ marginBottom: '8px', fontSize: 22, fontWeight: 400 }}
                  color="green"
                >
                  /mo
                </Text>
              )}
            </div>
            <Text as="bodyXs" color="darkGray">
              Billed {isMonthly ? 'monthly' : 'annually'}
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Card backgroundColor="natural">
              <Grid>
                <GridRowColumn>
                  <Text as="bodySm" color="black">
                    Catch up bookkeeping
                    <Popup content="Your catch-up bookkeeping was completed as part of your onboarding, and your future bookkeeping is included as part of your plan." />
                  </Text>
                </GridRowColumn>
                <GridRowColumn short>
                  <Text as="h1" color="green">
                    $0
                  </Text>
                </GridRowColumn>
                <GridRowColumn short>
                  <Text as="bodyXs" color="darkGray">
                    Already completed during onboarding
                  </Text>
                </GridRowColumn>
              </Grid>
            </Card>
          </GridRowColumn>
        </Grid>
      </Card>
    </div>
  )

  const newMemberRateCard = (
    <div>
      <div
        style={{
          backgroundColor: Colors.champagne,
          borderRadius: '16px 16px 0px 0px',
          padding: 12,
        }}
      >
        <Text as="h2" textAlign="center">
          New member rate
        </Text>
      </div>
      <Card variant="raised" style={{ marginTop: '-1px', borderTop: 'none' }}>
        <Grid>
          <GridRowColumn>
            <Text as="eyebrow" color="black">
              {isMonthly ? 'Monthly' : 'Annual'} plan
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
              }}
            >
              <Text as="display" color="orange" textAlign="left">
                {formatCurrency(defaultPrice, { hideDecimalsIfZero: true })}+
              </Text>
              {isMonthly && (
                <Text
                  style={{ marginBottom: '8px', fontSize: 22, fontWeight: 400 }}
                  color="orange"
                >
                  /mo
                </Text>
              )}
            </div>
            <Text as="bodyXs" color="darkGray">
              Billed {isMonthly ? 'monthly' : 'annually'}, rate subject to
              change
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Card backgroundColor="natural">
              <Grid>
                <GridRowColumn>
                  <Text as="bodySm" color="black">
                    Catch up bookkeeping
                    <Popup
                      content={`To get your books up-to-date, there is a one-time catch up bookkeeping fee of ${formatCurrency(CATCHUP_BOOKKEEPING_PRICE_PER_MONTH, { hideDecimalsIfZero: true })} for each month of transactions that need to be reviewed.`}
                    />
                  </Text>
                </GridRowColumn>
                <GridRowColumn short>
                  <Text as="h1" color="orange">
                    {formatCurrency(CATCHUP_BOOKKEEPING_PRICE_PER_MONTH, {
                      hideDecimalsIfZero: true,
                    })}
                    /mo
                  </Text>
                </GridRowColumn>
                <GridRowColumn short>
                  <Text as="bodyXs" color="darkGray">
                    One-time fee for each missed month
                  </Text>
                </GridRowColumn>
              </Grid>
            </Card>
          </GridRowColumn>
        </Grid>
      </Card>
    </div>
  )

  return {
    subText,
    isMonthly,
    currentSubscriptionPrice,
    bookkeeping_software_monthly_fee_in_cents,
    done_for_you_monthly_bookkeeping_monthly_fee_in_cents,
    annual_personal_tax_return_filing_yearly_fee_in_cents,
    annual_business_tax_return_filing_yearly_fee_in_cents,
    tax_advisory_with_local_cpa_hourly_fee_in_cents,
    s_corp_setup_and_compliance_in_cents,
    payroll_monthly_fee_in_cents,
    currentRateCard,
    newMemberRateCard,
  }
}

export enum CANCELLATION_FLOW_STEP {
  savingMoneyAndTime = 'savingMoneyAndTime',
  costComparison = 'costComparison',
  discountOffer = 'discountOffer',
  discountComplete = 'discountComplete',
  cancellationComplete = 'cancellationComplete',
  cancellationPrevented = 'cancellationPrevented',
}

export const useSelfServiceCancellationSteps = (
  currentStep: CANCELLATION_FLOW_STEP
) => {
  const [_, setSearchParams] = useSearchParams()
  const location = useLocation()

  const enableDiscounts = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableCancellationDiscounts,
    false
  )

  const eligibleForDiscount = useReselector(
    isEligibleForDiscount,
    enableDiscounts
  )

  const calculateSteps = useCallback(
    ({ eligibleForDiscount }: { eligibleForDiscount: boolean }) => {
      return filterNulls([
        CANCELLATION_FLOW_STEP.savingMoneyAndTime,
        CANCELLATION_FLOW_STEP.costComparison,
        eligibleForDiscount ? CANCELLATION_FLOW_STEP.discountOffer : null,
        CANCELLATION_FLOW_STEP.cancellationComplete,
      ])
    },
    []
  )

  const goToNextStep = useCallback(
    (cancellationDetails?: {
      cancellationComment?: string
      cancellationReason?: string
    }) => {
      const steps = calculateSteps({ eligibleForDiscount })
      const currentStepIndex = steps.indexOf(currentStep)
      const nextStep = steps[currentStepIndex + 1]
      setSearchParams(
        { step: nextStep },
        {
          state: {
            recordId: location?.state?.recordId,
            cancellationDetails:
              cancellationDetails ?? location?.state?.cancellationDetails,
          },
        }
      )
    },
    [
      calculateSteps,
      currentStep,
      setSearchParams,
      location,
      eligibleForDiscount,
    ]
  )

  return goToNextStep
}
