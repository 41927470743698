import { useScrollRef } from '../../../../utils/sharedHooks'
import { useEffect } from 'react'
import { useTaxesSteps } from './CalculateTaxesFlow'
import { TAXES_STEPS } from '../Bookkeeping/utils'
import LearnAboutTaxesAtHeard from './LearnAboutTaxesAtHeard'
import QuarterlyTaxes from './QuarterlyTaxes'
import AnnualTaxes from './AnnualTaxes'
import TaxesTimeline from './TaxesTimeline'
import TaxesSuccess from './TaxesSuccess'

const TaxesFlow = () => {
  const { currentStep, goToNextStep, goToPreviousStep } = useTaxesSteps(
    TAXES_STEPS.learnAboutTaxesAtHeard
  )

  const { scrollRef, scrollToRef } = useScrollRef({ autoScroll: true })

  useEffect(() => {
    scrollToRef()
  }, [currentStep, scrollToRef])

  const renderStep = () => {
    switch (currentStep) {
      case TAXES_STEPS.learnAboutTaxesAtHeard:
        return (
          <LearnAboutTaxesAtHeard
            handleBack={goToPreviousStep}
            handleContinue={goToNextStep}
          />
        )
      case TAXES_STEPS.taxesTimeline:
        return (
          <TaxesTimeline
            handleBack={goToPreviousStep}
            handleContinue={goToNextStep}
          />
        )
      case TAXES_STEPS.annualTaxes:
        return (
          <AnnualTaxes
            handleBack={goToPreviousStep}
            handleContinue={goToNextStep}
          />
        )
      case TAXES_STEPS.quarterlyTaxes:
        return (
          <QuarterlyTaxes
            handleContinue={goToNextStep}
            handleBack={goToPreviousStep}
          />
        )
      case TAXES_STEPS.taxesSuccess:
        return (
          <TaxesSuccess
            handleContinue={goToNextStep}
            handleBack={goToPreviousStep}
          />
        )
      default:
        return currentStep satisfies never
    }
  }

  return (
    <>
      <span ref={scrollRef} />
      {renderStep()}
    </>
  )
}

export default TaxesFlow
