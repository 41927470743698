import { Label } from 'semantic-ui-react'
import { UserWithAdminInfo } from '../../../../reducers/admin/allUsersReducer'
import { selectPrimaryMembershipForUser } from '../../../../selectors/user.selectors'
import { useReselector } from '../../../../utils/sharedHooks'
import { User } from '../../../../reducers/auth/userReducer'
import { TaxFilingUser } from '../../../../features/Taxes/AnnualTaxes/annualTaxFilings.slice'

const UserStateLabel = ({
  user,
}: {
  user?: UserWithAdminInfo | User | TaxFilingUser
}) => {
  const membership = useReselector(selectPrimaryMembershipForUser, user?.id)
  const renderMembershipStatus = () => {
    switch (membership?.status) {
      case 'signingUp':
        return (
          <Label>
            <b>Heard Membership Status: </b>
            Signing Up — User Has Not Paid
          </Label>
        )
      case 'paid':
        return (
          <Label color="green">
            <b>Heard Membership Status: </b>
            Paid
          </Label>
        )
      case 'cancelling':
        return (
          <Label color="orange">
            <b>Heard Membership Status: </b>
            Cancelling
          </Label>
        )
      case 'canceled':
        return (
          <Label color="red">
            <b>Heard Membership Status: </b>
            Canceled
          </Label>
        )
      case 'trial':
        return (
          <Label color="yellow">
            <b>Heard Membership Status: </b>
            Trial
          </Label>
        )
      default:
        return (
          <Label>
            <b>Heard Membership Status: </b>
            {membership?.status}
          </Label>
        )
    }
  }
  return renderMembershipStatus()
}

export default UserStateLabel
