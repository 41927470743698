import moment from 'moment'
import { Alert, Button, Text } from '../BaseComponents'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { getFreeTrialEndsAt } from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { Grid } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../styles/theme'

export const FreeTrialHomepageBanner = () => {
  const trialEndsAt = useReselector(getFreeTrialEndsAt)
  const navigate = useNavigate()
  return (
    <Alert
      style={{
        borderRadius: 0,
        width: '100%',
        backgroundColor: Colors.champagne,
      }}
      type="announcement"
      useCustomContent
      fullWidth
    >
      <Grid doubling stackable style={{ width: '100%' }}>
        <Grid.Row>
          <Grid.Column width={12}>
            <Text as="h3">
              {' '}
              You are previewing the Heard Experience, with{' '}
              {moment(trialEndsAt)
                .startOf('day')
                .diff(moment().startOf('day'), 'days')}{' '}
              days remaining in your trial
            </Text>
            <Text as="bodySm">
              <i>
                Your trial ends on{' '}
                {trialEndsAt
                  ? moment(trialEndsAt).format(DATE_FORMATS.DISPLAY_SHORT)
                  : ''}
                {/* . Sign up before your trial ends and receive a 20% discount in
                your first year with Heard. */}
              </i>
            </Text>
          </Grid.Column>
          <Grid.Column
            width={4}
            textAlign="right"
            float="right"
            style={{ display: 'flex' }}
          >
            <Button
              variant="secondary"
              size="small"
              style={{ marginLeft: 'auto' }}
              onClick={() => navigate('/accounts/choose-plan')}
            >
              Explore Plans
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Alert>
  )
}

export default FreeTrialHomepageBanner
