import { ReactNode } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { PlaidItem } from '../../../reducers/finances/plaidItemReducer'
import { Icon, Text } from '../../BaseComponents'
import Tooltip from '../../../features/Taxes/QuarterlyTaxEstimates/components/Tooltip'
import {
  healthyFinancialInstitutionIds,
  INSTITUTIONS_MAP,
} from '../../../constants/businessConstants'
import {
  FinancialAccount,
  FinancialType,
  TransactionSyncState,
} from '../../../reducers/finances/financialAccountsReducer'
import { StatementStatusType } from '../../../actions/financialAccountActions'
import { FinancialAccountWithAdminInfo } from '../../../reducers/admin/financialAccountsReducer'

interface PopupProps {
  title?: string
  body: ReactNode
  link?: string
  linkText?: string
}

export const getPlaidPopup = (institution: PlaidItem): PopupProps | null => {
  switch (institution.institutionId) {
    case INSTITUTIONS_MAP.CHASE:
      return {
        title: `Managing ${institution.institutionName} accounts`,
        body: 'Due to Chase policy, you can only stop sharing account connections via their website or app.',
        link: ' https://chase.com',
        linkText: 'Chase.com',
      }
    case INSTITUTIONS_MAP.WELLS_FARGO:
      return {
        title: `Managing ${institution.institutionName} accounts`,
        body: (
          <>
            <Text>
              Due to Wells Fargo policy, you can only stop sharing account
              connections via their website or app.
            </Text>
            <br />
            <Text>
              Doing this will also disconnect the account from other personal
              finance apps that you use (i.e. Venmo, Robinhood, Mint).
            </Text>
          </>
        ),
        link: 'https://wellsfargo.com',
        linkText: 'WellsFargo.com',
      }
    default:
      return null
  }
}

export const getPlaidButtonLabel = (
  institution: PlaidItem,
  disconnectedOn: string,
  isInRolledOutInstitutions: boolean
) => {
  const popup = getPlaidPopup(institution)

  return disconnectedOn ? (
    'Reconnect'
  ) : isInRolledOutInstitutions ? (
    <>
      Add Accounts
      {popup && (
        <Tooltip
          labelComponent={
            <Icon
              name="question circle outline"
              icon={regular('question-circle')}
              color={'darkGray'}
            />
          }
          popup={{ ...popup, body: () => popup.body }}
          style={{ marginLeft: 10 }}
        />
      )}
    </>
  ) : (
    'Add/Remove Account'
  )
}

export const getAccountsAwaitingConfirmation = (
  accounts: FinancialAccount[],
  rolledOutInstitutionIds: string[]
) => {
  return accounts.filter(
    (account) =>
      !account.inactive &&
      !account.isHidden &&
      account.transactionSyncState === TransactionSyncState.PENDING &&
      account.plaidInstitutionId &&
      rolledOutInstitutionIds.includes(account.plaidInstitutionId)
  )
}

export const checkIsInRolledOutInstitutions = (
  rolledOutInstitutionIds: string[],
  institutionId: string
) => Boolean(rolledOutInstitutionIds?.includes(institutionId))

export const hasLimitedBankAccess = (
  account: FinancialAccount | FinancialAccountWithAdminInfo
) =>
  account.accountType === FinancialType.PLAID &&
  account.bankAccessEnabledAt !== null

export const hasPlaidStatements = (
  account: FinancialAccount | FinancialAccountWithAdminInfo
) =>
  account.accountType === FinancialType.PLAID &&
  account.statementPermissions === StatementStatusType.PLAID_STATEMENT

export const hasPlaidStatementsDisabled = (account: FinancialAccount) =>
  account.accountType === FinancialType.PLAID &&
  account.statementPermissions === 'plaid_statement_disabled'

export const isAutoStatementUploadAccount = (account: FinancialAccount) =>
  hasPlaidStatements(account) || hasLimitedBankAccess(account)

export const isManualPlaidStatementUploadAccount = (
  account: FinancialAccount
) =>
  account.accountType === FinancialType.PLAID &&
  !isAutoStatementUploadAccount(account)

export const isHealthyInstitution = (institutionId: string | null) =>
  healthyFinancialInstitutionIds.includes(institutionId ?? '')

export const hasAutoStatementUploadAccount = (accounts: FinancialAccount[]) =>
  accounts.some(isAutoStatementUploadAccount)

export const hasPlaidStatementsAccount = (accounts: FinancialAccount[]) =>
  accounts.some(hasPlaidStatements)

export const hasPlaidStatementsDisabledAccount = (
  accounts: FinancialAccount[]
) => accounts.some(hasPlaidStatementsDisabled)

export const hasHealthyManualStatementUploadAccount = (
  accounts: FinancialAccount[]
) =>
  accounts.some(
    (account) =>
      isManualPlaidStatementUploadAccount(account) &&
      isHealthyInstitution(account.plaidInstitutionId)
  )
