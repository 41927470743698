import AhaMomentsBaseComponent from '../AhaMomentsBaseComponent'
import { Link, Text } from '../../../../components/BaseComponents'
import { Image } from 'semantic-ui-react'

const LearnAboutTaxesAtHeard = ({
  handleContinue,
  handleBack,
}: {
  handleContinue: () => void
  handleBack: () => void
}) => {
  return (
    <AhaMomentsBaseComponent
      title="Get ready for taxes"
      subtitle="Taxes for therapists"
      description="We know figuring out taxes can be tough as a therapist. Heard will make staying on top of it painless, while saving money in the process."
      media={
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/taxes_pencil.svg"
          style={{
            width: '100%',
          }}
        />
      }
      features={[
        {
          imgSrc: 'https://heard-images.s3.amazonaws.com/assets/3coins.svg',
          title: 'Get personalized tax estimates',
          description:
            'We use your transaction data to calculate what you might owe.',
        },
        {
          imgSrc:
            'https://heard-images.s3.amazonaws.com/assets/calendar_month.svg',
          title: 'Avoid tax penalties',
          description:
            'We’ll let you know in advance when you need to pay your taxes, so you’ll never miss a deadline.',
        },
        {
          imgSrc: 'https://heard-images.s3.amazonaws.com/assets/tax_paper.svg',
          title: 'Reduce your tax liability',
          description:
            'We’ll help identify potential deductions and find other ways to save on taxes.',
        },
      ]}
      learnMoreItems={[
        {
          title: 'Why do I need to pay taxes on my therapy income?',
          content: (
            <Text>
              Unless it&apos;s deductible, all income is subject to taxes,
              including your therapy income. Because you are a self-employed
              business owner, you are required to pay estimated taxes on your
              therapy income every quarter, and then pay the balance of whatever
              you owe at the end of the year when you file your tax return.
            </Text>
          ),
        },
        {
          title: 'How do I make tax payments?',
          content: (
            <Text>
              You can make a payment using a variety of methods. We recommend
              using electronic payment options offered by the IRS or your state.
              <br />
              <br />
              We recommend paying from your personal bank account or card, since
              quarterly estimated tax is paid towards your individual income
              tax, and considered a personal expense.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/4402339575319-How-to-pay-federal-quarterly-tax-estimates"
                newPage
                as="secondaryLink"
              >
                Learn more
              </Link>
            </Text>
          ),
        },
        {
          title: 'Do I also have to pay state taxes?',
          content: (
            <Text>
              Most states require you to pay taxes on your income. Each state
              has different requirements on how much to pay, and some do not
              require any income tax.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/sections/4416221590167-Paying-Quarterly-Taxes-by-State"
                newPage
                as="secondaryLink"
              >
                Learn more
              </Link>
            </Text>
          ),
        },
      ]}
      backButtonText="Back"
      continueButtonText="Continue"
      handleContinue={handleContinue}
      handleBack={handleBack}
    />
  )
}

export default LearnAboutTaxesAtHeard
