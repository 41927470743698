import { useEffect, useState } from 'react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import {
  useReselector,
  useToggle,
  useTrackFinancialInsightsButtonClick,
} from '../../../utils/sharedHooks'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import {
  GridRowColumn,
  Text,
  DatePicker,
  Alert,
  Link,
} from '../../BaseComponents'
import { Divider, Grid } from 'semantic-ui-react'
import { ArticleCard } from '../shared/ArticleCard'
import {
  ARTICLE_TYPE,
  FinancialInsightsButtonType,
  INSIGHTS_TYPE,
} from '../shared/utils'
import { UncategorizedTransactionBanner } from '../shared/UncategorizedTransactionBanner'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { CurrentPanel } from '../shared/CurrentPanel'
import { TrackInsightsSection } from '../shared/TrackInsightsSection'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'
import { selectActivePlaidFinancialAccounts } from '../../../selectors/financeSelectors'
import { isEmpty } from 'lodash'
import { BannerCard } from '../../BaseComponents/BannerCard'

const InsightsProfitPanel = () => {
  const dispatch = useAppDispatch()
  const [displayBanner, toggleBanner] = useToggle(true)
  const [inputDate, setInputDate] = useState('')

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const shouldDisplayDatePicker = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.insightsDatePicker,
    false
  )

  const trackInsights = useTrackFinancialInsightsButtonClick()

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  const linkedPlaidAccounts = useReselector(selectActivePlaidFinancialAccounts)
  const hasLinkedPlaidAccounts = !isEmpty(linkedPlaidAccounts)

  const closeBanner = () => {
    toggleBanner()
    trackInsights({
      button: FinancialInsightsButtonType.CLOSE_BANNER,
      insightsType: INSIGHTS_TYPE.PROFIT,
    })
  }

  return (
    <Grid padded={isMobile}>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Text as="h1"> Profit </Text>
        {shouldDisplayDatePicker && (
          <DatePicker value={inputDate} onChange={setInputDate} clearable />
        )}
      </GridRowColumn>
      <GridRowColumn short />
      {displayBanner && (
        <GridRowColumn>
          <BannerCard
            headerText="What is Profit?"
            imageUrl="https://heard-images.s3.amazonaws.com/assets/coins-stack.svg"
            onClose={closeBanner}
            bodyContent={
              <>
                Your profit is the income you make minus your business expenses.
                It&apos;s the amount of money you have left after you have paid
                off all your business expenses each month.
                <br />
                <br />
                Building a profitable practice is the same as building a
                practice that can last, where you are taking care of yourself as
                well as your clients. Profit affects how much you are able to
                pay yourself (Owner&apos;s Draw) and save for things like
                retirement.
              </>
            }
          />
        </GridRowColumn>
      )}
      {!hasLinkedPlaidAccounts && (
        <GridRowColumn>
          <Alert>
            <Text>
              You do not have a connected bank account.{' '}
              <Link to={'/accounts#connected-institutions'}>
                Link Business Account
              </Link>
            </Text>
          </Alert>
        </GridRowColumn>
      )}
      <UncategorizedTransactionBanner inputDate={inputDate} />
      <CurrentPanel insightsType={INSIGHTS_TYPE.PROFIT} inputDate={inputDate} />
      <TrackInsightsSection type={INSIGHTS_TYPE.PROFIT} inputDate={inputDate} />
      <GridRowColumn>
        <Text as="h2" style={{ marginBottom: 12 }}>
          Learn More
        </Text>
        <Text color="darkGray">
          Learn more about improving your profitability.
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Divider style={{ padding: 0, margin: 0 }} />
      </GridRowColumn>
      <Grid.Row className="short" columns={isMobile ? 1 : 3}>
        <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
          <ArticleCard
            title="How To Build a Profitable Therapy Practice"
            type={ARTICLE_TYPE.ARTICLE}
            url="https://www.joinheard.com/articles/how-to-build-a-profitable-therapy-practice"
            insightsType={INSIGHTS_TYPE.PROFIT}
          />
        </Grid.Column>
        <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
          <ArticleCard
            title="How To Keep Your Therapy Practice Profitable"
            type={ARTICLE_TYPE.ARTICLE}
            url="https://www.joinheard.com/articles/how-to-keep-your-therapy-practice-profitable"
            insightsType={INSIGHTS_TYPE.PROFIT}
          />
        </Grid.Column>
        <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
          <ArticleCard
            title="The Complete Guide to Financial Planning for Therapists"
            type={ARTICLE_TYPE.ARTICLE}
            url="https://www.joinheard.com/articles/the-complete-guide-to-financial-planning-for-therapists"
            insightsType={INSIGHTS_TYPE.PROFIT}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
export default InsightsProfitPanel
