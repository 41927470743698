import { SemanticCOLORS } from 'semantic-ui-react'
import { createSelector } from 'reselect'
import { find } from 'lodash'

import { ReduxState } from '../utils/typeHelpers'
import {
  isTaxCalculationOutput,
  UserTaxCalculation,
  UserTaxCalculationStatuses,
} from '../features/Admin/UserTaxCalculations/userTaxCalculation.slice'
import {
  selectActiveQuarterlyTaxEstimateDetails,
  selectAllQuarterlyTaxEstimateDetailsSorted,
} from '../features/Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { selectFPHistoriesForCurrentYear } from '../features/Admin/Histories/histories.selector'
import {
  FILING_STATUSES,
  TAX_ENTITY_TYPES,
} from '../features/Taxes/taxConstants'
import { isSingleStateCalculation } from '../features/Admin/UserTaxCalculations/service'
import { splitTaxYearQuarter } from '../features/Taxes/QuarterlyTaxEstimates/helpers'
import { getCurrentUser } from './user.selectors'
import { selectUserTaxEstimateById } from '../features/Taxes/QuarterlyTaxEstimates/userTaxEstimates.selector'

export const getAllUserTaxCalculations = (state: ReduxState) =>
  state.userTaxCalculations.byId

export const selectUserTaxCalculationById = createSelector(
  getAllUserTaxCalculations,
  (_: unknown, id?: number | string) => id,
  (taxCalculations, id) =>
    id && taxCalculations[id] ? taxCalculations[id] : null
)

//the taxCalculationOutput will be in form TaxCalculationOutput from 2023-Q3 on when we use our new in-house calculator
export const selectTaxCalculationOutput = createSelector(
  selectUserTaxCalculationById,
  (calc) =>
    (isTaxCalculationOutput(calc?.taxCalculationOutput) &&
      calc?.taxCalculationOutput) ||
    null
)

export const selectStatusInfoForCalculation = createSelector(
  selectUserTaxCalculationById,
  (calculation) => {
    if (!calculation) {
      return { text: '', color: 'black' as SemanticCOLORS }
    }

    const estimatesHaveValues = () => {
      if (typeof calculation.federalQuarterlyEstimateInCents === 'undefined') {
        return false
      }

      let statesHaveValues = true
      calculation.stateQuarterlyEstimates.forEach((s) => {
        if (typeof s.value === 'undefined') {
          statesHaveValues = false
        }
      })

      return statesHaveValues
    }

    if (
      calculation.status === UserTaxCalculationStatuses.savedAndNotified &&
      estimatesHaveValues()
    ) {
      return { text: 'complete', color: 'green' as SemanticCOLORS }
    }

    if (
      calculation.status === UserTaxCalculationStatuses.calculated &&
      estimatesHaveValues()
    ) {
      return {
        text: UserTaxCalculationStatuses.calculated,
        color: 'yellow' as SemanticCOLORS,
      }
    }

    return {
      text: UserTaxCalculationStatuses.notStarted,
      color: 'black' as SemanticCOLORS,
    }
  }
)

export const selectIsSingleStateCalculation = createSelector(
  selectUserTaxCalculationById,
  selectAllQuarterlyTaxEstimateDetailsSorted,
  selectFPHistoriesForCurrentYear,
  (
    userTaxCalculation,
    allQuarterlyTaxEstimateDetails,
    financialProfileHistoriesThisYear
  ) =>
    isSingleStateCalculation({
      userTaxCalculation,
      allQuarterlyTaxEstimateDetails,
      financialProfileHistoriesThisYear,
    })
)

const getTaxCalculationByTaxQuarter = (
  userTaxCalculations: { [key: string]: UserTaxCalculation },
  taxQuarter: string | undefined
) => {
  const { year, quarter } = splitTaxYearQuarter(taxQuarter)

  // Using find instead of filter because there should only be one calculation per tax year/quarter
  return Object.values(userTaxCalculations).find(
    (calculation) =>
      calculation.calendarQuarter === quarter &&
      calculation.calendarYear === year
  )
}

export const selectTaxCalculationByEstimateId = createSelector(
  getAllUserTaxCalculations,
  selectUserTaxEstimateById,
  (userTaxCalculations, taxEstimate) => {
    if (!taxEstimate) {
      return null
    }

    return getTaxCalculationByTaxQuarter(
      userTaxCalculations,
      taxEstimate.taxQuarter
    )
  }
)

export const selectTaxCalculationByTaxQuarter = createSelector(
  [
    getAllUserTaxCalculations,
    (_: unknown, taxQuarter: string | undefined) => taxQuarter,
  ],
  getTaxCalculationByTaxQuarter
)

export const selectIsSingleStateCalculationByTaxQuarter = createSelector(
  selectTaxCalculationByTaxQuarter,
  selectAllQuarterlyTaxEstimateDetailsSorted,
  selectFPHistoriesForCurrentYear,
  (
    userTaxCalculation,
    allQuarterlyTaxEstimateDetails,
    financialProfileHistoriesThisYear
  ) =>
    isSingleStateCalculation({
      userTaxCalculation,
      allQuarterlyTaxEstimateDetails,
      financialProfileHistoriesThisYear,
    })
)

export const selectIsSingleStateCalculationByTaxEstimateId = createSelector(
  selectTaxCalculationByEstimateId,
  selectAllQuarterlyTaxEstimateDetailsSorted,
  selectFPHistoriesForCurrentYear,
  (
    userTaxCalculation,
    allQuarterlyTaxEstimateDetails,
    financialProfileHistoriesThisYear
  ) =>
    isSingleStateCalculation({
      userTaxCalculation,
      allQuarterlyTaxEstimateDetails,
      financialProfileHistoriesThisYear,
    })
)

export const selectShouldDeductSelfEmploymentTax = createSelector(
  selectUserTaxCalculationById,
  (userTaxCalculation) =>
    userTaxCalculation?.taxEntityType &&
    ![
      TAX_ENTITY_TYPES.form_1040,
      TAX_ENTITY_TYPES.schedule_c,
      TAX_ENTITY_TYPES.form_1120,
      TAX_ENTITY_TYPES.form_1065,
    ].includes(userTaxCalculation?.taxEntityType)
)

/*
    We changed the way we use the W2 input fields in Q2 2023. Before that time
    the data stored wIncome fields was expected-annual. After that time, it was YTD.
    This selector is needed to determine how to display data related to the W2 input.
*/
export const selectUsesYTDW2Input = createSelector(
  selectUserTaxCalculationById,
  (userTaxCalculation) => {
    const calendarYear = userTaxCalculation?.calendarYear
      ? Number(userTaxCalculation.calendarYear)
      : 0
    return (
      calendarYear === 2022 ||
      calendarYear > 2023 ||
      (calendarYear === 2023 && Number(userTaxCalculation?.calendarQuarter) > 1)
    )
  }
)

/*
    We changed the way we calculate the quarterly estimates in Q3 2023 and the way we expose them in 2024.
    The new calculator returns additional data and doesn't need the adjustments to the quarterly estimates.
    This selector exists to determining when we should render the additional data and when we should remove
    adjustment popups.
*/
export enum BreakdownVersion {
  defaultBreakdown,
  version2,
  version3,
}

const getCalculatorVersion = ({
  isSingleState,
  taxCalc,
  taxYear,
  taxQuarter,
}: {
  taxCalc: UserTaxCalculation | null | undefined
  isSingleState: boolean
  taxYear?: string
  taxQuarter?: string
}) => {
  if (!isSingleState || !taxCalc) {
    return BreakdownVersion.defaultBreakdown
  }

  if (taxYear === '2023' && taxQuarter && ['3', '4'].includes(taxQuarter)) {
    return BreakdownVersion.version2
  } else if (Number(taxYear) >= 2024) {
    return BreakdownVersion.version3
  }

  return BreakdownVersion.defaultBreakdown
}

export const selectCalculatorVersionByTaxQuarter = createSelector(
  selectTaxCalculationByTaxQuarter,
  selectIsSingleStateCalculationByTaxQuarter,
  (_: unknown, taxQuarter: string | undefined) => taxQuarter,
  (taxCalc, isSingleState, taxQuarter) => {
    const { quarter, year } = splitTaxYearQuarter(taxQuarter)

    return getCalculatorVersion({
      taxQuarter: quarter,
      taxCalc,
      taxYear: year,
      isSingleState,
    })
  }
)

export const selectCalculatorVersionByCalcId = createSelector(
  selectUserTaxCalculationById,
  selectIsSingleStateCalculation,
  (taxCalc, isSingleState) =>
    getCalculatorVersion({
      taxQuarter: taxCalc?.calendarQuarter,
      taxCalc,
      taxYear: taxCalc?.calendarYear,
      isSingleState,
    })
)

export const selectIsCalculatorDefaultVersion = createSelector(
  selectCalculatorVersionByCalcId,
  (version) => version === BreakdownVersion.defaultBreakdown
)

export const selectUserTaxCalculationWithinActiveQuarter = createSelector(
  [getAllUserTaxCalculations, selectActiveQuarterlyTaxEstimateDetails],
  (userTaxCalculations, activeQuarterlyTaxEstimateDetails) => {
    if (!activeQuarterlyTaxEstimateDetails) {
      return null
    }
    // Using find instead of filter because there should only be one calculation per tax year/quarter
    return find(Object.values(userTaxCalculations), (taxCalculation) => {
      return (
        taxCalculation.calendarQuarter ===
          activeQuarterlyTaxEstimateDetails.taxQuarter &&
        taxCalculation.calendarYear ===
          activeQuarterlyTaxEstimateDetails.taxYear
      )
    })
  }
)

export const selectCalculationHomeState = createSelector(
  [selectUserTaxCalculationById, getCurrentUser],
  (calculation, currentUser) =>
    calculation?.homeState ?? currentUser?.financialProfile?.homeState ?? null
)

export const selectSampleTaxCalculation = createSelector(
  getCurrentUser,
  selectActiveQuarterlyTaxEstimateDetails,
  (user, activeQuarter) => {
    if (!user || !activeQuarter) {
      return undefined
    }

    return {
      id: -1,
      status: UserTaxCalculationStatuses.savedAndNotified,
      calendarYear: activeQuarter.taxYear,
      calendarQuarter: activeQuarter.taxQuarter,
      createdAt: '2024-01-20T07:07:08.021Z',
      updatedAt: '2024-08-17T08:01:49.396Z',
      deletedAt: undefined,
      notifiedAt: '2024-08-17T08:01:49.396Z',
      notes: null,
      needsRecalculation: null,
      unseenEstimate: null,
      userId: user.id,
      userUuid: user.uuid,
      firstName: user.firstName,
      lastName: user.lastName,
      stateQuarterlyEstimates: [
        {
          state: 'MO',
          value: 661.41,
        },
      ],
      calculatedByUserId: null,
      taxCalculationOutput: {
        data: {
          year: '2024',
          stateIncomeTax: 1717.02,
          selfEmploymentTax: 0,
          federalTaxOutstanding: 3336.78,
          annualGrossIncomeInCents: 11791562,
          annualQBIDeductionInCents: 875637,
          annualStateEstimateInCents: 171702,
          annualTaxableIncomeInCents: 7995925,
          quarterStateEstimateInCents: 66141,
          annualFederalEstimateInCents: 333678,
          allStateQuarterPaymentsLogged: true,
          quarterFederalEstimateInCents: 105400,
          YTDStateTaxPaymentsMadeInCents: 191242,
          annualStateTaxLiabilityInCents: 422244,
          allFederalQuarterPaymentsLogged: true,
          federalStandardDeductionInCents: 2920000,
          YTDFederalTaxPaymentsMadeInCents: 368492,
          YTDGrossStateTaxLiabilityInCents: 316683,
          YTDGrossFederalTaxLiabilityInCents: 684833,
          annualTotalStateWithholdingInCents: 59300,
          deductibleSelfEmploymentTaxInCents: 0,
          annualTotalFederalWithholdingInCents: 210941,
          annualFederalIncomeTaxLiabilityInCents: 913111,
          annualSelfEmploymentTaxLiabilityInCents: 0,
        },
      },
      calculatedAt: '2024-08-17T07:03:25.971Z',
      filingStates: ['MO', 'GA'],
      filingStatus: FILING_STATUSES.single,
      taxEntityType: TAX_ENTITY_TYPES.form_1040,
      homeState: 'MO',
      percentageIncomePerState: [
        { state: 'MO', value: '98' },
        { state: 'GA', value: '2' },
      ],
      taxProfileInputsUpdatedAt: '2024-08-17T07:03:17.005Z',
      plInputsUpdatedAt: '2024-08-17T07:03:17.005Z',
      federalQuarterlyEstimateInCents: 105400,
      wIncomeInCents: 0,
      spouseWIncomeInCents: 3147542,
      totalWIncomeInCents: 4324469,
      federalWithholdingInCents: 22236,
      stateWithholdingInCents: 3400,
      spouseFederalWithholdingInCents: 188705,
      spouseStateWithholdingInCents: 55900,
      totalFederalWithholdingInCents: 210941,
      totalStateWithholdingInCents: 59300,
      periodIncomeInCents: 4622269,
      periodExpensesInCents: 2068327,
      annualizedIncomeInCents: 7923890,
      annualizedExpensesInCents: 3545703,
      annualizedNetIncomeInCents: 4378187,
      paidEstimatesAtQ4Calculation: null,
      relocatedPreviousStateAt: null,
      otherIndividualIncomeInCents: 0,
      spouseIndividualIncomeInCents: 0,
      scorpPracticeWIncomeInCents: 1176927,
      annualizedTotalWIncomeInCents: 7413375,
      annualizedScorpPracticeWIncomeInCents: 2017589,
      annualizedWIncomeInCents: 0,
      annualizedSpouseWIncomeInCents: 5395786,
      estimatedAnnualW2IncomeInCents: null,
      estimatedAnnualSpouseW2IncomeInCents: null,
      estimatedAnnualScorpPracticeW2IncomeInCents: null,
      isSingleState: true,
      hasPaidAllTaxEstimates: true,
      booksClosedForPreviousMonths: true,
      taxProfileLastReviewedAt: '2024-08-16T21:39:58.000Z',
    } satisfies UserTaxCalculation
  }
)
