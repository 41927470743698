import { TrialStatus } from '../../reducers/auth/userReducer'
import {
  isFreeTrialPromoCode,
  getFreeTrialStatus,
} from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { Alert, Text } from '../BaseComponents'

const FreeTrialUnreviewedAlert = () => {
  const trialStatus = useReselector(getFreeTrialStatus)
  const isFreeTrial = trialStatus === TrialStatus.active
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)
  // Only show if this is the 14 day free trial, not the referral free trial, as the 14 day does not include CUBK
  if (!isFreeTrial || !hasFreeTrialPromoCode) return null
  return (
    <Alert type="warn">
      <Text as="h3">Your financial transactions are unreviewed</Text>
      <Text as="bodySm">
        Heads up! Because you are on a free trial, the numbers you are looking
        at have not been reviewed by Heard. While they give you a general
        picture of where your practice stands, we recommend joining Heard to
        receive a full review.
      </Text>
    </Alert>
  )
}

export default FreeTrialUnreviewedAlert
