import { useEffect, useMemo } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import {
  selectCompletedAtForOnboardingStep,
  selectHasUserCompletedOnboardingPhase,
  selectLastStartedAtStepForOnboardingPhase,
} from '../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import {
  OnboardingStepId,
  OnboardingStepPhase,
} from '../../Onboarding/UserOnboardingSteps/onboarding.reducer'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { fetchUserOnboardingStepsIfNeeded } from '../../Onboarding/UserOnboardingSteps/onboarding.actions'

export interface LearnWithHeardItemProps {
  imageBackgroundColor: string
  imageSrc: string
  imageAlt: string
  title: string
  description: string
  navigateTo: string
  timeItTakes: string
  key: string
  onboardingStepPhase: OnboardingStepPhase
  completionStep: OnboardingStepId
}

const LearnWithHeardItem = ({
  imageBackgroundColor,
  imageSrc,
  imageAlt,
  title,
  description,
  navigateTo,
  timeItTakes,
  onboardingStepPhase,
  completionStep,
}: LearnWithHeardItemProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const ahaMomentPhaseComplete = useReselector(
    selectHasUserCompletedOnboardingPhase,
    onboardingStepPhase
  )

  const phaseCompletedAtDate = useReselector(
    selectCompletedAtForOnboardingStep,
    completionStep
  )

  const lastStartedAtStep = useReselector(
    selectLastStartedAtStepForOnboardingPhase,
    onboardingStepPhase
  )

  const navigateStep = useMemo(
    () =>
      // if the user has completed the bookkeeping phase, navigate to the base url to start at the beginning
      !ahaMomentPhaseComplete && lastStartedAtStep
        ? lastStartedAtStep.onboardingStepId.toLowerCase()
        : undefined,
    [ahaMomentPhaseComplete, lastStartedAtStep]
  )

  const navigateToUrl = navigateStep
    ? `${navigateTo}?step=${navigateStep}`
    : navigateTo

  useEffect(() => {
    dispatch(fetchUserOnboardingStepsIfNeeded())
  }, [dispatch])

  return (
    <Card
      type="section"
      backgroundColor={ahaMomentPhaseComplete ? 'stone40' : 'natural'}
      style={{ boxShadow: 'none', border: 'none' }}
    >
      <Card.Content>
        <Grid style={{ padding: 12 }}>
          <GridRowColumn
            centerContent
            style={{
              backgroundColor: imageBackgroundColor,
              margin: 15,
            }}
          >
            <Image
              src={imageSrc}
              alt={imageAlt}
              style={{ margin: 20, width: 100, height: 100 }}
            />
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h2">{title}</Text>
            <br />
            <Text as="bodySm">{description}</Text>
          </GridRowColumn>
          <Text as="bodySm" color="gray">
            {phaseCompletedAtDate &&
              `Reviewed on ${formatISOFromUTC(
                phaseCompletedAtDate,
                DATE_FORMATS_LUXON.DISPLAY_SHORT
              )}`}
          </Text>
          <GridRowColumn>
            <Button
              variant={ahaMomentPhaseComplete ? 'secondary' : 'primary'}
              onClick={() => navigate(navigateToUrl)}
            >
              {ahaMomentPhaseComplete ? 'Review' : 'Learn More'}
              <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
            </Button>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodySm">
              <Icon
                icon={regular('timer')}
                style={{ marginRight: 10 }}
                size="1x"
              />
              {timeItTakes}
            </Text>
          </GridRowColumn>
        </Grid>
      </Card.Content>
    </Card>
  )
}

export default LearnWithHeardItem
