import { Grid, Image } from 'semantic-ui-react'
import {
  CALCULATE_SAFE_HARBOR_STEP,
  useCalculateSafeHarborSteps,
} from './helpers'
import {
  Card,
  GridRowColumn,
  Text,
} from '../../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from '../../../TaxesProfile/FormFlow/TaxProfileAccordions'
import CalculateSafeHarborFooter from './CalculateSafeHarborFooter'
import { useReselector } from '../../../../../utils/sharedHooks'
import { useNavigate } from 'react-router-dom'
import { selectIsCurrentUserScorp } from '../../../../../selectors/user.selectors'
import { QuarterlyTaxEstimateDetail } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'

const CalculateSafeHarborIntro = ({
  details,
}: {
  details?: QuarterlyTaxEstimateDetail | null
}) => {
  const navigate = useNavigate()
  const { goToNextStep } = useCalculateSafeHarborSteps(
    CALCULATE_SAFE_HARBOR_STEP.calculateSafeHarborIntro
  )
  const isScorp = useReselector(selectIsCurrentUserScorp)

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/practice.png"
          style={{ height: 180, width: 180 }}
        />
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([10, 16, 16], true)}>
        <Text as="display2" textAlign="center">
          Let&apos;s get ready for your estimate
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([10, 16, 16], true)}>
        <Card backgroundColor="stone40">
          <Text as="h2">You’ll need:</Text>
          <Text>
            <ul>
              <li>
                Your <b>1040</b> Individual Tax Return for{' '}
                <b>{Number(details?.taxYear) - 1}</b>
              </li>
              <li>
                If applicable, any estimated tax payment vouchers that have been
                provided to you
              </li>
            </ul>
            <br />
            This should take about <b>5-10 minutes.</b>
          </Text>
        </Card>
      </GridRowColumn>
      <Grid.Row />
      <Grid.Row />

      <TaxProfileAccordions
        faqs={[
          faqCopy.whoPaysQtes,
          faqCopy.no1040TaxReturn,
          faqCopy.noPayQtesPriorQuarters,
          ...(isScorp ? [faqCopy.haveToPayQTEsScorp] : []),
        ]}
      />

      <CalculateSafeHarborFooter
        submitOrContinue={() => goToNextStep()}
        goToPreviousStep={() => navigate('/taxes/quarterly')}
      />
    </Grid>
  )
}

export default CalculateSafeHarborIntro
