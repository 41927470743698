import {
  Accordion,
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { Grid, Image } from 'semantic-ui-react'
import { Colors } from '../../../../styles/theme'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface TaxesSuccessHeaderCardProps {
  imageSrc: string
  title: string
  description: React.ReactNode
  buttonText: string
  onButtonClickTo: string
  note?: React.ReactNode
  accordionContent?: {
    title: string
    content: React.ReactNode
  }
}

const TaxesSuccessHeaderCard = ({
  imageSrc,
  title,
  description,
  buttonText,
  onButtonClickTo,
  note,
  accordionContent,
}: TaxesSuccessHeaderCardProps) => {
  const navigate = useNavigate()
  return (
    <Card
      style={{
        backgroundColor: Colors.natural,
      }}
    >
      <Card.Content>
        <Grid>
          <GridRowColumn>
            <Image
              src={imageSrc}
              style={{
                width: '100%',
              }}
              alt="Tax form illustration"
            />
          </GridRowColumn>
          <GridRowColumn style={{ marginTop: 20 }}>
            <Text as="display3">{title}</Text>

            <br />
            <Text>{description}</Text>
            {note && (
              <Text as="bodyXs" style={{ marginTop: 20 }}>
                {note}
              </Text>
            )}

            <Button
              variant="primary"
              size="medium"
              style={{ marginTop: 20 }}
              onClick={() => navigate(onButtonClickTo)}
            >
              {buttonText}
              <Icon icon={light('arrow-right')} style={{ marginLeft: 8 }} />
            </Button>

            {accordionContent && (
              <Accordion
                title={accordionContent.title}
                content={accordionContent.content}
                variant="text"
                style={{ marginTop: 16 }}
              />
            )}
          </GridRowColumn>
        </Grid>
      </Card.Content>
    </Card>
  )
}

export default TaxesSuccessHeaderCard
