import { useState, useEffect } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Button, Form, Message, Modal } from 'semantic-ui-react'

import { getFetchError, getIsFetching } from '../../../../reducers/fetch'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import {
  updateSingleUserTaxCalculation,
  UserTaxCalculationStatuses,
  UPDATE_SINGLE_USER_TAX_CALCULATIONS_KEY,
} from '../userTaxCalculation.slice'
import TaxCalculationInputFigures from './TaxCalculationInputFigures'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../utils/currencyHelpers'
import { isNil } from 'lodash'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectUserTaxCalculationById } from '../../../../selectors/userTaxCalculation.selectors'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const EditCalculationsModal = ({
  id,
  readOnly,
}: {
  id: number
  readOnly: boolean
}) => {
  const taxCalculation = useReselector(selectUserTaxCalculationById, id)

  const dispatch = useAppDispatch()
  const currentUser = useReselector(getCurrentUser)
  const [open, setOpen] = useState(false)
  const [federalInCents, setFederalInCents] = useState<number>()
  const [states, setStates] =
    useState<{ state: string; value: number | undefined }[]>()
  const [notes, setNotes] = useState<string | undefined | null>()

  const action = readOnly ? 'View' : 'Edit'

  useEffect(() => {
    // Upon modal load, set convert federal and states amounts to strings, then update state
    setFederalInCents(taxCalculation?.federalQuarterlyEstimateInCents)
    setStates(
      taxCalculation?.stateQuarterlyEstimates.map((s) => ({
        state: s.state,
        value: s.value,
      }))
    )

    setNotes(taxCalculation?.notes)
  }, [taxCalculation, setFederalInCents, setStates])

  const saveError = useReselector(
    getFetchError,
    UPDATE_SINGLE_USER_TAX_CALCULATIONS_KEY + taxCalculation?.id
  )

  const saving = useReselector(
    getIsFetching,
    UPDATE_SINGLE_USER_TAX_CALCULATIONS_KEY + taxCalculation?.id
  )

  const onClose = () => {
    setOpen(false)
  }

  const isFormValid = () => {
    if (isNil(federalInCents)) {
      return false
    }

    let statesValid = true

    states?.forEach((s) => {
      if (isNil(s.value)) {
        statesValid = false
      }
    })

    return statesValid
  }

  const onUpdateClicked = async () => {
    if (!isFormValid()) {
      alert('All estimate inputs are required')
      return
    }

    const statesNumber = states?.map((s) => ({
      state: s.state,
      value: Number(s.value ?? 0),
    }))
    const nowJSON = new Date().toJSON()

    await dispatch(
      updateSingleUserTaxCalculation(id, {
        federalQuarterlyEstimateInCents: federalInCents,
        stateQuarterlyEstimates: statesNumber,
        calculatedAt: nowJSON,
        calculatedByUserId: currentUser?.id,
        updatedAt: nowJSON,
        notes,
        status: UserTaxCalculationStatuses.calculated,
      })
    )

    onClose()
  }
  const federalInDollars = centsToDollars(federalInCents)

  if (!taxCalculation) {
    return null
  }

  return (
    <Modal
      onClose={onClose}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button
          basic
          content={action}
          icon={readOnly ? 'eye' : 'pencil'}
          size="mini"
        />
      }
    >
      <Modal.Header>
        {`${taxCalculation.firstName} ${taxCalculation.lastName.charAt(0)}`} -
        {action} Final Amounts
      </Modal.Header>
      <Modal.Content image>
        <TaxCalculationInputFigures id={id} />
        <Modal.Description>
          <Form>
            <Form.Field required>
              <label>Federal Quarterly Estimate (Adjusted/Final)</label>
              <CurrencyInput
                allowNegativeValue={false}
                prefix="$"
                placeholder="Enter $0 for no estimate"
                allowDecimals
                decimalScale={2}
                value={federalInDollars}
                onValueChange={(value) =>
                  setFederalInCents(dollarsToCents(value))
                }
              />
            </Form.Field>
            {taxCalculation.stateQuarterlyEstimates?.map((s, i) => {
              const value = states?.find(
                (usaState) => usaState.state === s.state
              )?.value

              return (
                <Form.Field required key={`state-${i}`}>
                  <label>{s.state} Quarterly Estimate (Adjusted/Final)</label>
                  <CurrencyInput
                    allowNegativeValue={false}
                    prefix="$"
                    placeholder="Enter $0 for no estimate"
                    allowDecimals
                    decimalScale={2}
                    value={value}
                    onValueChange={(value: string | undefined) => {
                      if (!states) {
                        return
                      }
                      const statesClone = [...states]
                      statesClone[i] = {
                        state: s.state,
                        value: Number(value),
                      }
                      setStates(statesClone)
                    }}
                  />
                </Form.Field>
              )
            })}
            <Form.Field
              label="Notes"
              placeholder="Optional"
              control={Form.TextArea}
              onChange={(_e: Event, { value }: { value: string }) =>
                setNotes(value)
              }
              value={notes}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      {saveError && (
        <Message
          error
          content="Please try again. Contact eng if it persists"
          header={saveError.message}
        />
      )}
      <Modal.Actions>
        <Button disabled={saving} basic color="black" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={saving || readOnly}
          loading={saving}
          content="Update Calculations"
          labelPosition="right"
          icon="checkmark"
          onClick={onUpdateClicked}
          primary
        />
      </Modal.Actions>
    </Modal>
  )
}

export default EditCalculationsModal
