import { useEffect, useState } from 'react'
import { Grid, Image } from 'semantic-ui-react'

import {
  StickyHeader,
  Button,
  Card,
  GridRowColumn,
  Text,
  Toggle,
} from '../../../../../components/BaseComponents'
import EstimateBreakdownV3 from './EstimateBreakdownV3'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectSampleTaxCalculation } from '../../../../../selectors/userTaxCalculation.selectors'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { useNavigate } from 'react-router-dom'

const EstimateBreakdownSample = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showSampleData, setShowSampleData] = useState(false)

  const sampleCalculation = useReselector(selectSampleTaxCalculation)

  const taxCalc = showSampleData ? sampleCalculation : undefined
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const estimateDetails = useReselector(selectActiveQuarterlyTaxEstimateDetails)

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  return (
    <>
      {showSampleData && (
        <StickyHeader>
          This is a <b>demo</b> page using <b>sample data</b>.
        </StickyHeader>
      )}
      <Grid style={isMobile ? { margin: '0 5px' } : undefined}>
        <GridRowColumn>
          <Card backgroundColor="natural" type="subsection">
            <Grid>
              <Grid.Row>
                <Grid.Column
                  width={3}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Image
                    src="https://heard-images.s3.amazonaws.com/assets/calculator2.svg"
                    style={{ width: 180, height: 180 }}
                  />
                </Grid.Column>
                <Grid.Column width={13}>
                  <Grid>
                    <GridRowColumn>
                      <Text as="h2">
                        You don’t have a quarterly tax estimate yet
                      </Text>
                      <Text as="bodySm">
                        Get yours to receive a personalized breakdown. It will
                        show how your income and tax payments affect your
                        quarterly tax estimate.
                      </Text>
                    </GridRowColumn>
                    <GridRowColumn>
                      <Toggle
                        checked={showSampleData}
                        onChange={setShowSampleData}
                        label="View with sample data"
                      />
                    </GridRowColumn>
                    <GridRowColumn>
                      <Button onClick={() => navigate('/taxes/quarterly')}>
                        Get My Tax Estimate
                      </Button>
                    </GridRowColumn>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        </GridRowColumn>
        <EstimateBreakdownV3
          taxCalc={taxCalc}
          estimateDetails={estimateDetails}
          isSample
        />
      </Grid>
    </>
  )
}

export default EstimateBreakdownSample
