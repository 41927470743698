/*
  User facing transaction type label

  User can edit
*/
import { useState, useEffect, useMemo } from 'react'

import { TRANSACTION_TYPE_OPTIONS } from '../../constants/transactionConstants'
import { updateUserTransaction } from '../../features/Transactions/transactions.slice'
import './TransactionLabels.scss'
import { TransactionOrSplit } from '../../reducers/admin/allTransactions.slice'
import { Dropdown, Popup } from '../BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { selectIsLockedTransaction } from '../../features/Transactions/transactions.selectors'
import { FontWeight } from '../../styles/theme'
import { useAppDispatch } from '../../utils/typeHelpers'
import { TransactionRuleCategoryType } from '../../reducers/admin/transactionRulesReducer'
import { isFreeTrialPromoCode } from '../../selectors/user.selectors'

interface EditableTransactionTypeLabelProps {
  transaction: TransactionOrSplit
  /*Used to denote that we should default to let users edit*/
  review?: boolean
}

const EditableTransactionTypeLabel = ({
  transaction,
  review = false,
}: EditableTransactionTypeLabelProps) => {
  const dispatch = useAppDispatch()
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)
  const locked = useReselector(
    selectIsLockedTransaction,
    transaction.splitFrom || transaction.id
  )
  const [transactionType, setTransactionType] = useState<
    'personal' | 'business' | 'split'
  >()
  const [disablePopup, setDisablePopup] = useState(false)
  const isOpen = useMemo(() => {
    // If on the review screen, use the default dropdown open functionality
    // since popup is always disabled in review.
    // Other screens should open the dropdown anytime popup is disabled
    return review ? undefined : disablePopup && !locked
  }, [disablePopup, locked, review])

  const [updating, setUpdating] = useState(false)
  const popupContent = useMemo(() => {
    if (!review) {
      if (transaction.type) {
        return 'Did we miscategorize this transaction? Click to recategorize.'
      } else {
        return 'No action needed right now! Our team reviews your transactions on a monthly basis and will categorize this for you.'
      }
    }
    return null
  }, [transaction.type, review])

  useEffect(() => {
    setTransactionType(transaction.type || undefined)
  }, [transaction])

  const updateTransactionType = async (
    value: TransactionRuleCategoryType | undefined
  ) => {
    await setTransactionType(value)
    await setUpdating(true)
    const updated = await updateUserTransaction(transaction.id, {
      type: value,
      excludedAt: value === 'personal' ? Date.now() : null,
    })(dispatch)
    if (updated) {
      await setUpdating(false)
    }
    setDisablePopup(false)
  }

  if (hasFreeTrialPromoCode) {
    return (
      <Dropdown
        fontWeight={FontWeight.MEDIUM}
        placeholder="Unselected"
        value={transactionType}
        options={TRANSACTION_TYPE_OPTIONS}
        onChange={(value) => updateTransactionType(value)}
        onClose={() => setDisablePopup(false)}
        closeOnBlur
        onOpen={() => setDisablePopup(true)}
        open={isOpen}
        variant="text"
        selectOnBlur={false}
        disabled={updating || locked}
        fluid
      />
    )
  }
  return (
    <Popup
      inverted
      popupClassname="categoryPopup"
      position="bottom center"
      basic
      disabled={review || locked || disablePopup}
      content={popupContent}
      trigger={
        <Dropdown
          fontWeight={FontWeight.MEDIUM}
          placeholder="Unselected"
          value={transactionType}
          options={TRANSACTION_TYPE_OPTIONS}
          onChange={(value) => updateTransactionType(value)}
          onClose={() => setDisablePopup(false)}
          closeOnBlur
          onOpen={() => setDisablePopup(true)}
          open={isOpen}
          variant="text"
          selectOnBlur={false}
          disabled={updating || locked}
          fluid
        />
      }
    />
  )
}

export default EditableTransactionTypeLabel
