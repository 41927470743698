import Moment from 'react-moment'
import { HashLink } from 'react-router-hash-link'
import { useEffect, useMemo } from 'react'
import AccountSuspendedBanner from '../../features/AccountSuspended/AccountSuspendedBanner'
import { BannerRouter } from '../Routes/Router'
import { useReselector } from '../../utils/sharedHooks'
import {
  fetchSubscriptions,
  selectDaysUntilSuspension,
  selectHeardProductSubscription,
} from '../../reducers/subscription.slice'
import TermsOfServiceModal from './TermsOfServiceModal'
import {
  getCurrentUser,
  isCancelling,
  isFreeTrialPromoCode,
} from '../../selectors/user.selectors'
import TermsOfServiceModalV2 from './TermsOfServiceModalV2'
import { TosVersion } from '../../constants/businessConstants'
import { DateTime } from 'luxon'
import { SmsConsentModal } from '../../features/SmsConsent/SmsConsentModal'
import { SmsConsentSource } from '../../features/SmsConsent/smsConsentReducer'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { Alert } from '../BaseComponents'
import { SignBaaModal } from './SignBaaModal'
import { useAppDispatch } from '../../utils/typeHelpers'
import { PlaidStatementReloginModal } from '../Finances/Accounts/PlaidStatementReloginModal'
import { useBooleanFlagDetails } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../features/OpenFeature'
import FreeTrialHomepageBanner from '../Banners/FreeTrialHomepageBanner'

const TosModalMap: Record<TosVersion, typeof TermsOfServiceModal> = {
  [TosVersion.tos_02_2022]: TermsOfServiceModal,
  [TosVersion.tos_01_2024]: TermsOfServiceModalV2,
}

const BaseRouterBanners = () => {
  const dispatch = useAppDispatch()
  const currentUser = useReselector(getCurrentUser)
  const primarySubscription = useReselector(selectHeardProductSubscription)
  const daysUntilSuspension = useReselector(selectDaysUntilSuspension)
  const showCancellingBanner = useReselector(isCancelling)
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  useEffect(() => {
    dispatch(fetchSubscriptions())
  }, [dispatch])

  const showStatementModal = useBooleanFlagDetails(
    FEATURE_FLAG_KEYS.showStatementModal,
    false
  )

  const showSmsModal = useMemo(() => {
    if (
      !currentUser ||
      currentUser?.admin ||
      currentUser?.smsLoginModalViewedAt === undefined
    )
      return false
    // show sms modal if user account is at least a week old and they haven't viewed the sms login modal before
    const { createdAt, smsLoginModalViewedAt } = currentUser
    const luxonCreatedAt = DateTime.fromISO(createdAt)
    const currentDate = DateTime.now()
    const differenceInDays = currentDate.diff(luxonCreatedAt, 'days').days
    return !smsLoginModalViewedAt && differenceInDays > 7
  }, [currentUser])

  // user should be always available as it is rendered after login
  // just to satisfy TS
  if (!currentUser) {
    return null
  }

  // To prevent the TOS modal from appearing when CS/Ops god-view into customer accounts,
  // CS/Ops may manually add "tosForceHide": true to their browser's localStorage
  let localStorageOrUndefined

  try {
    localStorageOrUndefined = window.localStorage
  } catch (_err) {
    localStorageOrUndefined = undefined
  }

  const tosForceHide = localStorageOrUndefined
    ? localStorageOrUndefined.getItem('tosForceHide') === 'true'
    : false

  const showTosModal =
    !tosForceHide &&
    !currentUser.admin &&
    currentUser.currentTosVersion !== currentUser.tosVersion

  const TosModal = TosModalMap[currentUser.currentTosVersion]
  // Used to prioritize which modal to show
  const fetchModalToShow = () => {
    if (showTosModal) {
      return <TosModal currentUserId={currentUser?.id} />
    } else if (showSmsModal) {
      return (
        <SmsConsentModal
          open={showSmsModal && !showTosModal}
          source={SmsConsentSource.LOGIN_MODAL}
        />
      )
    } else {
      return null
    }
  }
  /**
   * If user is cancelling, ignore all banners and only show this banner
   */
  if (
    showCancellingBanner &&
    process.env.VITE_CANCELLATION_SELF_SERVE_ENABLED
  ) {
    return (
      <>
        <Alert type="error">
          <b>Canceled Plan</b>
          <br />
          Your Heard subscription plan is canceled. You have full access to
          Heard until your billing cycle ends on{' '}
          <Moment format={DATE_FORMATS.DISPLAY_LONG} unix>
            {primarySubscription?.nextBillingDate}
          </Moment>
          .
          <br />
          <HashLink to="/accounts#reactivation">
            Reactivate your account.
          </HashLink>
        </Alert>
        <BannerRouter />
      </>
    )
  }

  /**
   * Has Regular free trial (non-referral), then show new banner
   */
  if (hasFreeTrialPromoCode) {
    return <FreeTrialHomepageBanner />
  }

  return (
    <>
      {fetchModalToShow()}
      {!showTosModal && <SignBaaModal />}
      {!showTosModal && !showSmsModal && showStatementModal && (
        <PlaidStatementReloginModal />
      )}

      {daysUntilSuspension !== -1 && (
        <AccountSuspendedBanner daysUntilSuspension={daysUntilSuspension} />
      )}
      <BannerRouter />
    </>
  )
}

export default BaseRouterBanners
