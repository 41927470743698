import { ReactNode } from 'react'
import styled from 'styled-components'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import Text from './Text'
import Icon from './Icon'
import { Colors } from '../../styles/theme'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

interface Props {
  children?: ReactNode
  className?: string
}

const StickyHeader = styled(({ children, className }: Props) => {
  // Mobile has a different top offset
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <div
      className={className}
      style={isMobile ? { marginTop: -12 } : undefined}
    >
      <Text className="title" as="h3">
        <Icon icon={solid('circle-info')} className="icon" /> {children}
      </Text>
    </div>
  )
})({
  '&&&&&': {
    position: 'sticky',
    left: 0,
    right: 0,
    top: 0,
    height: 55,
    backgroundColor: Colors.orange,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    margin: '-24px -20px 20px -40px',

    '.icon': {
      marginRight: 10,
    },

    '.title': {
      color: Colors.white,
    },
  },
})

export default StickyHeader
