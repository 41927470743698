import { Grid, Image } from 'semantic-ui-react'
import { GridRowColumn, Text } from '../../../../components/BaseComponents'

interface WhatToExpectRowProps {
  index: number
  imageSrc: string
  title: string
  description: string
}

const WhatToExpectRow = ({
  imageSrc,
  title,
  description,
}: WhatToExpectRowProps) => (
  <Grid.Row>
    <Grid.Column width={2}>
      <Image src={imageSrc} />
    </Grid.Column>
    <Grid.Column width={14} style={{ marginLeft: -30 }}>
      <Text as="h3" style={{ marginBottom: 8 }}>
        {title}
      </Text>
      <Text as="bodySm">{description}</Text>
    </Grid.Column>
  </Grid.Row>
)

const WhatToExpectContent = ({
  title,
  subtitle,
  whatToExpectRows,
}: {
  title: string
  subtitle: string
  whatToExpectRows: WhatToExpectRowProps[]
}) => (
  <Grid>
    <GridRowColumn>
      <Text as="display3" style={{ marginBottom: 8 }}>
        {title}
      </Text>
      <Text as="bodyXs">{subtitle}</Text>
    </GridRowColumn>
    {whatToExpectRows.map((row) => (
      <WhatToExpectRow key={row.index} {...row} />
    ))}
  </Grid>
)

export default WhatToExpectContent
