import { Divider, Grid } from 'semantic-ui-react'
import {
  Button,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import {
  AhaMomentsBookeepingFooter,
  AhaMomentsBookeepingHeader,
} from '../AhaMomentsHeaderFooter'
import { Fonts } from '../../../../styles/theme'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  selectActiveQuarterlyTaxEstimateDetails,
  selectIsPastTaxEstimateCalculationDate,
  selectIsSameOrAfterNewUserCutOffAt,
} from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectUserCreatedPastQteCutoff } from '../../../Taxes/QuarterlyTaxEstimates/userTaxEstimates.selector'
import { useEffect, useMemo } from 'react'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { fetchUserTaxEstimates } from '../../../Taxes/QuarterlyTaxEstimates/userTaxEstimates.slice'
import TaxesSuccessHeaderCard from './TaxesSuccessHeaderCard'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'
import WhatToExpectContent from './TaxesSuccessContent'
import { useNavigate } from 'react-router-dom'

const TaxesSuccess = ({
  handleContinue,
  handleBack,
}: {
  handleContinue: () => void
  handleBack: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userJoinedTooLate = useReselector(selectUserCreatedPastQteCutoff)
  const afterNewUserCutOffAt = useReselector(selectIsSameOrAfterNewUserCutOffAt)
  const isPastCalculationDate = useReselector(
    selectIsPastTaxEstimateCalculationDate
  )
  const activeQuarterDetailsEst = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const {
    irsPaymentDueAt: irsPaymentDueAtISO,
    calculationStartsAt: calculationStartsAtISO,
    clientNotifiedAt: clientNotifiedAtISO,
    newUserCutOffAt: newUserCutOffAtISO,
  } = activeQuarterDetailsEst || {}

  const formatISODate = (isoDate: string | undefined): string => {
    return isoDate
      ? isoToUTCDateTime(isoDate).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)
      : ''
  }

  const irsPaymentDueAt = formatISODate(irsPaymentDueAtISO)
  const calculationStartsAt = formatISODate(calculationStartsAtISO)
  const clientNotifiedAt = formatISODate(clientNotifiedAtISO)
  const newUserCutOffAt = formatISODate(newUserCutOffAtISO)

  useEffect(() => {
    dispatch(fetchUserTaxEstimates())
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  // 3 scenarios
  // 1. user joined too late
  // 2. user joined before the new user cut off date and before the calculation date - user joined between quarters
  // 3. user joined before the new user cut off date but after the calculation date
  const isBeforeCutoffAndCalculationDate =
    !afterNewUserCutOffAt && !isPastCalculationDate

  const getHeaderCardContent = useMemo(() => {
    if (userJoinedTooLate) {
      return {
        imageSrc:
          'https://heard-images.s3.amazonaws.com/assets/tax_form_block.svg',
        title: 'Get your federal safe harbor estimate',
        description: (
          <Text>
            The upcoming due date for quarterly payments is {irsPaymentDueAt}.
            If you haven&apos;t made an estimated payment for this quarter, you
            can get a safe harbor estimate based on previous year&apos;s taxes
            to reduce. In the future, we&apos;ll provide you with a personalized
            estimate based on your practice&apos;s income.
          </Text>
        ),
        buttonText: 'Get Your Safe Harbor Estimate',
        note: (
          <>
            <b>Note:</b> This estimate only covers your estimated payments for
            federal taxes.{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/sections/4416221590167-Paying-Quarterly-Taxes-by-State"
              newPage
              style={{
                textDecoration: 'underline',
                fontSize: Fonts.bodyXs.fontSize,
              }}
              as="secondaryLink"
            >
              Learn more about state taxes
            </Link>
          </>
        ),
        onButtonClickTo: '/taxes/quarterly/calculate-safe-harbor',
        accordionContent: {
          title: "What's the difference between methods?",
          content: (
            <Text>
              The safe harbor estimate is a simplified way to calculate your
              estimated quarterly tax payments. It&apos;s based on your previous
              year&apos;s tax liability and can help you avoid underpayment
              penalties. The personalized estimate takes into account your
              current year&apos;s income and deductions, providing a more
              accurate estimate.
            </Text>
          ),
        },
      }
    }

    if (isBeforeCutoffAndCalculationDate) {
      return {
        imageSrc:
          'https://heard-images.s3.amazonaws.com/assets/tax_calculator_block.svg',
        title: 'Get a head start on quarterly taxes',
        description: (
          <Text>
            Try our safe harbor calculator to get a sense of what you might owe
            in federal taxes for the upcoming quarter. You can also use this
            estimate to pay any quarters that you may have missed.
            <br />
            <br />
            In the future, we&apos;ll work with you to provide you a more
            personalized estimate.
          </Text>
        ),
        buttonText: 'Get Your Safe Harbor Estimate',
        onButtonClickTo: '/taxes/quarterly/calculate-safe-harbor',
        note: (
          <>
            <b>Note:</b> This estimate only covers your estimated payment for
            federal taxes.{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/sections/4416221590167-Paying-Quarterly-Taxes-by-State"
              newPage
              style={{
                textDecoration: 'underline',
                fontSize: Fonts.bodyXs.fontSize,
              }}
              as="secondaryLink"
            >
              Learn more about state taxes
            </Link>
          </>
        ),
      }
    }

    return {
      imageSrc:
        'https://heard-images.s3.amazonaws.com/assets/get_ready_for_quarterly_taxes_block.svg',
      title: 'Start your quarterly taxes',
      description: (
        <Text>
          Complete your Quarterly Tax Checklist before {calculationStartsAt} to
          get your personalized quarterly tax estimate.
        </Text>
      ),
      buttonText: 'Start Checklist',
      onButtonClickTo: '/taxes/quarterly',
    }
  }, [
    userJoinedTooLate,
    isBeforeCutoffAndCalculationDate,
    irsPaymentDueAt,
    calculationStartsAt,
  ])

  const getWhatToExpectSubTitle = useMemo(() => {
    if (userJoinedTooLate) {
      return "Here's what the quarterly tax process will look like for upcoming quarters:"
    }
    if (isBeforeCutoffAndCalculationDate) {
      return "Here's what the quarterly tax process will look like for this upcoming quarter:"
    }
    return 'How to get your quarterly tax estimate for this quarter:'
  }, [userJoinedTooLate, isBeforeCutoffAndCalculationDate])

  const getWhatToExpectContent = useMemo(() => {
    const title = userJoinedTooLate
      ? 'What to expect in future quarters'
      : 'What to expect'
    const descriptionStep1 =
      userJoinedTooLate || isBeforeCutoffAndCalculationDate
        ? `Starting on ${newUserCutOffAt}, we'll ask you to complete your Quarterly Checklist. This ensures we have accurate information for calculating your tax estimate.`
        : 'This ensures we have accurate information for calculating your tax estimate.'

    return {
      title,
      subtitle: getWhatToExpectSubTitle,
      whatToExpectRows: [
        {
          index: 1,
          imageSrc:
            'https://heard-images.s3.amazonaws.com/assets/one_circle.svg',
          title: 'Complete your Quarterly Tax Checklist',
          description: descriptionStep1,
        },
        {
          index: 2,
          imageSrc:
            'https://heard-images.s3.amazonaws.com/assets/circle_two.svg',
          title: 'Receive your quarterly tax estimate',
          description: `If your Quarterly Tax Checklist is completed before ${calculationStartsAt}, you'll receive your quarterly tax estimate on or before ${clientNotifiedAt} (in most cases, instantly).`,
        },
        {
          index: 3,
          imageSrc:
            'https://heard-images.s3.amazonaws.com/assets/circle_three.svg',
          title: 'Pay your quarterly taxes',
          description: `Pay your taxes via the federal and/or state portals by ${irsPaymentDueAt}.`,
        },
      ],
    }
  }, [
    userJoinedTooLate,
    isBeforeCutoffAndCalculationDate,
    getWhatToExpectSubTitle,
    irsPaymentDueAt,
    calculationStartsAt,
    clientNotifiedAt,
    newUserCutOffAt,
  ])

  return (
    <>
      <AhaMomentsBookeepingHeader />
      <Grid
        style={{
          flexDirection: 'column',
          maxWidth: 700,
          padding: 0,
          margin: '0 auto',
        }}
      >
        <GridRowColumn>
          <Text as="display2">Get ready for taxes</Text>
        </GridRowColumn>
        <TaxesSuccessHeaderCard {...getHeaderCardContent} />
        <WhatToExpectContent {...getWhatToExpectContent} />
        <br />
        <Divider />
        <br />
        <Text as="h3" style={{ marginBottom: 8 }}>
          How do you calculate a personalized estimate?
        </Text>
        <Text as="bodySm">
          In future quarters, we&apos;ll provide a detailed breakdown of how we
          came up with your personalized estimate. Preview it with sample data:
        </Text>
        <GridRowColumn width={8}>
          <Button
            variant="secondary"
            onClick={() => navigate('/onboarding-v2/estimate-breakdown-sample')}
          >
            View Estimate Breakdown{' '}
            <Icon icon={light('arrow-right')} style={{ marginLeft: 8 }} />
          </Button>
        </GridRowColumn>
      </Grid>
      <AhaMomentsBookeepingFooter
        backButtonText="Back"
        continueButtonText="Return to Home"
        handleBack={handleBack}
        handleContinue={handleContinue}
      />
    </>
  )
}

export default TaxesSuccess
