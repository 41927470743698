import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Container, Grid, Image, Message } from 'semantic-ui-react'

import SignupHeader from './SignupHeader'
import {
  Button,
  FormikInput,
  FormikLabelError,
  FormikRadioButton,
  GridRowColumn,
  Text,
  getFieldName,
  makeReqStringSchema,
} from '../../components/BaseComponents'

import {
  UPDATE_USER_KEY,
  fetchCurrentUserDetailsIfNeeded,
  updateUser,
} from '../../actions/userActions'
import { useReselector, useTimeoutRef } from '../../utils/sharedHooks'
import {
  getCurrentUser,
  selectMembershipIsIn,
} from '../../selectors/user.selectors'
import { useSignupPageUpdate } from '../../services/onboardingService'
import {
  ELIGIBLE_FREE_TRIAL_PARAM_VALUES,
  FREE_TRIAL_14_DAYS,
  FREE_TRIAL_DAYS,
  FREE_TRIAL_PROMO,
  SIGNUP_PATHS,
} from './helpers'
import { useAnalyticsTrack, useAnalyticsView } from '../Amplitude'
import { FormikProvider, useFormik } from 'formik'
import {
  AcquisitionSource,
  MembershipScopes,
} from '../../reducers/auth/userReducer'
import { getFetchError, getIsFetching } from '../../reducers/fetch'
import { useAppDispatch } from '../../utils/typeHelpers'

const STRIPE_WEBHOOK_TIMEOUT = 3000

const SignupSuccess = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const timeout = useTimeoutRef()
  const currentUser = useReselector(getCurrentUser)
  const [searchParams] = useSearchParams()
  const isUpdatingUser = useReselector(getIsFetching, UPDATE_USER_KEY)
  const errorUpdatingUser = useReselector(getFetchError, UPDATE_USER_KEY)
  const hasActiveOrTrialMembership = useReselector(
    selectMembershipIsIn,
    MembershipScopes.activePayingOrTrial
  )

  useSignupPageUpdate(SIGNUP_PATHS.success)

  // Once a successful payment comes through a user will be updated to active.
  // We do not want them to navigate to their dashboard until this happens though so refetch the user with a timeout on this page
  // Note this update user state logic will not work on locally unless stripe webhooks are set up
  useEffect(() => {
    timeout.current = setTimeout(() => {
      dispatch(fetchCurrentUserDetailsIfNeeded(true))
      setLoading(false)
    }, STRIPE_WEBHOOK_TIMEOUT)
  }, [dispatch, timeout])

  const pageView = useAnalyticsView()
  const track = useAnalyticsTrack()

  const trialParameterValue = searchParams.get('trial')
  const isFreeTrial =
    trialParameterValue &&
    ELIGIBLE_FREE_TRIAL_PARAM_VALUES.includes(trialParameterValue)
  const freeTrialDays =
    trialParameterValue === FREE_TRIAL_PROMO
      ? FREE_TRIAL_14_DAYS
      : FREE_TRIAL_DAYS
  const titleText = isFreeTrial
    ? `Thank you for starting your ${freeTrialDays}-day free trial!`
    : 'Thank you for your payment!'

  useEffect(() => {
    pageView('sign up payment success')
  }, [pageView])

  const formik = useFormik({
    initialValues: {
      acquisitionSource: null,
      acquisitionSourceComment: null,
    },
    onSubmit: async ({ acquisitionSource, acquisitionSourceComment }) => {
      // Acquisition comment should only have a value when other is selected
      // (prevent a lingering value if the user inputs something, then selects a diff option)
      let commentToPersist = acquisitionSourceComment
      if (acquisitionSource !== AcquisitionSource.other) {
        commentToPersist = null
      }

      const user = await updateUser(currentUser?.id, {
        acquisitionSource,
        acquisitionSourceComment: commentToPersist,
      })(dispatch)

      if (user) {
        navigate('/')
      }
      track('completed payment confirmation page', {
        acquisitionSource,
        acquisitionSourceComment,
      })
    },
  })

  useEffect(() => {
    // Clear acquisition comment if "other" is not selected
    if (
      formik.values.acquisitionSource !== AcquisitionSource.other &&
      formik.values.acquisitionSourceComment
    ) {
      formik.setFieldValue(
        getFieldName<typeof formik.values>('acquisitionSourceComment'),
        null
      )
    }
  }, [formik])

  const otherFieldSelected =
    formik.values.acquisitionSource === AcquisitionSource.other

  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid>
        <SignupHeader currentStep={3} />
        <Grid.Row />
        <Grid.Row>
          <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
            <Image
              src="https://heard-images.s3.us-west-1.amazonaws.com/assets/celebration_confentti.png"
              style={{ height: 165 }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Text as="h1">{titleText}</Text>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn />
        <Grid.Row className="short" columns={3}>
          <Grid.Column width={4} />
          <Grid.Column width={8}>
            <FormikProvider value={formik}>
              <Grid>
                <GridRowColumn>
                  <FormikLabelError
                    name={getFieldName<typeof formik.values>(
                      'acquisitionSource'
                    )}
                    label="One last thing, how did you hear about us?"
                    schema={makeReqStringSchema()}
                    required
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikRadioButton
                    name={getFieldName<typeof formik.values>(
                      'acquisitionSource'
                    )}
                    value={AcquisitionSource.webSearch}
                    label="Web Search (Google, Bing, Yahoo, etc.)"
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikRadioButton
                    name={getFieldName<typeof formik.values>(
                      'acquisitionSource'
                    )}
                    value={AcquisitionSource.onlineAds}
                    label="Online Ads"
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikRadioButton
                    name={getFieldName<typeof formik.values>(
                      'acquisitionSource'
                    )}
                    value={AcquisitionSource.colleagueOrFriend}
                    label="Colleague or Friend"
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikRadioButton
                    name={getFieldName<typeof formik.values>(
                      'acquisitionSource'
                    )}
                    value={AcquisitionSource.socialMedia}
                    label="Social Media (Facebook, Instagram, LinkedIn)"
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikRadioButton
                    name={getFieldName<typeof formik.values>(
                      'acquisitionSource'
                    )}
                    value={AcquisitionSource.events}
                    label="Events (Webinars, Podcasts, Workshops)"
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikRadioButton
                    name={getFieldName<typeof formik.values>(
                      'acquisitionSource'
                    )}
                    value={AcquisitionSource.onlineCommunities}
                    label="Online Communities (Private Practice Pro, Private Practice Skills, etc.)"
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikRadioButton
                    name={getFieldName<typeof formik.values>(
                      'acquisitionSource'
                    )}
                    value={AcquisitionSource.therapyPlatforms}
                    label="Therapy Platforms (Alma, Grow Therapy, Path, etc.)"
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikRadioButton
                    name={getFieldName<typeof formik.values>(
                      'acquisitionSource'
                    )}
                    value={AcquisitionSource.mailer}
                    label="Mailer"
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikRadioButton
                    name={getFieldName<typeof formik.values>(
                      'acquisitionSource'
                    )}
                    value={AcquisitionSource.other}
                    label="Other"
                  />
                </GridRowColumn>
                {otherFieldSelected && (
                  <GridRowColumn>
                    <FormikInput
                      fullWidth
                      name={getFieldName<typeof formik.values>(
                        'acquisitionSourceComment'
                      )}
                      schema={makeReqStringSchema()}
                      placeholder="Tell us more"
                    />
                  </GridRowColumn>
                )}
              </Grid>
            </FormikProvider>
          </Grid.Column>
          <Grid.Column width={4} />
        </Grid.Row>
        {/* Network Error */}
        {errorUpdatingUser && (
          <GridRowColumn>
            <Message error>An error occurred, please try again.</Message>
          </GridRowColumn>
        )}
        <Grid.Row>
          <Grid.Column width={6} />
          <Grid.Column width={4}>
            <Button
              onClick={formik.submitForm}
              fullWidth
              disabled={
                loading ||
                !currentUser ||
                !hasActiveOrTrialMembership ||
                isUpdatingUser
              }
              id="btn-signup-payment-success"
              loading={loading || isUpdatingUser}
            >
              Go to Heard
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>
    </Container>
  )
}

export default SignupSuccess
