import { Moment } from 'moment'
import { Grid } from 'semantic-ui-react'
import { getUserTransactionsSelector } from '../../../features/Transactions/transactions.selectors'
import MobileTableRow from '../../../features/Transactions/TransactionTable/MobileTableRow'
import { GridRowColumn, Text } from '../../BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'

export const ReportsTransactionTable = ({
  startDate,
  endDate,
}: {
  startDate: Moment
  endDate: Moment
}) => {
  const transactions = useReselector(getUserTransactionsSelector, {
    startDate,
    endDate,
  })

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={3}>
          <Text as="h3">Date</Text>
        </Grid.Column>
        <Grid.Column width={3} textAlign="right">
          <Text as="h3">Amount</Text>
        </Grid.Column>
        <Grid.Column width={6}>
          <Text as="h3">Description</Text>
        </Grid.Column>
        <Grid.Column width={4}>
          <Text as="h3">Transaction Category</Text>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn short>
        {transactions.length > 0 &&
          transactions.map((transaction) => (
            <MobileTableRow
              key={transaction.id}
              transaction={transaction}
              reports
            />
          ))}
        {transactions.length === 0 && (
          <GridRowColumn>
            <Text as="bodyMd">No transactions this month!</Text>
          </GridRowColumn>
        )}
      </GridRowColumn>
      <GridRowColumn />
      <GridRowColumn />
    </Grid>
  )
}
