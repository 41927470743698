import AhaMomentsBaseComponent from '../AhaMomentsBaseComponent'
import { Link, Text } from '../../../../components/BaseComponents'
import { Image } from 'semantic-ui-react'
import { selectIsCurrentUserScorp } from '../../../../selectors/user.selectors'
import { useReselector } from '../../../../utils/sharedHooks'
import { ADDITIONAL_1040_COST } from '../../../Taxes/AnnualTaxes/constants'

const AnnualTaxes = ({
  handleContinue,
  handleBack,
}: {
  handleContinue: () => void
  handleBack: () => void
}) => {
  const isScorp = useReselector(selectIsCurrentUserScorp)

  return (
    <AhaMomentsBaseComponent
      title="Get ready for taxes"
      subtitle="How do annual taxes work?"
      description="Heard offers annual tax filing as part of your membership, and makes it easy to do so."
      media={
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/bank_block.svg"
          style={{
            width: '100%',
          }}
        />
      }
      features={[
        {
          imgSrc: 'https://heard-images.s3.amazonaws.com/assets/tax_form.svg',
          title: 'Tell us your annual tax needs',
          description:
            'Each year, we’ll help you figure out your annual tax return needs with a quick and simple survey.',
        },
        {
          imgSrc:
            'https://heard-images.s3.amazonaws.com/assets/tax_checklist.svg',
          title: 'Complete your Annual Tax Checklist',
          description:
            'Starting in January, you’ll complete an Annual Tax Checklist that provides us with the information and documents needed to file your taxes.',
        },
        {
          imgSrc: 'https://heard-images.s3.amazonaws.com/assets/messages.svg',
          title: 'Work with a dedicated tax professional',
          description:
            'We’ve partnered with Taxfyle to assign you a dedicated tax professional. They will handle finalizing and filing your tax return.',
        },
      ]}
      learnMoreItems={[
        {
          title: 'How long will it take to file my annual taxes?',
          content: (
            <Text>
              You can expect to receive a draft return within 7 days once your
              tax preparer has the information they need. If additional
              information is required along the way, your tax preparer will
              reach out to you. After your review, the filing date will depend
              on how long it takes to work through any revisions with your tax
              preparer.
            </Text>
          ),
        },
        {
          title: 'What is Taxfyle and why does Heard partner with them?',
          content: (
            <Text>
              Taxfyle is an online tax filing platform with access to over 7,000
              licensed CPAs and EAs located nationwide, averaging 12 years of
              experience in US state and federal tax preparation. Founded in
              2015, Taxfyle has been supporting individuals, small businesses,
              CPA firms, and more by compliantly, confidently, and conveniently
              filing their taxes.
              <br />
              <br />
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/19788518004887-Taxfyle-FAQs"
                newPage
                as="secondaryLink"
              >
                Learn more about Taxfyle
              </Link>
            </Text>
          ),
        },
        ...(isScorp
          ? [
              {
                title:
                  'Will Heard help file my business and personal tax return?',
                content: (
                  <Text>
                    Personal federal and state income tax filings are included
                    for sole proprietors.
                    <br />
                    <br />
                    For S corporations, your business returns are included and
                    you can add on personal filing for ${ADDITIONAL_1040_COST}.
                    <br />
                    <br />
                    Heard will not complete tax filings or tax planning for
                    nonprofits, businesses with shareholders who are not US
                    citizens, or personal income taxes for non-US citizens.
                  </Text>
                ),
              },
            ]
          : []),
      ]}
      backButtonText="Back"
      continueButtonText="Continue"
      handleContinue={handleContinue}
      handleBack={handleBack}
    />
  )
}

export default AnnualTaxes
