import { Card, Grid, Image } from 'semantic-ui-react'
import { Colors } from '../../styles/theme'
import { useIsDeviceWidth, DeviceWidth } from '../../utils/deviceWidthHelpers'
import { Text } from '../../components/BaseComponents'
import {
  FREE_TRIAL_14_DAYS,
  FREE_TRIAL_DAYS,
  FREE_TRIAL_PROMO,
} from './helpers'
import { useSearchParams } from 'react-router-dom'

const FreeTrialSignupBanner = () => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const [searchParams] = useSearchParams()
  const freeTrialSearchParamValue = searchParams.get('trial')

  const freeTrialDays =
    freeTrialSearchParamValue === FREE_TRIAL_PROMO
      ? FREE_TRIAL_14_DAYS
      : FREE_TRIAL_DAYS
  return (
    <Card
      fullWidth
      type="subsection"
      style={{
        width: '100%',
        background: `${Colors['moss']}`,
        padding: '20px 40px',
      }}
    >
      <Grid centered>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4} textAlign="center">
            <Image
              src={
                'https://heard-images.s3.amazonaws.com/assets/book-celebration.svg'
              }
              alt="banner"
              style={{
                height: 100,
              }}
            />
          </Grid.Column>
          <Grid.Column width={12}>
            <Text as="h2" textAlign={isMobile ? 'center' : undefined}>
              Try Heard for free
            </Text>
            <Text
              as="bodyMd"
              color="darkGray"
              textAlign={isMobile ? 'center' : undefined}
            >
              Start your {freeTrialDays} day free trial, and learn how Heard can
              transform and simplify your practice finances. We’ll remind you
              when you have a few days left in your trial.
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export default FreeTrialSignupBanner
