import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import {
  Accordion,
  Button,
  Card,
  GridRowColumn,
  Link,
  Text,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import {
  getCurrentUser,
  isFreeTrialPromoCode,
} from '../../selectors/user.selectors'
import { useAppDispatch } from '../../utils/typeHelpers'
import { postCompleteOnboarding } from '../Dashboard/UserActionItems/userActionItems.slice'
import { GroupOnboardingCard } from './GroupOnboardingCard'
import { updateUser } from '../../actions/userActions'
import { useAnalyticsTrack, useAnalyticsView } from '../Amplitude'
import './styles.scss'
import {
  postCreateNewTicket,
  postCreateWelcomeTicket,
} from '../Conversations/actions'
import { OTHER_TICKET_TOPIC } from '../Conversations/conversation.slice'
import { selectPlaidItems } from '../../selectors/financeSelectors'
import {
  StatementStatus,
  StatementStatusType,
  getAccountUploadStatuses,
} from '../../actions/financialAccountActions'
import { logSentryError } from '../../utils/sentryHelpers'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'
import { ONBOARDING_V2_ROUTE_NAMES } from './config'

const MISSING_FI_TICKET_SUBJECT = 'Onboarding missing FI from Plaid'
const MISSING_FI_TICKET_COPY =
  'This user has indicated during onboarding that one or more of their business bank accounts cannot be connected via Plaid.'
const MISSING_FI_TICKET_TAGS = ['onboarding_missing_fi']

const ONBOARDING_LBA_HEADER = 'Onboarding Limited Bank Access Request'
const ONBOARDING_LBA_TICKET_COPY =
  'This user has indicated during onboarding they are interested in setting up limited bank access to their business bank account(s). Supported institutions:'
const ONBOARDING_LBA_TICKET_TAGS = ['onboarding_lba']

export const OtherFeature = ({
  imgSrc,
  title,
  description,
}: {
  imgSrc: string
  title: string
  description: string | React.ReactNode
}) => (
  <Grid>
    <Grid.Row verticalAlign="middle">
      <Grid.Column width={3}>
        <img src={imgSrc} alt="feature_image" width={228} />
      </Grid.Column>
      <Grid.Column width={13}>
        <Text as="h3" style={{ marginBottom: 6 }}>
          {title}
        </Text>
        <Text> {description} </Text>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

export const SetupComplete = () => {
  const user = useReselector(getCurrentUser)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()
  const ahaMoment = false
  const [statuses, setStatuses] = useState<StatementStatus[]>()
  const [loading, setLoading] = useState(false)
  const plaidItems = useReselector(selectPlaidItems)
  const shouldShowGettingStartedMVP = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.gettingStartedMVP,
    false
  )
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  useEffect(() => {
    pageView('register for group onboarding call')
  }, [pageView])

  useEffect(() => {
    const fetchStatuses = async () => {
      const statusesResponse = await dispatch(getAccountUploadStatuses())
      if (statusesResponse) {
        setStatuses(statusesResponse)
      }
    }
    fetchStatuses()
  }, [dispatch])

  useEffect(() => {
    if (
      user?.lastOnboardingScreen !==
      ONBOARDING_V2_ROUTE_NAMES.ONBOARDING_COMPLETE
    ) {
      const update = async () => {
        await updateUser(user?.id, {
          lastOnboardingScreen: ONBOARDING_V2_ROUTE_NAMES.ONBOARDING_COMPLETE,
        })(dispatch)
      }

      update()
    }
  }, [user, dispatch])

  const lbaSupportedInstitutions = useMemo(() => {
    if (!statuses) return []
    const plaidItemArray = Object.values(plaidItems)
    const filteredItems = plaidItemArray.map((item) => {
      const accounts = item.accounts.filter((account) =>
        statuses.some(
          (status) =>
            status.id === account.id &&
            status.status === StatementStatusType.LIMITED_BANK_ACCESS
        )
      )
      return {
        ...item,
        accounts,
      }
    })
    return filteredItems.filter((item) => item.accounts.length > 0)
  }, [plaidItems, statuses])

  const sendOnboardingCompleteSideEffects = async () => {
    // dont create tickets / send emails if onboarding is already completed
    if (user?.onboardingItemsCompletedAt) return
    // create internal ZD ticket if user indicated during onboarding that one or more of their business bank accounts cannot be connected via Plaid
    if (user?.financialProfile?.hasIncompatibleAccounts) {
      try {
        dispatch(
          postCreateNewTicket({
            internalSubmitter: true,
            comment: {
              body: MISSING_FI_TICKET_COPY,
              htmlBody: `<p>${MISSING_FI_TICKET_COPY}</p>`,
            },
            topic: OTHER_TICKET_TOPIC,
            subject: MISSING_FI_TICKET_SUBJECT,
            tags: MISSING_FI_TICKET_TAGS,
          })
        )
      } catch (error) {
        logSentryError(error)
      }
    }
    // create internal ZD ticket if user indicated during onboarding they are interested in setting up limited bank access to their business bank account(s)
    if (user?.financialProfile?.limitedBankAccessInterest) {
      try {
        dispatch(
          postCreateNewTicket({
            internalSubmitter: true,
            comment: {
              body: `${ONBOARDING_LBA_TICKET_COPY} ${lbaSupportedInstitutions.map((item) => item.institutionName).toString()}`,
              htmlBody: `<p>${ONBOARDING_LBA_TICKET_COPY} 
          <ul>
            ${lbaSupportedInstitutions.map((item) => `<li>${item.institutionName}</li>`)}
          </ul>
        </p>`,
            },
            topic: OTHER_TICKET_TOPIC,
            subject: ONBOARDING_LBA_HEADER,
            tags: ONBOARDING_LBA_TICKET_TAGS,
          })
        )
      } catch (error) {
        logSentryError(error)
      }
    }
    await dispatch(postCompleteOnboarding())
    try {
      dispatch(postCreateWelcomeTicket())
    } catch (error) {
      logSentryError(error)
    }
  }

  const completeSetup = async () => {
    setLoading(true)
    track('completed onboarding items')
    await sendOnboardingCompleteSideEffects()
    navigate('/dashboard')
    window.location.reload()
    setLoading(false)
  }

  const setupHeaderText = hasFreeTrialPromoCode
    ? 'Your Heard trial experience is ready'
    : 'Your Heard account is ready'

  const otherFeatureItems = () => {
    if (hasFreeTrialPromoCode) {
      return (
        <>
          <OtherFeature
            imgSrc="https://heard-images.s3.amazonaws.com/assets/chart-tablet.svg"
            title="Financial Insights"
            description="Detailed metrics on your income and expenses"
          />
          <OtherFeature
            imgSrc="https://heard-images.s3.amazonaws.com/assets/booklist.svg"
            title="Guides and Resources"
            description="Learn all the tips and tricks of managing your private practice finances."
          />
          <OtherFeature
            imgSrc="https://heard-images.s3.amazonaws.com/assets/green_piggy_bank.svg"
            title="Stay Compliant with Taxes"
            description="Preview the Tax experience with Heard, and learn how we help your practice stay compliant"
          />
        </>
      )
    } else {
      return (
        <>
          <OtherFeature
            imgSrc="https://heard-images.s3.amazonaws.com/assets/chart-tablet.svg"
            title="Financial Insights"
            description="Detailed metrics on your income and expenses"
          />
          <OtherFeature
            imgSrc="https://heard-images.s3.amazonaws.com/assets/green_piggy_bank.svg"
            title="Budgeting Guides"
            description="Set up how much to put aside for taxes and pay"
          />
        </>
      )
    }
  }
  return (
    <Grid className="SetupComplete">
      <GridRowColumn>
        <Text as="display2"> {setupHeaderText} </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Card
          backgroundColor="lightOrange"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          fullWidth
        >
          <img
            src="https://heard-images.s3.amazonaws.com/assets/laptop_chair.svg"
            alt="office_chair"
            width={174}
            height={168}
          />
        </Card>
      </GridRowColumn>
      <GridRowColumn>
        {hasFreeTrialPromoCode ? (
          <Text as="bodyLg">
            Thanks, {user?.firstName}. We&apos;re so excited to have you try the
            Heard experience.
            <br />
            <br />
            Here&apos;s what to look forward to:
          </Text>
        ) : (
          <Text as="bodyLg">
            Thanks, {user?.firstName}. We have everything we need to start
            working on your books. Once they&apos;re complete, here&apos;s what
            to look forward to:
          </Text>
        )}
      </GridRowColumn>
      <GridRowColumn
        columnStyle={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {otherFeatureItems()}
      </GridRowColumn>

      {user?.financialProfile?.hasIncompatibleAccounts && (
        <GridRowColumn className="SetupComplete__card">
          <div className="SetupComplete__card-header">
            <div className="SetupComplete__copy">
              <Text as="h3" className="SetupComplete__row-item">
                Still have accounts that couldn&apos;t be connected?
              </Text>
              <Text className="SetupComplete__row-item">
                We&apos;ll follow up via Conversations regarding any account(s)
                that were incompatible with Plaid.
              </Text>
              <Text className="SetupComplete__row-item">
                In the meantime,{' '}
                <b>
                  we recommend replacing them with accounts at compatible banks.
                </b>{' '}
                This allows us to provide you the most consistent service.
              </Text>
            </div>

            <Image
              src="https://heard-images.s3.amazonaws.com/assets/pavilion_exclamation_mark.png"
              style={{ width: 78, height: 65, marginLeft: 32 }}
            />
          </div>
          <Accordion
            title="What if I don't have a business account at all?"
            style={{ marginBottom: 20 }}
            content={
              <Text>
                Here are some widely used banks that are compatible with Plaid
                and used by Heard customers for their business bank accounts:
                <br />
                <ul className="SetupComplete-list">
                  <li>
                    <Link
                      as="secondaryLink"
                      href="https://www.bluevine.com/partner/heard-checking"
                      newPage
                    >
                      Bluevine
                    </Link>
                  </li>
                  <li>
                    <Link
                      as="secondaryLink"
                      href="https://www.bankofamerica.com/smallbusiness/deposits/business-advantage-banking/"
                      newPage
                    >
                      Bank of America
                    </Link>
                  </li>
                  <li>
                    <Link
                      as="secondaryLink"
                      href="https://www.becu.org/business-banking/checking"
                      newPage
                    >
                      BECU
                    </Link>
                  </li>
                  <li>
                    <Link
                      as="secondaryLink"
                      href="https://www.chase.com/business/banking/checking"
                      newPage
                    >
                      Chase
                    </Link>
                  </li>
                  <li>
                    <Link
                      as="secondaryLink"
                      href="https://www.td.com/us/en/small-business/checking-accounts"
                      newPage
                    >
                      TD Bank
                    </Link>
                  </li>
                  <li>
                    <Link
                      as="secondaryLink"
                      href="https://www.wellsfargo.com/biz/checking/"
                      newPage
                    >
                      Wells Fargo
                    </Link>
                  </li>
                </ul>
              </Text>
            }
            variant="text"
          />
          <Accordion
            title="What if I don't want to replace my account(s)?"
            content={
              <>
                <Text style={{ marginBottom: 4 }}>
                  Our team will reach out to help you with your situation.
                  However, we recommend replacing your accounts, as
                  Plaid-incompatible accounts significantly delay bookkeeping
                  and access to your reports.
                </Text>
                <Text>
                  We might not be able to support the account(s) if it prevents
                  us from providing consistent service.
                </Text>
              </>
            }
            variant="text"
          />
        </GridRowColumn>
      )}
      {!shouldShowGettingStartedMVP && (
        <GridRowColumn>
          <GroupOnboardingCard
            sendOnboardingCompleteSideEffects={
              sendOnboardingCompleteSideEffects
            }
          />
        </GridRowColumn>
      )}

      <GridRowColumn className="SetupComplete__button-group">
        {ahaMoment && (
          <Button
            variant="primary"
            onClick={completeSetup}
            style={{ marginBottom: 12 }}
            loading={loading}
          >
            Get Set Up For Success
          </Button>
        )}
        <Button variant="secondary" onClick={completeSetup} loading={loading}>
          View Dashboard
        </Button>
      </GridRowColumn>
    </Grid>
  )
}
