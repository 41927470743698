import { useEffect } from 'react'
import { Grid, Header, Image, Loader } from 'semantic-ui-react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Card, Text, Icon } from '../../../components/BaseComponents'
import './styles.scss'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { useAnalyticsView } from '../../Amplitude'
import { isFreeTrialPromoCode } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'

interface IAccountQuestionsEndScreenProps {
  accounts?: FinancialAccount[]
  transactionsCount: number
  hoursSaved: number
  isFetchingTransactions: boolean
}

export const AccountQuestionsEndScreen: React.FC<
  IAccountQuestionsEndScreenProps
> = ({ accounts, transactionsCount, hoursSaved, isFetchingTransactions }) => {
  const pageView = useAnalyticsView()
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  const headerText = hasFreeTrialPromoCode
    ? 'Thanks for connecting your accounts!'
    : 'Our bookkeeping team is getting to work'
  useEffect(() => {
    pageView('payoff')
  }, [pageView])

  return (
    <Grid>
      <Grid.Row style={{ paddingTop: 32 }}>
        <Grid.Column width={5}>
          <Image
            className="AccountQuestionsEndScreen__image"
            src={
              'https://heard-images.s3.amazonaws.com/assets/receipt-2-coins.png'
            }
          />
        </Grid.Column>

        <Grid.Column width={8} style={{ marginLeft: 32 }}>
          <Header as="h2" style={{ fontWeight: 500 }}>
            {headerText}
          </Header>
          {isFetchingTransactions ? (
            <Loader active />
          ) : (
            <>
              <Text>
                You’ve connected <b>{accounts?.length}</b> accounts with{' '}
                <b>{transactionsCount}</b> transactions.
              </Text>
              <Card className="AccountQuestionsEndScreen__card">
                <Icon icon={solid('sparkles')} />
                <Text>
                  {hoursSaved > 1 ? (
                    <b>{`You’re saving ${hoursSaved} hours`}</b>
                  ) : (
                    <>
                      <b>You’re saving time</b> that you would have spent
                      categorizing your books by yourself.
                    </>
                  )}
                  {hasFreeTrialPromoCode
                    ? ' This is one of the many ways that Heard can help you manage your finances.'
                    : ' Moving forward, we’ll categorize your transactions for you.'}
                </Text>
              </Card>
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
