import AnnualTaxProgressOverview from './AnnualTaxProgressOverview'
import { useReselector } from '../../../../utils/sharedHooks'
import IncompleteTaxFilingFormPanel from './IncompleteTaxFilingFormPanel'
import TaxSeasonKickoffIncomplete from './TaxSeasonKickoffIncomplete'
import UserDisqualifiedPanel from './UserDisqualifiedPanel'
import NoTaxServicesPanel from './NoTaxServicesPanel'
import {
  AnnualTaxPanelDisplay,
  selectAnnualTaxDisplay,
} from '../annualTaxFilings.selector'
import NoTaxFilingPanel from './NoTaxFilingPanel'
import UserMissedDeadlinePanel from './UserMissedDeadlinePanel'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'
import { isFreeTrialPromoCode } from '../../../../selectors/user.selectors'
import FreeTrialAnnualTaxPreview from './FreeTrialAnnualTaxPreview'

const AnnualTaxYearPanel = () => {
  //for users who we manually want to allow to ignore the deadlines and continue with the tax filing process
  const skipDeadline = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.allowAnnualTaxAdHocDeadlineExtension,
    false
  )
  const annualTaxDisplay = useReselector(selectAnnualTaxDisplay, skipDeadline)
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)
  /*
    If user is in free trial, we want to show a preview of the Annual Tax Experience
  */
  if (hasFreeTrialPromoCode) {
    return <FreeTrialAnnualTaxPreview />
  }
  switch (annualTaxDisplay) {
    case AnnualTaxPanelDisplay.userDisqualified:
      return <UserDisqualifiedPanel />
    case AnnualTaxPanelDisplay.userMissedDeadline:
      return <UserMissedDeadlinePanel />
    case AnnualTaxPanelDisplay.noTaxFiling:
      return <NoTaxFilingPanel />
    case AnnualTaxPanelDisplay.taxSeasonKickoffIncomplete:
      return <TaxSeasonKickoffIncomplete />
    case AnnualTaxPanelDisplay.noTaxServices:
      return <NoTaxServicesPanel />
    case AnnualTaxPanelDisplay.incompleteTaxFilingForm:
      return <IncompleteTaxFilingFormPanel />
    case AnnualTaxPanelDisplay.annualTaxProgress:
    default:
      return <AnnualTaxProgressOverview />
  }
}

export default AnnualTaxYearPanel
