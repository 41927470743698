import { ReactNode } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { isNumber } from 'lodash'

import {
  Accordion,
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
  Link,
  Alert,
  Popup,
  Label,
} from '../../../../../components/BaseComponents'
import PaymentCard from './PaymentCard'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  isTaxCalculationOutput,
  UserTaxCalculation,
} from '../../../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import { FILING_STATUSES, TAX_ENTITY_TYPES } from '../../../taxConstants'
import {
  centsToCurrency,
  CurrencyInput,
  formatCurrency,
  formatCurrencyFromCents,
  isCurrency,
} from '../../../../../utils/currencyHelpers'
import { selectFederalEstimateByTaxQuarter } from '../../userTaxEstimates.selector'
import {
  DeductiblePotionSelfEmploymentTooltip,
  OtherW2IncomeTooltip,
  PercentageDueThisQuarterTooltip,
  ProjectedAnnualTaxBillTooltip,
  ProjectedFederalIncomeTaxTooltip,
  QBIDeductionTooltip,
  QuarterlyTaxesPaidToDateTooltip,
  QuarterlyYTDTaxBillTooltip,
  Scorp1099IncomeTooltip,
  ScorpPrivatePracticeIncomeTooltip,
  ScorpProjectedAnnualIncomeTooltip,
  ScorpSelfEmploymentTooltip,
  Sprop1099IncomeTooltip,
  PrivatePracticeNetIncomeTooltip,
  SpropProjectedAnnualIncomeTooltip,
  SpropSelfEmploymentTooltip,
  StandardDeductionTooltip,
  TaxWithholdingsPaidToDateTooltip,
  TotalTaxableIncomeTooltip,
  ZeroEstimateTooltip,
  ScorpEstimatedPrivatePracticeIncomeTooltip,
  OtherW2IncomeEstimatedTooltip,
} from './EstimateBreakdownV3Tooltips'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import {
  QuarterlyTaxEstimateDetail,
  QuarterlyTaxEstimateDetailStatuses,
} from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import HighEstimatesAlert, {
  getIsTaxCalcIncomeHigh,
} from '../../HighEstimatesAlert'
import { FEATURE_FLAG_KEYS } from '../../../../OpenFeature'
import { Colors } from '../../../../../styles/theme'

const isScorpCalc = (taxCalc: UserTaxCalculation | undefined) =>
  taxCalc?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s

const doesCalcHaveSpouse = (taxCalc: UserTaxCalculation | undefined) =>
  taxCalc?.filingStatus &&
  [
    FILING_STATUSES.married_filing_jointly,
    FILING_STATUSES.qualifying_widow,
  ].includes(taxCalc.filingStatus)

const centsToDollarsOrDashes = (val: CurrencyInput, showNeg?: boolean) =>
  val && (!isCurrency(val) || val.value !== 0)
    ? `${showNeg ? '-' : ''}${formatCurrencyFromCents(val)}`
    : '--'

const BreakdownTooltip = ({
  trigger,
  content,
}: {
  trigger: ReactNode
  content: ReactNode
}) => (
  <Popup
    trigger={<Button variant="tooltipLink">{trigger}</Button>}
    content={content}
  />
)

const QTEFAQs = () => (
  <Accordion
    title={
      <Text style={{ display: 'inline-block' }} as="h2">
        Common FAQs
      </Text>
    }
    titleStyle={{}}
    content={
      <Grid>
        <GridRowColumn>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/22369431571863"
            newPage
          >
            How to make adjustments to quarterly tax estimate
          </Link>
        </GridRowColumn>

        <GridRowColumn>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/10122365411223"
            newPage
          >
            How do I find my quarterly tax payment receipt?
          </Link>
        </GridRowColumn>
        <GridRowColumn>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4416208116247"
            newPage
          >
            How does Heard calculate quarterly estimated taxes?
          </Link>
        </GridRowColumn>
        <GridRowColumn>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4416233114647"
            newPage
          >
            Should I pay quarterly taxes from my personal or business bank
            account?
          </Link>
        </GridRowColumn>
        <GridRowColumn>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4416196188439"
            newPage
          >
            What do you mean by estimate? Can I pay more or less than the
            estimate you gave me?
          </Link>
        </GridRowColumn>
        <GridRowColumn>
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/4416233114647"
            newPage
          >
            Can I transfer money from my business account to pay for quarterly
            taxes?
          </Link>
        </GridRowColumn>
        <GridRowColumn>
          <Link
            style={{ color: Colors.forest }}
            href="https://support.joinheard.com/hc/en-us/sections/4416200689303"
            newPage
            as="secondaryLink"
          >
            View all FAQs
          </Link>
        </GridRowColumn>
      </Grid>
    }
  />
)

const AccordionHeader = ({
  left,
  right,
}: {
  left: ReactNode
  right: ReactNode
}) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: 4,
    }}
  >
    {left}
    {right}
  </div>
)

const PencilLinkOrText = ({
  children,
  isActiveEstimate,
  hideLink,
}: {
  children: ReactNode
  isActiveEstimate: boolean
  hideLink?: boolean
}) => {
  if (!hideLink && isActiveEstimate) {
    return (
      <Link to="/taxes/quarterly/recalculate">
        {children}
        <Icon icon={regular('pen')} style={{ marginLeft: 8 }} />
      </Link>
    )
  }

  return (
    <Text style={{ marginRight: isActiveEstimate ? 28 : 0 }}>{children}</Text>
  )
}

const CalculationRow = ({
  left,
  center,
  right,
  inCard,
}: {
  left: ReactNode
  center?: ReactNode
  right: ReactNode
  inCard?: boolean
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const shouldUseCenterColumn = Boolean(center && !isMobile)

  // For mobile the center will sit directly on top of the right
  return (
    <Grid.Row verticalAlign="middle">
      <Grid.Column width={shouldUseCenterColumn ? 8 : 10}>{left}</Grid.Column>
      {shouldUseCenterColumn && (
        <Grid.Column
          width={4}
          textAlign="right"
          style={{
            display: 'flex',
            alignItems: 'end',
          }}
        >
          {center}
        </Grid.Column>
      )}
      <Grid.Column
        width={shouldUseCenterColumn ? 4 : 6}
        textAlign="right"
        style={{
          display: 'flex',
          alignItems: 'end',
          paddingRight: inCard ? undefined : 36,
        }}
      >
        {isMobile && <div style={{ marginBottom: 4 }}>{center}</div>}
        {right}
      </Grid.Column>
    </Grid.Row>
  )
}

const CalculatedStatement = () => (
  <Text color="darkGray">
    <i>Calculated from your profit and loss statement</i>
  </Text>
)

const DeductionStatement = () => (
  <Text color="darkGray">
    <i>
      Our quarterly tax estimator uses the &quot;standard deduction&quot;
      amount. Currently, we’re not able to include pre-tax deductions like
      retirement contributions, investment returns or military tax-exemptions,
      but we will be able to in future quarters.{' '}
      <Link
        href="https://support.joinheard.com/hc/en-us/articles/4416208116247"
        newPage
      >
        Learn more
      </Link>
    </i>
  </Text>
)

const IncomeSection = ({
  estimateDetails,
  taxCalc,
  isSample,
}: {
  taxCalc: UserTaxCalculation | undefined
  estimateDetails: QuarterlyTaxEstimateDetail | undefined
  isSample?: boolean
}) => {
  const [searchParams] = useSearchParams()

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const taxCalculationOutput = taxCalc?.taxCalculationOutput
  const isScorp = isScorpCalc(taxCalc)
  const hasSpouse = doesCalcHaveSpouse(taxCalc)

  const isActiveEstimate =
    estimateDetails?.status === QuarterlyTaxEstimateDetailStatuses.active

  const shouldEnableQ1QteFollowups = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.q1QteFollowups,
    false
  )

  // This check is a little confusing - it's making sure that tax calc output is either undefined or it matches the expected shape
  if (
    !isTaxCalculationOutput(taxCalculationOutput) &&
    taxCalculationOutput !== undefined
  ) {
    return null
  }

  const incomeExpanded = searchParams.get('incomeExpanded') === 'true'
  const isIncomeHigh = getIsTaxCalcIncomeHigh(taxCalc)
  const quarter = estimateDetails?.taxQuarter
  const year = estimateDetails?.taxYear

  return (
    <>
      <GridRowColumn columnStyle={{ display: 'flex' }}>
        <Text as="h2">
          <Icon icon={regular('coins')} style={{ marginRight: 8 }} />
          Income
        </Text>
        {isSample && (
          <Label color="orange" style={{ marginLeft: 20 }}>
            Sample Data
          </Label>
        )}
      </GridRowColumn>

      {isScorp ? (
        <GridRowColumn computer={8} tablet={8} mobile={16} short>
          <Text color="darkGray">
            Based on the numbers you updated for{' '}
            {isActiveEstimate ? (
              <Link to="/taxes/quarterly/recalculate">
                your Q{quarter} tax information
              </Link>
            ) : (
              `your Q${quarter} tax information`
            )}
            , this is how much we&apos;re estimating you&apos;ll earn for the
            year (before tax deductions).
          </Text>
        </GridRowColumn>
      ) : (
        <GridRowColumn computer={8} tablet={8} mobile={16} short>
          <Text color="darkGray">
            Based on your private practice’s net income and any additional
            income sources from when you updated{' '}
            {isActiveEstimate ? (
              <Link to="/taxes/quarterly/recalculate">
                your Q{quarter} tax information
              </Link>
            ) : (
              `your Q${quarter} tax information`
            )}
            , this is how much we&apos;re estimating you&apos;ll earn this year
            (before tax deductions).
          </Text>
        </GridRowColumn>
      )}

      {isActiveEstimate && (
        <HighEstimatesAlert
          title="The income you entered seems high"
          taxCalc={taxCalc}
          unsetMobileMargin={-20}
        />
      )}

      {/*  Only one alert should display at a time*/}
      {isActiveEstimate &&
        incomeExpanded &&
        !isIncomeHigh &&
        shouldEnableQ1QteFollowups && (
          <GridRowColumn>
            <Alert
              unsetMobileMargin={-20}
              title="Do these numbers look off?"
              type="warn"
              customIcon={<Icon icon={regular('stars')} />}
            >
              {/*todo this text doesn't work with q4*/}
              Check that your entries are year-to-date (from January to the
              present) instead of annual (the entire year).{' '}
              <Link to="/taxes/quarterly/recalculate" as="secondaryLink">
                Make Adjustments
              </Link>
            </Alert>
          </GridRowColumn>
        )}

      <GridRowColumn>
        <Accordion
          initialOpen={incomeExpanded || isIncomeHigh}
          title={
            <AccordionHeader
              left={
                <BreakdownTooltip
                  trigger="Projected Annual Income"
                  content={
                    isScorp ? (
                      <ScorpProjectedAnnualIncomeTooltip />
                    ) : (
                      <SpropProjectedAnnualIncomeTooltip />
                    )
                  }
                />
              }
              right={
                <Text as="h3">
                  {centsToDollarsOrDashes(
                    taxCalculationOutput?.data.annualGrossIncomeInCents
                  )}
                </Text>
              }
            />
          }
          content={
            <Card backgroundColor="stone40" type="subsection">
              <Grid>
                <CalculationRow
                  left={
                    <Text as="eyebrow" color="darkGray">
                      income source
                    </Text>
                  }
                  center={
                    <Text as="eyebrow" color="darkGray" textAlign="right">
                      your entries
                    </Text>
                  }
                  right={
                    <Text as="eyebrow" color="darkGray" textAlign="right">
                      projected income
                    </Text>
                  }
                  inCard
                />
                {isScorp &&
                  taxCalc?.estimatedAnnualScorpPracticeW2IncomeInCents !==
                    null && (
                    <CalculationRow
                      left={
                        <BreakdownTooltip
                          trigger="W-2 private practice income (Estimated)"
                          content={
                            <ScorpEstimatedPrivatePracticeIncomeTooltip />
                          }
                        />
                      }
                      center={
                        <PencilLinkOrText
                          isActiveEstimate={isActiveEstimate}
                          hideLink={isSample}
                        >
                          {centsToDollarsOrDashes(
                            taxCalc?.estimatedAnnualScorpPracticeW2IncomeInCents
                          )}
                        </PencilLinkOrText>
                      }
                      right={
                        <Text as="h3">
                          {centsToDollarsOrDashes(
                            taxCalc?.annualizedScorpPracticeWIncomeInCents
                          )}
                        </Text>
                      }
                      inCard
                    />
                  )}

                {isScorp && taxCalc?.scorpPracticeWIncomeInCents !== null && (
                  <CalculationRow
                    left={
                      <BreakdownTooltip
                        trigger="W-2 private practice income (YTD)"
                        content={<ScorpPrivatePracticeIncomeTooltip />}
                      />
                    }
                    center={
                      <PencilLinkOrText
                        isActiveEstimate={isActiveEstimate}
                        hideLink={isSample}
                      >
                        {centsToDollarsOrDashes(
                          taxCalc?.scorpPracticeWIncomeInCents
                        )}
                      </PencilLinkOrText>
                    }
                    right={
                      <Text as="h3">
                        {centsToDollarsOrDashes(
                          taxCalc?.annualizedScorpPracticeWIncomeInCents
                        )}
                      </Text>
                    }
                    inCard
                  />
                )}

                <CalculationRow
                  left={
                    <>
                      <BreakdownTooltip
                        trigger="Private practice net income"
                        content={<PrivatePracticeNetIncomeTooltip />}
                      />
                      {!isMobile && (
                        <>
                          <br />
                          <CalculatedStatement />
                        </>
                      )}
                    </>
                  }
                  center={
                    <PencilLinkOrText
                      isActiveEstimate={isActiveEstimate}
                      hideLink
                    >
                      {/*todo*/}
                      {isNumber(taxCalc?.periodIncomeInCents) &&
                      isNumber(taxCalc?.periodExpensesInCents)
                        ? formatCurrency(
                            centsToCurrency(
                              taxCalc?.periodIncomeInCents
                            ).subtract(taxCalc?.periodExpensesInCents)
                          )
                        : '--'}
                    </PencilLinkOrText>
                  }
                  right={
                    <Text as="h3">
                      {centsToDollarsOrDashes(
                        taxCalc?.annualizedNetIncomeInCents
                      )}
                    </Text>
                  }
                  inCard
                />
                {isMobile && (
                  <GridRowColumn>
                    <CalculatedStatement />
                  </GridRowColumn>
                )}

                {isNumber(taxCalc?.wIncomeInCents) && (
                  <CalculationRow
                    left={
                      <BreakdownTooltip
                        trigger="Other W-2 income (YTD)"
                        content={<OtherW2IncomeTooltip />}
                      />
                    }
                    center={
                      <PencilLinkOrText
                        isActiveEstimate={isActiveEstimate}
                        hideLink={isSample}
                      >
                        {centsToDollarsOrDashes(taxCalc.wIncomeInCents)}
                      </PencilLinkOrText>
                    }
                    right={
                      <Text as="h3">
                        {centsToDollarsOrDashes(
                          taxCalc.annualizedWIncomeInCents
                        )}
                      </Text>
                    }
                    inCard
                  />
                )}

                {isNumber(taxCalc?.estimatedAnnualW2IncomeInCents) && (
                  <CalculationRow
                    left={
                      <BreakdownTooltip
                        trigger="Other W-2 income (Estimated)"
                        content={<OtherW2IncomeEstimatedTooltip />}
                      />
                    }
                    center={
                      <PencilLinkOrText
                        isActiveEstimate={isActiveEstimate}
                        hideLink={isSample}
                      >
                        {centsToDollarsOrDashes(
                          taxCalc.estimatedAnnualW2IncomeInCents
                        )}
                      </PencilLinkOrText>
                    }
                    right={
                      <Text as="h3">
                        {centsToDollarsOrDashes(
                          taxCalc.annualizedWIncomeInCents
                        )}
                      </Text>
                    }
                    inCard
                  />
                )}

                {isNumber(taxCalc?.otherIndividualIncomeInCents) && (
                  <CalculationRow
                    left={
                      <BreakdownTooltip
                        trigger={
                          isScorp ? '1099 income' : 'Additional 1099 income'
                        }
                        content={
                          isScorp ? (
                            <Scorp1099IncomeTooltip />
                          ) : (
                            <Sprop1099IncomeTooltip />
                          )
                        }
                      />
                    }
                    center={
                      <PencilLinkOrText
                        isActiveEstimate={isActiveEstimate}
                        hideLink={isSample}
                      >
                        {centsToDollarsOrDashes(
                          taxCalc.otherIndividualIncomeInCents
                        )}
                      </PencilLinkOrText>
                    }
                    right={
                      <Text as="h3">
                        {centsToDollarsOrDashes(
                          taxCalc.otherIndividualIncomeInCents
                        )}
                      </Text>
                    }
                    inCard
                  />
                )}

                {isNumber(taxCalc?.spouseWIncomeInCents) && (
                  <CalculationRow
                    left={<Text>Spouse’s W-2 income (YTD)</Text>}
                    center={
                      <PencilLinkOrText
                        isActiveEstimate={isActiveEstimate}
                        hideLink={isSample}
                      >
                        {centsToDollarsOrDashes(taxCalc.spouseWIncomeInCents)}
                      </PencilLinkOrText>
                    }
                    right={
                      <Text as="h3">
                        {centsToDollarsOrDashes(
                          taxCalc.annualizedSpouseWIncomeInCents
                        )}
                      </Text>
                    }
                    inCard
                  />
                )}

                {isNumber(taxCalc?.estimatedAnnualSpouseW2IncomeInCents) && (
                  <CalculationRow
                    left={<Text>Spouse’s W-2 income (Estimated)</Text>}
                    center={
                      <PencilLinkOrText
                        isActiveEstimate={isActiveEstimate}
                        hideLink={isSample}
                      >
                        {centsToDollarsOrDashes(
                          taxCalc.estimatedAnnualSpouseW2IncomeInCents
                        )}
                      </PencilLinkOrText>
                    }
                    right={
                      <Text as="h3">
                        {centsToDollarsOrDashes(
                          taxCalc.annualizedSpouseWIncomeInCents
                        )}
                      </Text>
                    }
                    inCard
                  />
                )}

                {hasSpouse &&
                  isNumber(taxCalc?.spouseIndividualIncomeInCents) && (
                    <CalculationRow
                      left={<Text>Spouse’s 1099 income</Text>}
                      center={
                        <PencilLinkOrText
                          isActiveEstimate={isActiveEstimate}
                          hideLink={isSample}
                        >
                          {centsToDollarsOrDashes(
                            taxCalc.spouseIndividualIncomeInCents
                          )}
                        </PencilLinkOrText>
                      }
                      right={
                        <Text as="h3">
                          {centsToDollarsOrDashes(
                            taxCalc.spouseIndividualIncomeInCents
                          )}
                        </Text>
                      }
                      inCard
                    />
                  )}
              </Grid>
            </Card>
          }
          variant="text"
        />
      </GridRowColumn>

      <GridRowColumn>
        <Accordion
          title={
            <AccordionHeader
              left={<Text>Deductions</Text>}
              right={
                <Text as="h3">
                  -
                  {centsToDollarsOrDashes(
                    centsToCurrency(
                      taxCalculationOutput?.data.federalStandardDeductionInCents
                    )
                      .add(
                        taxCalculationOutput?.data.annualQBIDeductionInCents ||
                          0
                      )
                      .add(
                        taxCalculationOutput?.data
                          .deductibleSelfEmploymentTaxInCents || 0
                      )
                  )}
                </Text>
              }
            />
          }
          content={
            <Card backgroundColor="stone40" type="subsection">
              <Grid>
                <CalculationRow
                  left={
                    <Text as="eyebrow" color="darkGray">
                      deduction source
                    </Text>
                  }
                  right={
                    <Text as="eyebrow" color="darkGray" textAlign="right">
                      amount
                    </Text>
                  }
                  inCard
                />

                <CalculationRow
                  left={
                    <>
                      <BreakdownTooltip
                        trigger="Standard deduction"
                        content={<StandardDeductionTooltip year={year} />}
                      />
                      {!isMobile && (
                        <>
                          <br />
                          <DeductionStatement />
                        </>
                      )}
                    </>
                  }
                  right={
                    <Text as="h3">
                      {centsToDollarsOrDashes(
                        taxCalculationOutput?.data
                          .federalStandardDeductionInCents,
                        true
                      )}
                    </Text>
                  }
                  inCard
                />
                {isMobile && (
                  <GridRowColumn>
                    <DeductionStatement />
                  </GridRowColumn>
                )}
                <CalculationRow
                  left={
                    <BreakdownTooltip
                      trigger="Qualified business income (QBI) deduction"
                      content={<QBIDeductionTooltip />}
                    />
                  }
                  right={
                    <Text as="h3">
                      {centsToDollarsOrDashes(
                        taxCalculationOutput?.data.annualQBIDeductionInCents,
                        true
                      )}
                    </Text>
                  }
                  inCard
                />
                {(!isScorp || hasSpouse) && (
                  <CalculationRow
                    left={
                      <BreakdownTooltip
                        trigger="Deductible portion of self-employment tax"
                        content={<DeductiblePotionSelfEmploymentTooltip />}
                      />
                    }
                    right={
                      <Text as="h3">
                        {centsToDollarsOrDashes(
                          taxCalculationOutput?.data
                            .deductibleSelfEmploymentTaxInCents,
                          true
                        )}
                      </Text>
                    }
                    inCard
                  />
                )}
              </Grid>
            </Card>
          }
          variant="text"
        />
      </GridRowColumn>

      <GridRowColumn>
        <Divider />
      </GridRowColumn>

      <CalculationRow
        left={
          <BreakdownTooltip
            trigger={<Text as="h3">Total Taxable Income</Text>}
            content={<TotalTaxableIncomeTooltip />}
          />
        }
        right={
          <Text as="h3" textAlign="right">
            {centsToDollarsOrDashes(
              taxCalculationOutput?.data.annualTaxableIncomeInCents
            )}
          </Text>
        }
      />
    </>
  )
}

const TaxesSection = ({
  taxCalc,
  estimateDetails,
  isSample,
}: {
  taxCalc: UserTaxCalculation | undefined
  estimateDetails: QuarterlyTaxEstimateDetail | undefined
  isSample?: boolean
}) => {
  const taxCalculationOutput = taxCalc?.taxCalculationOutput
  const isScorp = isScorpCalc(taxCalc)
  const hasSpouse = doesCalcHaveSpouse(taxCalc)
  const quarter = estimateDetails?.taxQuarter

  if (
    !isTaxCalculationOutput(taxCalculationOutput) &&
    taxCalculationOutput !== undefined
  ) {
    return null
  }

  return (
    <>
      <GridRowColumn columnStyle={{ display: 'flex' }}>
        <Text as="h2">
          <Icon icon={regular('sack-dollar')} style={{ marginRight: 8 }} />
          Taxes
        </Text>
        {isSample && (
          <Label color="orange" style={{ marginLeft: 20 }}>
            Sample Data
          </Label>
        )}
      </GridRowColumn>

      <GridRowColumn computer={8} tablet={8} mobile={16} short>
        <Text color="darkGray">
          {isScorp
            ? 'Based on your total taxable income and the IRS federal income tax rates, this is how much we’re estimating you’ll owe in taxes for the year.'
            : 'Based on your total taxable income, the IRS federal income tax rates and self-employment tax, this is how much we’re estimating you’ll owe in taxes for the year.'}
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Accordion
          title={
            <AccordionHeader
              left={
                <BreakdownTooltip
                  trigger="Projected Annual Tax Bill"
                  content={<ProjectedAnnualTaxBillTooltip />}
                />
              }
              right={
                <Text as="h3">
                  {centsToDollarsOrDashes(
                    centsToCurrency(
                      taxCalculationOutput?.data
                        .annualFederalIncomeTaxLiabilityInCents
                    ).add(
                      taxCalculationOutput?.data
                        .annualSelfEmploymentTaxLiabilityInCents || 0
                    )
                  )}
                </Text>
              }
            />
          }
          content={
            <Card backgroundColor="stone40" type="subsection">
              <Grid>
                <CalculationRow
                  left={
                    <Text as="eyebrow" color="darkGray">
                      tax
                    </Text>
                  }
                  right={
                    <Text as="eyebrow" color="darkGray" textAlign="right">
                      projected tax bill
                    </Text>
                  }
                  inCard
                />
                <CalculationRow
                  left={
                    <BreakdownTooltip
                      trigger="Projected federal income tax"
                      content={<ProjectedFederalIncomeTaxTooltip />}
                    />
                  }
                  right={
                    <Text as="h3">
                      {centsToDollarsOrDashes(
                        taxCalculationOutput?.data
                          .annualFederalIncomeTaxLiabilityInCents
                      )}
                    </Text>
                  }
                  inCard
                />
                {(!isScorp || hasSpouse) && (
                  <CalculationRow
                    left={
                      <BreakdownTooltip
                        trigger="Self-employment tax"
                        content={
                          isScorp ? (
                            <ScorpSelfEmploymentTooltip />
                          ) : (
                            <SpropSelfEmploymentTooltip />
                          )
                        }
                      />
                    }
                    right={
                      <Text as="h3">
                        {centsToDollarsOrDashes(
                          taxCalculationOutput?.data
                            .annualSelfEmploymentTaxLiabilityInCents
                        )}
                      </Text>
                    }
                    inCard
                  />
                )}
              </Grid>
            </Card>
          }
          variant="text"
        />
      </GridRowColumn>

      <CalculationRow
        left={
          <BreakdownTooltip
            trigger="Percentage Due This Quarter"
            content={<PercentageDueThisQuarterTooltip />}
          />
        }
        right={
          <Text as="h3" textAlign="right">
            {Number(quarter) * 25}%
          </Text>
        }
      />
      <GridRowColumn computer={10} tablet={10} mobile={16} short>
        <Text color="darkGray">
          <i>
            This is the percentage of your projected annual tax bill that’s due
            this quarter, including any previous payments.
          </i>
        </Text>
      </GridRowColumn>

      <GridRowColumn>
        <Divider />
      </GridRowColumn>

      <CalculationRow
        left={
          <BreakdownTooltip
            trigger={<Text as="h3">Q{quarter} YTD Taxes Due</Text>}
            content={<QuarterlyYTDTaxBillTooltip />}
          />
        }
        right={
          <Text as="h3" textAlign="right">
            {centsToDollarsOrDashes(
              taxCalculationOutput?.data.YTDGrossFederalTaxLiabilityInCents
            )}
          </Text>
        }
      />
    </>
  )
}

const MedicareStatement = () => (
  <Text color="darkGray">
    <i>
      Currently, we don’t use your W-2 medicare and social security withholdings
      to calculate the amount of taxes you’ve paid to date. We’re working on
      including these withholdings in future quarters.
    </i>
  </Text>
)

const TaxesPaidToDateSection = ({
  taxCalc,
  estimateDetails,
  isSample,
}: {
  taxCalc: UserTaxCalculation | undefined
  estimateDetails: QuarterlyTaxEstimateDetail | undefined
  isSample?: boolean
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const quarter = estimateDetails?.taxQuarter
  const year = estimateDetails?.taxYear

  const displaySampleVal = Boolean(taxCalc && isSample)

  const q1Estimate = useReselector(
    selectFederalEstimateByTaxQuarter,
    `${year}-1`
  )
  const q2Estimate = useReselector(
    selectFederalEstimateByTaxQuarter,
    `${year}-2`
  )
  const q3Estimate = useReselector(
    selectFederalEstimateByTaxQuarter,
    `${year}-3`
  )
  const quarterNum = Number(quarter)
  const taxCalculationOutput = taxCalc?.taxCalculationOutput
  const hasSpouse = doesCalcHaveSpouse(taxCalc)
  const sampleEstimateAmount = 83173

  if (
    !isTaxCalculationOutput(taxCalculationOutput) &&
    taxCalculationOutput !== undefined
  ) {
    return null
  }

  return (
    <>
      <GridRowColumn columnStyle={{ display: 'flex' }}>
        <Text as="h2">
          <Icon
            icon={regular('money-check-dollar-pen')}
            style={{ marginRight: 8 }}
          />
          Taxes Paid to date
        </Text>
        {isSample && (
          <Label color="orange" style={{ marginLeft: 20 }}>
            Sample Data
          </Label>
        )}
      </GridRowColumn>

      <GridRowColumn computer={8} tablet={8} mobile={16} short>
        <Text color="darkGray">
          We take this quarter’s tax bill and if applicable, we factor in any
          quarterly tax payments made this year and any tax withholdings that
          have been paid to date.
        </Text>
      </GridRowColumn>
      <CalculationRow
        left={<Text>Q{quarter} Taxes Due</Text>}
        right={
          <Text as="h3">
            {centsToDollarsOrDashes(
              taxCalculationOutput?.data.YTDGrossFederalTaxLiabilityInCents
            )}
          </Text>
        }
      />
      {quarterNum > 1 && (
        <GridRowColumn>
          <Accordion
            title={
              <AccordionHeader
                left={
                  <BreakdownTooltip
                    trigger="Quarterly Taxes Paid to Date"
                    content={<QuarterlyTaxesPaidToDateTooltip />}
                  />
                }
                right={
                  <Text as="h3">
                    {centsToDollarsOrDashes(
                      taxCalculationOutput?.data
                        .YTDFederalTaxPaymentsMadeInCents,
                      true
                    )}
                  </Text>
                }
              />
            }
            content={
              <Card backgroundColor="stone40" type="subsection">
                <Grid>
                  <CalculationRow
                    left={
                      <Text as="eyebrow" color="darkGray">
                        payment type
                      </Text>
                    }
                    right={
                      <Text as="eyebrow" color="darkGray" textAlign="right">
                        amount paid
                      </Text>
                    }
                    inCard
                  />
                  <CalculationRow
                    left={<Text>Q1 tax payment</Text>}
                    right={
                      <Text as="h3">
                        {centsToDollarsOrDashes(
                          displaySampleVal
                            ? sampleEstimateAmount
                            : q1Estimate?.amountPaidInCents,
                          true
                        )}
                      </Text>
                    }
                    inCard
                  />
                  {quarterNum > 2 && (
                    <CalculationRow
                      left={<Text>Q2 tax payment</Text>}
                      right={
                        <Text as="h3">
                          {centsToDollarsOrDashes(
                            displaySampleVal
                              ? sampleEstimateAmount
                              : q2Estimate?.amountPaidInCents,
                            true
                          )}
                        </Text>
                      }
                      inCard
                    />
                  )}
                  {quarterNum > 3 && (
                    <CalculationRow
                      left={<Text>Q3 tax payment</Text>}
                      right={
                        <Text as="h3">
                          {centsToDollarsOrDashes(
                            displaySampleVal
                              ? sampleEstimateAmount
                              : q3Estimate?.amountPaidInCents,
                            true
                          )}
                        </Text>
                      }
                      inCard
                    />
                  )}
                </Grid>
              </Card>
            }
            variant="text"
          />
        </GridRowColumn>
      )}

      <GridRowColumn>
        <Accordion
          title={
            <AccordionHeader
              left={
                <BreakdownTooltip
                  trigger="Tax Withholdings Paid to Date"
                  content={<TaxWithholdingsPaidToDateTooltip />}
                />
              }
              right={
                <Text as="h3">
                  {centsToDollarsOrDashes(
                    taxCalculationOutput?.data
                      .annualTotalFederalWithholdingInCents,
                    true
                  )}
                </Text>
              }
            />
          }
          content={
            <Card backgroundColor="stone40" type="subsection">
              <Grid>
                <CalculationRow
                  left={
                    <Text as="eyebrow" color="darkGray">
                      payment type
                    </Text>
                  }
                  right={
                    <Text as="eyebrow" color="darkGray" textAlign="right">
                      amount paid
                    </Text>
                  }
                  inCard
                />

                <CalculationRow
                  left={
                    <>
                      <Text>Tax withholdings</Text>
                      {!isMobile && <MedicareStatement />}
                    </>
                  }
                  right={
                    <Text as="h3">
                      {centsToDollarsOrDashes(
                        taxCalc?.federalWithholdingInCents,
                        true
                      )}
                    </Text>
                  }
                  inCard
                />
                {isMobile && (
                  <GridRowColumn short>
                    <MedicareStatement />
                  </GridRowColumn>
                )}
                {hasSpouse && (
                  <CalculationRow
                    left={<Text>Spouse’s tax withholdings</Text>}
                    right={
                      <Text as="h3">
                        {centsToDollarsOrDashes(
                          taxCalc?.spouseFederalWithholdingInCents,
                          true
                        )}
                      </Text>
                    }
                    inCard
                  />
                )}
              </Grid>
            </Card>
          }
          variant="text"
        />
      </GridRowColumn>
    </>
  )
}

const EstimateBreakdownV3 = ({
  taxCalc,
  estimateDetails,
  isSample,
}: {
  taxCalc: UserTaxCalculation | undefined
  estimateDetails: QuarterlyTaxEstimateDetail | undefined
  isSample?: boolean
}) => {
  const quarter = estimateDetails?.taxQuarter

  const isActiveEstimate =
    estimateDetails?.status === QuarterlyTaxEstimateDetailStatuses.active

  const navigate = useNavigate()

  const estimateText = taxCalc?.federalQuarterlyEstimateInCents ? (
    <Text as="h3">
      {centsToDollarsOrDashes(taxCalc.federalQuarterlyEstimateInCents)}
    </Text>
  ) : taxCalc?.federalQuarterlyEstimateInCents === 0 ? (
    <BreakdownTooltip
      trigger={centsToDollarsOrDashes(taxCalc?.federalQuarterlyEstimateInCents)}
      content={<ZeroEstimateTooltip />}
    />
  ) : (
    <Text as="h3">--</Text>
  )

  return (
    <>
      <GridRowColumn>
        <Text as="eyebrow" color="mediumGray">
          federal
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Text as="h1">Q{quarter} Federal Tax Estimate Breakdown</Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column computer={10} tablet={16} mobile={16}>
          <Grid>
            <IncomeSection
              taxCalc={taxCalc}
              estimateDetails={estimateDetails}
              isSample={isSample}
            />
            <Grid.Row />
            <TaxesSection
              taxCalc={taxCalc}
              estimateDetails={estimateDetails}
              isSample={isSample}
            />
            <Grid.Row />
            <TaxesPaidToDateSection
              taxCalc={taxCalc}
              estimateDetails={estimateDetails}
              isSample={isSample}
            />
            <GridRowColumn>
              <Divider />
            </GridRowColumn>
            <GridRowColumn>
              <Card
                backgroundColor="natural"
                type="subsection"
                unsetMobileMargin={-20}
              >
                <Grid>
                  <CalculationRow
                    left={
                      <Text as="h2">
                        <Icon
                          icon={regular('circle-quarter-stroke')}
                          style={{
                            marginRight: 10,
                            transform: `rotate(${90 * (Number(quarter) - 1)}deg)`,
                          }}
                        />
                        Q{quarter} Quarterly Tax Estimate
                      </Text>
                    }
                    right={estimateText}
                    inCard
                  />
                </Grid>
              </Card>
            </GridRowColumn>
            {isActiveEstimate && (
              <>
                <Grid.Row />
                <GridRowColumn>
                  <Alert unsetMobileMargin={-20}>
                    Any changes made to your Profit & Loss statement,
                    transactions or categorizations since this estimate was
                    released won&apos;t be applied until you select{' '}
                    <Link to="/taxes/quarterly/recalculate">
                      Make Adjustments
                    </Link>
                    .
                  </Alert>
                </GridRowColumn>
              </>
            )}
            <Grid.Row />
            <Grid.Row />
          </Grid>
        </Grid.Column>
        <Grid.Column computer={6} tablet={16} mobile={16}>
          <Grid>
            <GridRowColumn>
              <Text as="h3">Q{quarter} Federal Tax Estimate</Text>
            </GridRowColumn>
            <Grid.Row>
              <Grid.Column computer={16} tablet={8} mobile={16}>
                <PaymentCard
                  taxCalc={taxCalc}
                  estimateDetails={estimateDetails}
                  isSample={isSample}
                />
              </Grid.Column>
              {isActiveEstimate && (
                <Grid.Column computer={16} tablet={8} mobile={16}>
                  <Card
                    type="subsection"
                    backgroundColor="stone40"
                    unsetMobileMargin={-20}
                  >
                    <Grid>
                      <GridRowColumn>
                        <Text as="h3">
                          Need to make an adjustment to your tax information?
                        </Text>
                      </GridRowColumn>
                      <GridRowColumn>
                        <Text>
                          If you need to change your income or withholdings, you
                          can do so and receive a new estimate.
                        </Text>
                      </GridRowColumn>
                      <GridRowColumn>
                        <Button
                          variant="secondary"
                          fullWidth
                          onClick={() =>
                            navigate('/taxes/quarterly/recalculate')
                          }
                          disabled={isSample}
                        >
                          <Icon
                            icon={regular('redo')}
                            style={{ marginRight: 8 }}
                          />
                          Make Adjustments
                        </Button>
                      </GridRowColumn>
                    </Grid>
                  </Card>
                </Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={16} tablet={8} mobile={16}>
                <QTEFAQs />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default EstimateBreakdownV3
