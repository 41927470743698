import axios from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PercentageIncomePerState } from '../../../reducers/auth/userReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'

import { FILING_STATUSES, TAX_ENTITY_TYPES } from '../../Taxes/taxConstants'
import { UserTaxEstimate } from '../../Taxes/QuarterlyTaxEstimates/userTaxEstimates.slice'
import { keyBy, uniq } from 'lodash'

export enum UserTaxCalculationStatuses {
  notStarted = 'not_started',
  calculated = 'calculated',
  savedAndNotified = 'saved_and_notified',
}
export enum UserTaxCalculationTabNames {
  all = 'All Customers',
  singleState = 'Single State Filings',
  multiState = 'Multi State Filings',
  edgeCases = 'Edge Cases',
}

export interface CalculatedStateEstimate {
  state: string
  value?: number
}

export interface AboundResponse {
  data: {
    '1099Income': number
    effectiveTaxRate: number
    expenseDeduction: number
    federalIncomeTax: number
    federalTaxOutstanding: number
    federalTaxTotal: number
    filingState: string
    filingStatus: string
    irsPayments: number
    quarterlyPayments: number
    marginalTaxRate: number
    medicareTax: number
    mileage: number
    mileageDeduction: number
    qbiDeduction: number
    selfEmploymentTax: number
    smartTaxRate: number
    socialSecurityTax: number
    stateIncomeTax: number
    stateTaxPayments: number
    taxBalance: number
    taxWithholdings: number
    taxWithholdingsPending: number
    taxTotalOutstanding: number
    totalTax: number
    w2Income: number
    year: number
    annualFederalIncomeTaxLiabilityInCents: number | null
    annualStateTaxLiabilityInCents: number | null
  }
  request: {
    timestamp: string
    requestId: string
  }
}

export const isAboundResponse = (
  taxCalculationOutput: unknown
): taxCalculationOutput is AboundResponse => {
  //Verify the format is a valid object
  if (!taxCalculationOutput || typeof taxCalculationOutput !== 'object')
    return false

  return (
    'federalTaxOutstanding' in taxCalculationOutput &&
    'stateIncomeTax' in taxCalculationOutput
  )
}

export interface TaxCalculationOutput {
  data: {
    year: string
    stateIncomeTax: number
    selfEmploymentTax: number
    federalTaxOutstanding: number
    annualGrossIncomeInCents: number
    annualTaxableIncomeInCents: number
    annualFederalIncomeTaxLiabilityInCents: number | null
    annualStateTaxLiabilityInCents: number | null
    annualSelfEmploymentTaxLiabilityInCents: number
    annualQBIDeductionInCents: number
    annualTotalFederalWithholdingInCents: number
    annualTotalStateWithholdingInCents: number
    annualFederalEstimateInCents: number
    annualStateEstimateInCents: number
    quarterFederalEstimateInCents: number
    quarterStateEstimateInCents: number
    YTDGrossFederalTaxLiabilityInCents: number
    YTDGrossStateTaxLiabilityInCents: number
    YTDFederalTaxPaymentsMadeInCents: number
    YTDStateTaxPaymentsMadeInCents: number
    allFederalQuarterPaymentsLogged: boolean
    allStateQuarterPaymentsLogged: boolean
    annualSpouseSelfEmploymentTaxLiabilityInCents?: number
    federalStandardDeductionInCents: number
    deductibleSelfEmploymentTaxInCents: number
  }
}

export const isTaxCalculationOutput = (
  taxCalculationOutput: unknown
): taxCalculationOutput is TaxCalculationOutput => {
  //Verify the format is a valid object
  if (!taxCalculationOutput || typeof taxCalculationOutput !== 'object')
    return false

  return (
    'data' in taxCalculationOutput &&
    typeof taxCalculationOutput.data === 'object' &&
    taxCalculationOutput.data !== null &&
    'quarterFederalEstimateInCents' in taxCalculationOutput.data &&
    'quarterStateEstimateInCents' in taxCalculationOutput.data
  )
}

export interface UserTaxCalculation {
  id: number
  status: UserTaxCalculationStatuses
  calendarYear: string
  calendarQuarter: string
  createdAt: string
  updatedAt: string
  deletedAt?: string | null
  userId: number
  userUuid: string
  firstName: string
  lastName: string
  stateQuarterlyEstimates: CalculatedStateEstimate[]
  calculatedByUserId?: number | null
  //we need to keep AboundResponse because the format of legacy data is different
  taxCalculationOutput?: AboundResponse | TaxCalculationOutput
  calculatedAt?: string
  filingStates: string[]
  filingStatus: FILING_STATUSES
  taxEntityType?: TAX_ENTITY_TYPES
  homeState: string
  percentageIncomePerState?: PercentageIncomePerState[]
  taxProfileInputsUpdatedAt: string
  plInputsUpdatedAt?: string
  notifiedAt?: string
  notes?: string | null
  federalQuarterlyEstimateInCents: number
  wIncomeInCents: number | null
  spouseWIncomeInCents: number | null
  totalWIncomeInCents: number
  federalWithholdingInCents: number
  stateWithholdingInCents: number
  spouseFederalWithholdingInCents: number
  spouseStateWithholdingInCents: number
  totalFederalWithholdingInCents: number
  totalStateWithholdingInCents: number
  periodIncomeInCents: number
  periodExpensesInCents: number
  annualizedIncomeInCents: number
  annualizedExpensesInCents: number
  annualizedNetIncomeInCents: number
  paidEstimatesAtQ4Calculation?: UserTaxEstimate[] | null
  relocatedPreviousStateAt: string | null
  scorpPracticeWIncomeInCents: number | null
  otherIndividualIncomeInCents: number | null
  spouseIndividualIncomeInCents: number | null
  annualizedTotalWIncomeInCents: number | null
  isSingleState: boolean | null
  taxProfileLastReviewedAt: string | null
  booksClosedForPreviousMonths: boolean | null
  hasPaidAllTaxEstimates: boolean | null
  unseenEstimate: boolean | null
  needsRecalculation: boolean | null
  annualizedSpouseWIncomeInCents: number | null
  annualizedScorpPracticeWIncomeInCents: number | null
  annualizedWIncomeInCents: number | null
  estimatedAnnualW2IncomeInCents: number | null
  estimatedAnnualSpouseW2IncomeInCents: number | null
  estimatedAnnualScorpPracticeW2IncomeInCents: number | null
}

export interface TaxCalculationViewModel {
  [calendarYear: string]: {
    [calendarQuarter: string]: {
      [tabName: string]: UserTaxCalculation[]
    }
  }
}

interface ReleaseUserTaxCalculationsModel {
  ids: number[]
}

export interface UserTaxCalculationsState {
  byId: { [key: string]: UserTaxCalculation }
  allIds: string[]
}

const initialState: UserTaxCalculationsState = {
  byId: {},
  allIds: [],
}

const userTaxCalculationsSlice = createSlice({
  name: 'userTaxCalculations',
  initialState,
  reducers: {
    receiveAllUserTaxCalculations: (
      state,
      action: PayloadAction<{ [key: string]: UserTaxCalculation }>
    ) => {
      state.byId = {
        ...state.byId,
        ...action.payload,
      }

      state.allIds = uniq([
        ...state.allIds,
        ...Object.values(action.payload).map((c) => c.id.toString()),
      ])
    },
    receiveSingleUserTaxCalculation: (
      state,
      action: PayloadAction<UserTaxCalculation>
    ) => {
      state.byId[action.payload.id] = action.payload
      state.allIds = uniq([...state.allIds, action.payload.id.toString()])
    },
  },
})

export default userTaxCalculationsSlice.reducer

export const {
  receiveAllUserTaxCalculations,
  receiveSingleUserTaxCalculation,
} = userTaxCalculationsSlice.actions

export const FETCH_ALL_USER_TAX_CALCULATIONS_KEY =
  'FETCH_ALL_USER_TAX_CALCULATIONS_KEY'
export const UPDATE_SINGLE_USER_TAX_CALCULATIONS_KEY =
  'UPDATE_SINGLE_USER_TAX_CALCULATIONS_KEY'
export const RELEASE_USER_TAX_CALCULATIONS_KEY =
  'RELEASE_USER_TAX_CALCULATIONS_KEY'

export const fetchUserTaxCalculationsIfNeeded = (alwaysFetch?: boolean) =>
  fetchIfNeededWrapper({
    alwaysFetch,
    fetchKey: FETCH_ALL_USER_TAX_CALCULATIONS_KEY,
    defaultErrorMessage: 'Error fetching all user tax calculations',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserTaxCalculation[]>(
        '/finances/api/v1/user_tax_calculations'
      )

      dispatch(receiveAllUserTaxCalculations(keyBy(json.data, 'id')))

      return json.data
    },
  })

export const UPDATE_USER_TAX_CALCULATIONS_KEY =
  'UPDATE_USER_TAX_CALCULATIONS_KEY'
export const updateUserTaxCalculation = (
  id: number,
  data: Partial<UserTaxCalculation>
) =>
  fetchWrapper({
    fetchKey: UPDATE_USER_TAX_CALCULATIONS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.patch<UserTaxCalculation>(
        `/finances/api/v1/user_tax_calculations/${id}`,
        data
      )

      dispatch(receiveSingleUserTaxCalculation(json.data))

      return json.data
    },
  })

export const RECALCULATE_USER_TAX_CALCULATIONS_KEY =
  'RECALCULATE_USER_TAX_CALCULATIONS_KEY'
export const recalculateUserTaxCalculation = () =>
  fetchWrapper({
    fetchKey: RECALCULATE_USER_TAX_CALCULATIONS_KEY,
    fetchFunction: async () => {
      await axios.post<undefined>(
        '/finances/api/v1/user_tax_calculations/recalculateCurrentQuarter'
      )

      return true
    },
  })

export const adminFetchAllUserTaxCalculationsIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALL_USER_TAX_CALCULATIONS_KEY,
    defaultErrorMessage: 'Error fetching all admin user tax calculations',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserTaxCalculation[]>(
        '/finances/api/v1/admin/user_tax_calculations'
      )

      dispatch(receiveAllUserTaxCalculations(keyBy(json.data, 'id')))

      return json.data
    },
  })

export const updateSingleUserTaxCalculation = (
  id: number,
  data: Partial<UserTaxCalculation>
) =>
  fetchWrapper({
    fetchKey: UPDATE_SINGLE_USER_TAX_CALCULATIONS_KEY + id,
    defaultErrorMessage: 'Error updating the tax calculation',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<UserTaxCalculation>(
        `/finances/api/v1/admin/user_tax_calculations/${id}`,
        data
      )

      dispatch(receiveSingleUserTaxCalculation(json.data))

      return json.data
    },
  })

export const releaseUserTaxCalculations = (
  model: ReleaseUserTaxCalculationsModel
) =>
  fetchWrapper({
    // Reusing key so our component doesn't need to have multiple vars for monitoring error & loading
    fetchKey: RELEASE_USER_TAX_CALCULATIONS_KEY,
    defaultErrorMessage: 'Error releasing user tax calculations',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<UserTaxCalculation[]>(
        '/finances/api/v1/admin/user_tax_calculations/release',
        model
      )

      dispatch(receiveAllUserTaxCalculations(keyBy(json.data, 'id')))

      return json.data
    },
  })
