import axios from 'axios'
import { BrowserRouter } from 'react-router-dom'
import Redirect from 'react-https-redirect'

import { GlobalPopupStyle } from './components/BaseComponents/Popup'
import Router from './components/Routes/Router'

import './styles/fonts.scss'
import { AnalyticsProvider } from './features/Amplitude'
import { useSurvicate } from './utils/sharedHooks'
import { OpenFeatureProvider } from '@openfeature/react-sdk'
import { UsersnapProvider } from './components/shared/UsersnapSurvey'
import { EvervaultProvider } from '@evervault/react'

axios.defaults.baseURL = process.env.VITE_LOCAL_API_ENDPOINT
axios.defaults.withCredentials = true

const App = () => {
  useSurvicate()
  return (
    <div className="App" id="App">
      <EvervaultProvider
        teamId={process.env.VITE_EVERVAULT_TEAM_ID ?? ''}
        appId={process.env.VITE_EVERVAULT_APP_ID ?? ''}
      >
        <OpenFeatureProvider domain="openFeatureClient">
          <AnalyticsProvider>
            <UsersnapProvider>
              <GlobalPopupStyle />
              <Redirect disabled={process.env.NODE_ENV === 'development'}>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </Redirect>
            </UsersnapProvider>
          </AnalyticsProvider>
        </OpenFeatureProvider>
      </EvervaultProvider>
    </div>
  )
}

export default App
