import { Grid } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  Icon,
  Link,
} from '../../../../../components/BaseComponents'
import { splitTaxYearQuarter } from '../../helpers'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectTaxCalculationByTaxQuarter } from '../../../../../selectors/userTaxCalculation.selectors'
import { selectQuarterlyTaxEstimateDetailsByYearAndQuarter } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import EstimateBreakdownV3 from './EstimateBreakdownV3'

const EstimateBreakdownV3Wrapper = () => {
  const { taxQuarter } = useParams()
  const { quarter, year } = splitTaxYearQuarter(taxQuarter)

  const taxCalc = useReselector(selectTaxCalculationByTaxQuarter, taxQuarter)
  const estimateDetails = useReselector(
    selectQuarterlyTaxEstimateDetailsByYearAndQuarter,
    year,
    quarter
  )

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Grid style={isMobile ? { margin: '0 5px' } : undefined}>
      <GridRowColumn>
        <Link to="/taxes/quarterly">
          <Icon icon={regular('arrow-left')} style={{ marginRight: 8 }} />
          Back to Quarterly Taxes
        </Link>
      </GridRowColumn>
      <EstimateBreakdownV3
        taxCalc={taxCalc}
        estimateDetails={estimateDetails}
      />
    </Grid>
  )
}

export default EstimateBreakdownV3Wrapper
