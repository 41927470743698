import React, { useEffect, createElement } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { updateUser } from '../../actions/userActions'
import { getCurrentUser } from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import SetupLandingCard from './SetupLanding/SetupLandingCard'
import { ConnectStage } from './ConnectStage'
import { AccountsStage } from './AccountsStage'
import { UploadStatementsStage } from './UploadStatementsStage'
import IncompleteAccount from './ConnectStage/IncompleteAccount'
import { OnboardingMobileRedirect } from './OnboardingMobileRedirect'
import { useAppDispatch } from '../../utils/typeHelpers'

const NON_REDIRECT_SCREENS = [
  /onboarding-v2\?stage=connect&step=baa/, // baa
  /onboarding-v2\?stage=connect&step=prescreen/, // prescreen
  /\/signup\/.*/, // all signup screens
]

type ComponentMapType = {
  [key: string]: React.ComponentType
}

const onboardingStageComponents: ComponentMapType = {
  landing: SetupLandingCard,
  connect: ConnectStage,
  account: AccountsStage,
  statements: UploadStatementsStage,
  incomplete: IncompleteAccount,
}

export const OnboardingV2 = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const stage = searchParams.get('stage') || ''
  const currentUser = useReselector(getCurrentUser)
  const component = onboardingStageComponents[stage]
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  useEffect(() => {
    const urlPath = location.pathname + location.search

    if (
      urlPath === '/onboarding-v2' &&
      currentUser?.lastOnboardingScreen &&
      !NON_REDIRECT_SCREENS.some((screen) =>
        screen.test(currentUser?.lastOnboardingScreen ?? '')
      )
    ) {
      navigate(currentUser.lastOnboardingScreen)
      return
    }
    if (location.search && currentUser?.lastOnboardingScreen !== urlPath) {
      const update = async () => {
        await updateUser(currentUser?.id, { lastOnboardingScreen: urlPath })(
          dispatch
        )
      }

      update()
    }
  }, [dispatch, location, currentUser, navigate])

  if (isMobile) {
    return <OnboardingMobileRedirect />
  }

  if (!component) {
    return <SetupLandingCard />
  }

  return createElement(component, {})
}
