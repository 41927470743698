import { Grid, Icon } from 'semantic-ui-react'

import {
  ActiveQuarterTaxSummary,
  LastQuarterTaxSummary,
} from './SingleQuarterTaxSummary'
import { EstimateStatus, statusForMultipleEstimates } from '../service'
import {
  selectActiveQTEDetailsAsDates,
  selectActiveQuarterlyTaxEstimateDetails,
  selectIsSameOrAfterNewUserCutOffAt,
} from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import QTETimeline from './QTETimeline'
import QTEFAQs from './QTEFAQs'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  getGroupedActiveTaxEstimatesWithinActiveQuarter,
  selectUserCreatedPastQteCutoff,
} from '../userTaxEstimates.selector'
import QTEChecklist from './QTEChecklist'
import { useMemo, useState } from 'react'
import DeadlineLabel from './DeadlineLabel'
import { Button, Link, Text } from '../../../../components/BaseComponents'
import Tooltip from './Tooltip'
import JoinedTooLateCard from './JoinedTooLateCard'
import moment from 'moment'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { Colors } from '../../../../styles/theme'
import { UserTaxCalculationStatuses } from '../../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import BreakdownBanner from './BreakdownBanner'
import {
  selectCalculationHomeState,
  selectUserTaxCalculationWithinActiveQuarter,
} from '../../../../selectors/userTaxCalculation.selectors'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'
import UpdateQuarterlyTaxEstimateModal from './UpdateQuarterlyTaxEstimateModal'
import UserJoinedTooLateV2 from './CalculateSafeHarborFlow/UserJoinedTooLateV2'
import { isFreeTrialPromoCode } from '../../../../selectors/user.selectors'
import { BannerCard } from '../../../../components/BaseComponents/BannerCard'
import { useNavigate } from 'react-router-dom'

const UserJoinedTooLate = ({
  activeQuarterText,
  groupSummaryStatus,
}: {
  activeQuarterText: string
  groupSummaryStatus: EstimateStatus
}) => {
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )
  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={15}>
          <Text as="h2">{activeQuarterText} Estimates</Text>
        </Grid.Column>
        {groupSummaryStatus === EstimateStatus.awaitingEstimate && (
          <Grid.Column width={1} floated="right">
            <Tooltip
              popup={{
                title: 'Quarterly Tax Estimates',
                body: 'Heard uses your tax filing information to provide estimates of how much you owe in taxes each quarter. We give you the calculation: all you have to do is pay!',
                link: 'https://support.joinheard.com/hc/en-us/articles/4416208037655-What-are-quarterly-tax-estimates-',
              }}
              labelComponent={<Icon name="question circle outline" />}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row style={{ padding: '0' }}>
        <Grid.Column>
          <DeadlineLabel
            text="Estimated tax payment due:"
            prominentText={moment
              .utc(activeQuarterDetails?.irsPaymentDueAt)
              .format(DATE_FORMATS.DISPLAY_LONG)}
            style={{ color: Colors.black }}
          />
        </Grid.Column>
      </Grid.Row>
      <JoinedTooLateCard
        cutOffAt={activeQuarterDetails?.newUserCutOffAt}
        activeTaxQuarter={activeQuarterDetails?.taxQuarter}
      />
    </Grid>
  )
}

const ActiveQuarterTab = () => {
  const navigate = useNavigate()
  const activeQuarterDetails = useReselector(selectActiveQTEDetailsAsDates)
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  const activeQuarterText = `Q${activeQuarterDetails?.taxQuarter} ${activeQuarterDetails?.taxYear}`
  const [estimateModalOpen, setEstimateModalOpen] = useState(false)

  const userJoinedTooLate = useReselector(selectUserCreatedPastQteCutoff)

  const calculation = useReselector(selectUserTaxCalculationWithinActiveQuarter)
  const homeState = useReselector(selectCalculationHomeState, calculation?.id)
  const { federalTaxEstimate, homeStateTaxEstimate, stateTaxEstimates } =
    useReselector(getGroupedActiveTaxEstimatesWithinActiveQuarter, homeState)

  const groupSummaryStatus = statusForMultipleEstimates([
    ...(stateTaxEstimates || []),
    federalTaxEstimate,
    homeStateTaxEstimate,
  ])

  const enableNewQteLateJoinerFlow = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableNewQteLateJoinerFlow,
    false
  )

  const estimatesReleased =
    calculation?.status === UserTaxCalculationStatuses.savedAndNotified
  const afterNewUserCutOffAt = useReselector(selectIsSameOrAfterNewUserCutOffAt)

  const mainContent = useMemo(() => {
    if (hasFreeTrialPromoCode) {
      return (
        <>
          <BannerCard
            headerText="Preview of the Quarterly Tax Estimate Experience"
            imageUrl="https://heard-images.s3.amazonaws.com/assets/coins-stack.svg"
            bodyContent={
              <>
                <Text as="bodyMd">
                  Every quarter, we calculate your estimated federal and state
                  payments and even remind you when to pay so you never miss a
                  deadline.
                </Text>
                <br />
                <Text as="bodyMd">
                  We have shown an example of this process in the section below
                  — note that you will not be able to interact with it.
                </Text>
                <br />
                <Link
                  newPage
                  href="https://support.joinheard.com/hc/en-us/articles/4416208116247"
                  as="secondaryLink"
                >
                  Learn how Heard prepares your Quarterly Tax Estimates
                </Link>
                <br />
                <br />
                <Button
                  variant="primary"
                  onClick={() => navigate('/accounts/choose-plan')}
                >
                  Need help with Taxes? Sign up with Heard
                </Button>
              </>
            }
          />
          <div style={{ opacity: '0.5', pointerEvents: 'none' }}>
            <QTEChecklist />
          </div>
        </>
      )
    }
    if (userJoinedTooLate) {
      if (enableNewQteLateJoinerFlow) {
        return (
          <UserJoinedTooLateV2
            activeQuarterText={activeQuarterText}
            federalTaxEstimate={federalTaxEstimate}
          />
        )
      } else {
        return (
          <UserJoinedTooLate
            activeQuarterText={activeQuarterText}
            groupSummaryStatus={groupSummaryStatus}
          />
        )
      }
    }
    if (!afterNewUserCutOffAt) {
      return <LastQuarterTaxSummary />
    }
    if (!estimatesReleased || calculation?.needsRecalculation) {
      return <QTEChecklist />
    }
    return <ActiveQuarterTaxSummary quarterText={activeQuarterText} />
  }, [
    userJoinedTooLate,
    afterNewUserCutOffAt,
    estimatesReleased,
    calculation?.needsRecalculation,
    activeQuarterText,
    groupSummaryStatus,
    enableNewQteLateJoinerFlow,
    federalTaxEstimate,
    hasFreeTrialPromoCode,
    navigate,
  ])

  return (
    <Grid>
      <BreakdownBanner />
      <Grid.Row columns={2}>
        <Grid.Column width={11}>{mainContent}</Grid.Column>
        <Grid.Column width={5}>
          {!userJoinedTooLate && <QTETimeline />}
          <QTEFAQs />
        </Grid.Column>
      </Grid.Row>
      {federalTaxEstimate && (
        <UpdateQuarterlyTaxEstimateModal
          open={estimateModalOpen}
          close={() => setEstimateModalOpen(false)}
          estimate={federalTaxEstimate}
        />
      )}
    </Grid>
  )
}
export default ActiveQuarterTab
