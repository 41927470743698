import { CSSProperties, useMemo, useState, ReactNode } from 'react'
import { Divider, Grid, Image, List } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Card, Icon, Pill, Popup, Text } from '../../BaseComponents'
import PlaidLink from '../../shared/plaid/PlaidLink'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { PlaidItem } from '../../../reducers/finances/plaidItemReducer'
import { institutionLogoMap } from '../../../constants/logoConstants'
import HiddenAccountsAccordion from './HiddenAccountsAccordion'
import HideAccountModal from './HideAccountModal'
import {
  FinancialAccount,
  TransactionSyncState,
} from '../../../reducers/finances/financialAccountsReducer'
import { Colors } from '../../../styles/theme'
import {
  checkIsInRolledOutInstitutions,
  getPlaidButtonLabel,
  hasAutoStatementUploadAccount,
  hasHealthyManualStatementUploadAccount,
} from './helpers'
import { AccountBanner, AwaitingConfirmationButton } from './InstitutionList'
import {
  selectHiddenAccounts,
  selectVisibleAccounts,
} from '../../../selectors/financeSelectors'
import { useReselector } from '../../../utils/sharedHooks'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { selectRolledOutInstitutionIds } from '../../../selectors/user.selectors'
import { deletePlaidItem } from '../../../actions/financialAccountActions'
import { inOperator, useAppDispatch } from '../../../utils/typeHelpers'
import { PlaidStatementRelogin } from './PlaidStatementAccountRelogin'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'
import { useBooleanFlagValue } from '@openfeature/react-sdk'

interface InstitutionLogoProps {
  institution: PlaidItem
  className: string
}

export const InstitutionLogo = ({
  institution,
  className,
}: InstitutionLogoProps) => {
  const src = useMemo(() => {
    const { logo, institutionName } = institution
    if (logo) return `data:image/png;base64, ${logo}`
    return inOperator(institutionName, institutionLogoMap)
      ? institutionLogoMap[institutionName]
      : null
  }, [institution])

  if (src) {
    return <Image src={src} alt="" width={48} />
  }

  return (
    <div className={className}>
      <Icon icon={regular('bank')} size="lg" color="white" />
    </div>
  )
}

const PillTooltip = ({
  title,
  body,
  children,
}: {
  title: string
  body: ReactNode
  children: ReactNode
}) => (
  <Popup
    hoverable
    wide
    trigger={children}
    content={
      <Grid>
        <Grid.Row style={{ paddingBottom: '0' }}>
          <Grid.Column>
            <Text style={{ paddingTop: '10px' }} as="h3">
              {title}
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{body}</Grid.Column>
        </Grid.Row>
      </Grid>
    }
  />
)

const StatusPill = ({
  active,
  style = {},
}: {
  active: boolean
  style: CSSProperties
}) => (
  <Pill color={active ? 'neutral' : 'red'} variant="solid" style={style}>
    {active ? 'Active' : 'Disconnected'}
  </Pill>
)

const StatementPill = ({
  accounts,
  style = {},
}: {
  accounts: FinancialAccount[]
  style?: CSSProperties
}) => {
  const isAutomated = useMemo(
    () =>
      hasAutoStatementUploadAccount(accounts) ||
      hasHealthyManualStatementUploadAccount(accounts),
    [accounts]
  )

  return isAutomated ? (
    <PillTooltip
      title="Automated"
      body={
        <>
          <Text>
            This means we can automatically reconcile and complete bookkeeping
            for this account. You don&apos;t need to upload statements every
            month.
          </Text>
          <Text style={{ marginTop: 10 }}>
            This includes accounts that were set up with automatic statement
            uploads and limited bank access.
          </Text>
        </>
      }
    >
      <Pill color={'green'} variant="light" style={style}>
        Automated
      </Pill>
    </PillTooltip>
  ) : (
    <PillTooltip
      title="Manual Upload"
      body={
        <Text>
          This means you need to manually upload bank statements every month for
          this account. This is required to complete bookkeeping.
        </Text>
      }
    >
      <Pill color={'magenta'} variant="light" style={style}>
        Manual Upload
      </Pill>
    </PillTooltip>
  )
}

const PlaidStatementPill = ({
  institution,
  style = {},
}: {
  institution: PlaidItem
  style?: CSSProperties
}) => {
  const { accounts } = institution

  if (
    accounts.length === 0 ||
    accounts[0].statementPermissions !== 'plaid_statement'
  ) {
    return null
  }

  return (
    <PillTooltip
      title="Auto statement uploads"
      body={
        <Text>
          Your bank statements for all accounts at this institution will be
          automatically uploaded every month.
        </Text>
      }
    >
      <Pill color={'teal'} variant="light" style={style}>
        Auto Statement Uploads
      </Pill>
    </PillTooltip>
  )
}

const LBAPill = ({
  institution,
  style = {},
}: {
  institution: PlaidItem
  style?: CSSProperties
}) => {
  const { accounts } = institution

  if (
    accounts.length === 0 ||
    accounts[0].statementPermissions === 'plaid_statement' ||
    !accounts[0].bankAccessEnabledAt
  ) {
    return null
  }

  return (
    <PillTooltip
      title="Limited bank access"
      body={
        <>
          <Text>
            Heard has view-only access to your accounts at this institution.
          </Text>
          <Text style={{ marginTop: 8 }}>
            Your bank statements for all accounts at this institution will be
            automatically uploaded every month.
          </Text>
        </>
      }
    >
      <Pill color={'purple'} variant="light" style={style}>
        Limited Bank Access
      </Pill>
    </PillTooltip>
  )
}

export const InstitutionHeader = ({
  institution,
  isInRolledOutInstitutions,
  isOnboarding = false,
  handleRemoveConnection,
}: {
  institution: PlaidItem
  isInRolledOutInstitutions: boolean
  isOnboarding: boolean
  handleRemoveConnection?: () => void
}) => {
  const enableLowerFrictionStatements = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )
  const { disconnectedOn, institutionName } = institution

  return (
    <div className="institutionHeader">
      <div>
        <div className="institutionLabel">
          <InstitutionLogo
            className="institutionLogo"
            institution={institution}
          />
          <div>
            <div className="nameLabel">
              <Text as="h3">{institutionName}</Text>
              <StatusPill active={!disconnectedOn} style={{ marginLeft: 8 }} />
              {enableLowerFrictionStatements ? (
                !isOnboarding && (
                  <StatementPill
                    accounts={institution.accounts}
                    style={{ marginLeft: 8 }}
                  />
                )
              ) : (
                <>
                  <PlaidStatementPill
                    institution={institution}
                    style={{ marginLeft: 8 }}
                  />
                  <LBAPill
                    institution={institution}
                    style={{ marginLeft: 8 }}
                  />
                </>
              )}
            </div>
            {disconnectedOn && (
              <Text as="bodySm">
                We have lost connection to this institution. Please reconnect it
                now.
              </Text>
            )}
          </div>
        </div>
      </div>
      {isOnboarding ? (
        <Button variant="link" onClick={handleRemoveConnection}>
          Remove Connection
        </Button>
      ) : (
        <PlaidLink
          buttonVariant={disconnectedOn ? undefined : 'link'}
          reconnectButtonText={getPlaidButtonLabel(
            institution,
            disconnectedOn,
            isInRolledOutInstitutions
          )}
          account={institution.accounts[0]}
          disconnected={Boolean(disconnectedOn)}
        />
      )}
    </div>
  )
}

export const MobileInstitutionHeader = ({
  institution,
  isInRolledOutInstitutions,
  isOnboarding = false,
  handleRemoveConnection,
}: {
  institution: PlaidItem
  isInRolledOutInstitutions: boolean
  isOnboarding?: boolean
  handleRemoveConnection?: () => void
}) => {
  const enableLowerFrictionStatements = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )
  const { disconnectedOn, institutionName } = institution

  return (
    <div>
      <div className="institutionLabel mobile">
        <InstitutionLogo
          className="institutionLogo mobile"
          institution={institution}
        />
        <div>
          <StatusPill active={!disconnectedOn} style={{ marginTop: 6 }} />
          {enableLowerFrictionStatements ? (
            <StatementPill
              accounts={institution.accounts}
              style={{ marginTop: 6 }}
            />
          ) : (
            <>
              <PlaidStatementPill
                institution={institution}
                style={{ marginTop: 6 }}
              />
              <LBAPill institution={institution} style={{ marginTop: 6 }} />
            </>
          )}
          <Text as="h3" style={{ marginTop: 6, marginLeft: 0 }}>
            {institutionName}
          </Text>
        </div>
      </div>
      {disconnectedOn && (
        <Text as="bodySm" style={{ marginBottom: 6 }}>
          We have lost connection to this institution. Please reconnect it now.
        </Text>
      )}
      {isOnboarding ? (
        <Button variant="link" onClick={handleRemoveConnection}>
          Remove Connection
        </Button>
      ) : (
        <PlaidLink
          buttonVariant={disconnectedOn ? undefined : 'link'}
          reconnectButtonText={getPlaidButtonLabel(
            institution,
            disconnectedOn,
            isInRolledOutInstitutions
          )}
          account={institution.accounts[0]}
          disconnected={Boolean(disconnectedOn)}
        />
      )}
    </div>
  )
}

interface InstitutionCardProps {
  institution: PlaidItem
  setAccountBanner: (accountBanner: AccountBanner | null) => void
  setAccountToEdit: (account: FinancialAccount | null) => void
  accountToEdit: FinancialAccount | null
  setInitialStateModalOpen: (account: boolean) => void
  isOnboarding?: boolean
}

export const InstitutionCard = ({
  institution,
  setAccountBanner,
  setAccountToEdit,
  accountToEdit,
  setInitialStateModalOpen,
  isOnboarding = false,
}: InstitutionCardProps) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const [hideModalOpen, setHideModalOpen] = useState(false)
  const { disconnectedOn } = institution
  const track = useAnalyticsTrack()
  const rolledOutInstitutionIds = useReselector(selectRolledOutInstitutionIds)
  const isInRolledOutInstitutions = checkIsInRolledOutInstitutions(
    rolledOutInstitutionIds,
    institution.institutionId
  )
  const dispatch = useAppDispatch()

  const hiddenAccounts = useReselector(
    selectHiddenAccounts,
    institution.id,
    isInRolledOutInstitutions
  )
  const visibleAccounts = useReselector(
    selectVisibleAccounts,
    institution.id,
    isInRolledOutInstitutions
  )

  const handleRemoveConnection = async () => {
    await dispatch(deletePlaidItem(institution.id))
  }

  return (
    <Card
      type="subsection"
      className="institutionCard"
      backgroundColor={disconnectedOn ? 'lightRed' : 'stone40'}
    >
      {isMobile ? (
        <MobileInstitutionHeader
          institution={institution}
          isInRolledOutInstitutions={isInRolledOutInstitutions}
          isOnboarding={isOnboarding}
          handleRemoveConnection={handleRemoveConnection}
        />
      ) : (
        <InstitutionHeader
          institution={institution}
          isInRolledOutInstitutions={isInRolledOutInstitutions}
          isOnboarding={isOnboarding}
          handleRemoveConnection={handleRemoveConnection}
        />
      )}
      <Divider />
      {visibleAccounts.length > 0 && (
        <List as="ul">
          {visibleAccounts.map((account) => (
            <List.Item
              as="li"
              key={account.id}
              style={{
                ...(disconnectedOn
                  ? { color: Colors.darkGray }
                  : isInRolledOutInstitutions &&
                      account.transactionSyncState ===
                        TransactionSyncState.PENDING
                    ? { color: Colors.mediumGray }
                    : {}),
              }}
            >
              {account.name} {account.mask}
              {isInRolledOutInstitutions &&
                account.transactionSyncState ===
                  TransactionSyncState.ENABLED && (
                  <Button
                    variant={'secondaryLink'}
                    disabled={Boolean(disconnectedOn)}
                    inline
                    style={{ marginLeft: 4 }}
                    onClick={() => {
                      setAccountToEdit(account)
                      setHideModalOpen(true)
                      track('clicked review bank account button')
                    }}
                    size="small"
                  >
                    Hide
                  </Button>
                )}
              {isInRolledOutInstitutions &&
                account.transactionSyncState ===
                  TransactionSyncState.PENDING && (
                  <AwaitingConfirmationButton
                    account={account}
                    disconnectedOn={disconnectedOn}
                    setAccountToEdit={setAccountToEdit}
                    setInitialStateModalOpen={setInitialStateModalOpen}
                  />
                )}
            </List.Item>
          ))}
        </List>
      )}
      {!isOnboarding && (
        <PlaidStatementRelogin
          institution={institution}
          accounts={visibleAccounts}
          disconnected={Boolean(disconnectedOn)}
        />
      )}
      {accountToEdit && (
        <HideAccountModal
          open={hideModalOpen}
          account={accountToEdit}
          onClose={() => {
            setHideModalOpen(false)
            setAccountToEdit(null)
          }}
          setAccountBanner={setAccountBanner}
        />
      )}
      {isInRolledOutInstitutions && hiddenAccounts.length > 0 && (
        <HiddenAccountsAccordion
          hiddenAccounts={hiddenAccounts}
          institutionName={institution.institutionName}
          disconnectedOn={disconnectedOn}
          setAccountBanner={setAccountBanner}
          initialOpen={visibleAccounts.length === 0}
        />
      )}
    </Card>
  )
}
