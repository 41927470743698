import { useMemo } from 'react'
import { Loader, Grid, Progress, Divider } from 'semantic-ui-react'
import { orderBy } from 'lodash'

import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import { getTransactionCategorySelector } from '../../../features/Reports/reports.selectors'
import { Card, Text } from '../../BaseComponents'
import { Expense } from '../../../actions/reportActions'
import { useReselector } from '../../../utils/sharedHooks'
import { Colors } from '../../../styles/theme'

const ExpensesLineItem = ({ expense }: { expense: Expense }) => {
  const selectedCategory = useReselector(
    getTransactionCategorySelector,
    expense.transactionCategoryId
  )

  return (
    <Grid.Row
      className="expensesLineItem"
      stretched
      style={{ padding: '10px!important' }}
    >
      <Grid.Column desktop={7} mobile={6}>
        {selectedCategory?.name}
      </Grid.Column>
      <Grid.Column desktop={4} mobile={6}>
        <CurrencyFormatLabel value={expense.sum} />
      </Grid.Column>
      <Grid.Column desktop={5} mobile={4}>
        <Progress
          percent={Math.trunc(Number(expense.percentage) * 100)}
          progress
        />
      </Grid.Column>
    </Grid.Row>
  )
}

const BookkeepingReportTopExpensesCard = ({
  loading,
  expensesObject,
}: {
  loading: boolean
  expensesObject: { expenses: Expense[]; sum: number }
}) => {
  const expenses = useMemo(
    () => orderBy(expensesObject.expenses, 'percentage', 'desc'),
    [expensesObject.expenses]
  )

  return (
    <Card
      fullWidth
      className="bookkeepingReportTopExpensesCard shadowed"
      type="subsection"
      style={{
        minHeight: '300px',
        backgroundColor: Colors.stone40,
      }}
    >
      <Text as="h2" style={{ marginBottom: 4 }}>
        Top Expenses This Month
      </Text>
      <Divider hidden />

      {!loading && (
        <Grid doubling>
          {expenses.length > 0 &&
            expenses
              ?.slice(0, 5)
              .map((expense) => (
                <ExpensesLineItem
                  key={expense.transactionCategoryId}
                  expense={expense}
                />
              ))}
          {expenses.length === 0 && (
            <Text as="bodyMd">No Expenses for the month!</Text>
          )}
        </Grid>
      )}

      {loading && <Loader inline active />}
    </Card>
  )
}

export default BookkeepingReportTopExpensesCard
