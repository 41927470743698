import { Grid } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useFetchResponse, useReselector } from '../../../utils/sharedHooks'
import { getMissingStatements } from '../userDocuments.slice'
import { Button, Icon, Link, Text } from '../../../components/BaseComponents'
import ViewMissingStatementsAccordion from '../../../components/shared/ViewMissingStatementsAccordion'
import * as React from 'react'
import { Colors } from '../../../styles/theme'
import { isFreeTrialPromoCode } from '../../../selectors/user.selectors'

const MissingStatements = ({
  includeUploadButton = true,
  accordionOnly = false,
  style = {},
  whenNone = null,
}: {
  includeUploadButton: boolean
  accordionOnly?: boolean
  style?: React.CSSProperties
  whenNone?: React.ReactNode
}) => {
  const navigate = useNavigate()
  const missingStatements = useFetchResponse(getMissingStatements)
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)
  const computer = React.useMemo(
    () => (includeUploadButton ? 11 : 16),
    [includeUploadButton]
  )

  if (!missingStatements || missingStatements.length === 0) {
    return whenNone
  }

  if (hasFreeTrialPromoCode) {
    return null
  }

  if (accordionOnly) {
    return (
      <ViewMissingStatementsAccordion
        neededStatements={missingStatements}
        title="View missing statements"
      />
    )
  }

  return (
    <div
      style={{
        position: 'relative',
        alignItems: 'vertical',
        display: 'flex',
        border: `1px solid ${Colors.orange} !important`,
        borderRadius: '4px',
        backgroundColor: Colors.lightOrange,
        color: Colors.orange,
        padding: 16,
        wordBreak: 'break-word',
        ...style,
      }}
    >
      <Icon
        icon={solid('triangle-exclamation')}
        style={{
          color: Colors.orange,
          margin: '25px 18px 0 2px',
        }}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column computer={computer} tablet={8} mobile={8}>
            <Text as="h3" style={{ marginBottom: 4 }}>
              Bank statement(s) needed
            </Text>
            <Text style={{ marginBottom: 4 }}>
              Please upload the bank statements below so we can complete your
              bookkeeping.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/23569956149399-Bank-Statements-at-Heard"
                as="secondaryLink"
                newPage
              >
                Why?
              </Link>
            </Text>
            <ViewMissingStatementsAccordion
              neededStatements={missingStatements}
              title="View missing statements"
            />
          </Grid.Column>
          {includeUploadButton && (
            <Grid.Column
              computer={5}
              tablet={8}
              mobile={8}
              textAlign="right"
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'vertical',
              }}
            >
              <Button
                onClick={() => navigate('/practice/documents')}
                style={{ marginTop: 25 }}
              >
                Upload in Documents
              </Button>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default MissingStatements
