import { useCallback, useEffect, useMemo, useState } from 'react'
import { Container, Divider, Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FormikProvider, useFormik } from 'formik'

import {
  Accordion,
  Button,
  Card,
  FormikDateInput,
  FormikDropdown,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  getFieldName,
  GridRowColumn,
  Icon,
  Link,
  makeDateSchema,
  makeReqBoolSchema,
  makeReqStringSchema,
  makeSsnSchema,
  Popup,
  Text,
} from '../../../../../../components/BaseComponents'
import { Colors } from '../../../../../../styles/theme'
import {
  useEncryption,
  useFetchResponse,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import RemoveDependentModal from './RemoveDependentModal'
import {
  DATE_FORMATS,
  DATE_FORMATS_LUXON,
} from '../../../../../../utils/dateHelpers'
import { Form1040DetailsProps, FORM_1040_DETAILS_SCREENS } from '.'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponseByFormIdAndDependentId,
  selectTaxListQuestionResponsesByFormId,
  selectTaxListQuestionResponsesByQuestionIds,
} from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { useParams } from 'react-router-dom'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  createOrUpdateDependents,
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  DELETED_DEPENDENTS_KEY,
  deleteDependents,
  deleteTaxQuestionnaireResponses,
  DependentRelationship,
  FETCH_DEPENDENTS_KEY,
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  fetchDependents,
  postUpdateTaxQuestionnaireResponses,
  UPSERT_DEPENDENTS_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  UserDependent,
} from '../../taxChecklistQuestion.actions'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { DateTime } from 'luxon'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { logSentryError } from '../../../../../../utils/sentryHelpers'
import ReadOnlyForm from '../../Shared/ReadOnlyForm'
import {
  createIsSomeEnum,
  useAppDispatch,
} from '../../../../../../utils/typeHelpers'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { StepUpdates } from '../../Shared/ReviewStepsandProgresses/useProgressSteps'

const isDependentRelationship = createIsSomeEnum(DependentRelationship)

interface FormProps {
  dependent: Partial<UserDependent>
  index: number
  removeDependent: ({
    index,
    dependentId,
  }: {
    index: number
    dependentId?: number
  }) => void
  goToNextStep: (
    data: Partial<TaxChecklistResponse>[] | null,
    newScreen: FORM_1040_DETAILS_SCREENS | null,
    stepUpdates?: StepUpdates,
    getNextScreen?: () => FORM_1040_DETAILS_SCREENS
  ) => Promise<boolean>
  loadDependents: () => void
}

export const dependentQuestionIds = [
  TaxListQuestionId.dependent_full_name,
  TaxListQuestionId.dependent_date_of_birth,
  TaxListQuestionId.dependent_ssn,
  TaxListQuestionId.dependent_relationship,
]

const DependentForm = ({
  dependent,
  index,
  goToNextStep,
  removeDependent,
  loadDependents,
}: FormProps) => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const ssnMask = '***-**-****'
  const { formId } = useParams()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
    UPSERT_DEPENDENTS_KEY,
    DELETED_DEPENDENTS_KEY,
  ])
  const [error, setError] = useState<string | null>(null)
  const { encrypt } = useEncryption()

  const questionName = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.dependent_full_name,
    taxYear
  )
  const responseName = useReselector(
    selectTaxListQuestionResponseByFormIdAndDependentId,
    TaxListQuestionId.dependent_full_name,
    Number(formId),
    dependent.id
  )
  const questionRelationship = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.dependent_relationship,
    taxYear
  )
  const responseRelationship = useReselector(
    selectTaxListQuestionResponseByFormIdAndDependentId,
    TaxListQuestionId.dependent_relationship,
    Number(formId),
    dependent.id
  )
  const questionDob = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.dependent_date_of_birth,
    taxYear
  )
  const responseDob = useReselector(
    selectTaxListQuestionResponseByFormIdAndDependentId,
    TaxListQuestionId.dependent_date_of_birth,
    Number(formId),
    dependent.id
  )
  const questionSsn = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.dependent_ssn,
    taxYear
  )
  const responseSsn = useReselector(
    selectTaxListQuestionResponseByFormIdAndDependentId,
    TaxListQuestionId.dependent_ssn,
    Number(formId),
    dependent.id
  )

  // dependent ssns are stored in 2 separate tables
  // if no checklist response is set yet, fall back to the value from the user_dependents table
  const ssn = useMemo(
    () =>
      typeof responseSsn?.value === 'string'
        ? responseSsn.value
        : dependent.encryptedSsn,
    [responseSsn, dependent.encryptedSsn]
  )

  // note: validateOnMount doesn't work with enableReinitialize - https://github.com/jaredpalmer/formik/issues/3051
  const formik = useFormik({
    initialValues: {
      dependentName: responseName?.value ?? dependent.name,
      relationship:
        responseRelationship?.value ?? dependent.relationship?.toUpperCase(),
      dependentDob:
        responseDob?.value ??
        (typeof dependent.dateOfBirth === 'string'
          ? DateTime.fromISO(dependent.dateOfBirth)
              .toUTC()
              .toFormat(DATE_FORMATS_LUXON.INPUT)
          : undefined),
      dependentSsn: null,
      confirmDependentSsn: null,
    },
    isInitialValid: Boolean(
      (responseName?.value ?? dependent.name) &&
        (responseRelationship?.value ?? dependent.relationship) &&
        (responseDob?.value ?? dependent.dateOfBirth) &&
        ssn
    ),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setError(null)
      try {
        const encryptedSsn =
          typeof values.dependentSsn === 'string'
            ? await encrypt(values.dependentSsn)
            : typeof responseSsn?.value === 'string'
              ? responseSsn.value
              : undefined
        const responseData: Partial<TaxChecklistResponse>[] = [
          {
            id: responseName?.id,
            value: values.dependentName,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.dependent_full_name,
            userDependentId: dependent.id,
          },
          {
            id: responseRelationship?.id,
            value: values.relationship ?? undefined,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.dependent_relationship,
            userDependentId: dependent.id,
          },
          {
            id: responseDob?.id,
            value: values.dependentDob ?? undefined,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.dependent_date_of_birth,
            userDependentId: dependent.id,
          },
          {
            id: responseSsn?.id,
            value: encryptedSsn,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.dependent_ssn,
            userDependentId: dependent.id,
          },
        ]
        const userDependentRelationship =
          typeof values.relationship === 'string'
            ? values.relationship.toLowerCase()
            : undefined
        const userDependentUpdates: Partial<UserDependent> = {
          id: dependent.id,
          name:
            typeof values.dependentName === 'string'
              ? values.dependentName
              : undefined,
          relationship: isDependentRelationship(userDependentRelationship)
            ? userDependentRelationship
            : undefined,
          dateOfBirth:
            typeof values.dependentDob === 'string'
              ? (DateTime.fromFormat(
                  values.dependentDob,
                  DATE_FORMATS_LUXON.INPUT
                ).toISO() ?? undefined)
              : undefined,
          encryptedSsn,
        }
        let updateSuccess = true
        let userDependentId: number | null = null
        if (Object.keys(userDependentUpdates).length > 0) {
          const upsertResponse = await createOrUpdateDependents([
            userDependentUpdates,
          ])(dispatch)
          updateSuccess = Boolean(
            upsertResponse?.length && upsertResponse.length > 0
          )
          userDependentId = upsertResponse?.[0]?.id ?? null
        }
        if (updateSuccess && responseData.length > 0 && userDependentId) {
          await goToNextStep(
            responseData.map((rd) => ({ ...rd, userDependentId })),
            FORM_1040_DETAILS_SCREENS.skipNavigation
          )
          // skipcq: JS-0357
          setEditing(false)
          loadDependents()
        }
      } catch (err) {
        setError('An error occurred. Please try again.')
        logSentryError(err)
      }
    },
  })

  const { values, isValid, submitForm, resetForm, isSubmitting } = formik
  const [editing, setEditing] = useState(!isValid)

  const readOnly = useMemo(() => {
    return [
      { ...questionName, responseText: values.dependentName },
      { ...questionDob, responseText: values.dependentDob },
      {
        ...questionSsn,
        responseText: values.dependentSsn || responseSsn?.value ? ssnMask : '',
      },
      {
        ...questionRelationship,
        responseText: questionRelationship?.options?.find(
          (o) => o.id === values.relationship
        )?.text,
      },
    ]
  }, [
    questionDob,
    questionName,
    questionRelationship,
    questionSsn,
    values,
    responseSsn?.value,
  ])

  const onCancel = useCallback(() => {
    if (!dependent.id) {
      removeDependent({ index })
    }
    resetForm()
    setEditing(false)
  }, [dependent.id, index, removeDependent, resetForm])

  return (
    <FormikProvider value={formik}>
      <RemoveDependentModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        removeDependent={() =>
          removeDependent({ index, dependentId: dependent.id })
        }
      />
      {/* READ ONLY FORM */}
      {!editing && (
        <ReadOnlyForm
          title={`Dependent ${index + 1}`}
          setEditing={() => setEditing(true)}
          readOnlyData={readOnly}
        />
      )}
      {/* EDITABLE FORM */}
      {editing && (
        <Card type="subsection" backgroundColor="stone40">
          <Grid>
            <GridRowColumn
              columnStyle={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text as="h2" color="forest">
                Dependent {index + 1}
              </Text>
              {dependent.id && (
                <Button
                  style={{ borderColor: Colors.red }}
                  variant="secondary"
                  onClick={() => setModalOpen(true)}
                >
                  <Icon size="1x" color="red" icon={regular('trash-can')} />
                </Button>
              )}
            </GridRowColumn>
            <GridRowColumn short>
              <Divider />
            </GridRowColumn>
            <GridRowColumn short>
              <FormikInput
                label={questionName?.question?.text}
                name={getFieldName<typeof values>('dependentName')}
                schema={makeReqStringSchema({
                  field: 'full name',
                })}
                placeholder="Enter First and Last Name"
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn width={8}>
              <FormikDateInput
                maxDate={new Date()}
                label={questionDob?.question?.text}
                placeholder="MM-DD-YYYY"
                name={getFieldName<typeof values>('dependentDob')}
                schema={makeDateSchema({
                  field: 'date of birth',
                  format: DATE_FORMATS.INPUT,
                  strict: true,
                })}
                fullWidth
              />
            </GridRowColumn>
            <Grid.Row>
              <Grid.Column width={8}>
                <FormikInput
                  label={questionSsn?.question?.text}
                  name={getFieldName<typeof values>('dependentSsn')}
                  fullWidth
                  placeholder={!ssn ? '123-45-6789' : ssnMask}
                  format="###-##-####"
                  schema={makeSsnSchema({
                    field: 'social security number',
                    required: Boolean(!ssn),
                  })}
                  type="password"
                  componentType="pattern"
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <FormikInput
                  label={`Confirm ${questionSsn?.question?.text}`}
                  name={getFieldName<typeof values>('confirmDependentSsn')}
                  fullWidth
                  placeholder={!ssn ? '123-45-6789' : ssnMask}
                  format="###-##-####"
                  schema={makeSsnSchema({
                    field: 'social security number',
                    required: Boolean(!ssn || values.dependentSsn),
                  }).oneOf(
                    [values.dependentSsn],
                    'Social Security Number does not match'
                  )}
                  type="password"
                  componentType="pattern"
                />
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn width={8}>
              <FormikDropdown
                label={questionRelationship?.question?.text}
                name={getFieldName<typeof values>('relationship')}
                options={questionRelationship?.options?.map((o) => ({
                  text: o.text,
                  value: o.id,
                  key: o.id,
                }))}
                schema={makeReqStringSchema({ field: 'relationship' })}
                fullWidth
                afterLabel={
                  <Popup
                    content={
                      <Grid>
                        <GridRowColumn>
                          <Text as="h3">Dependent relationships</Text>
                        </GridRowColumn>
                        <GridRowColumn short>
                          <Text>
                            If none of the dependent relationships listed here
                            apply, select <b>Other</b>.
                          </Text>
                        </GridRowColumn>
                      </Grid>
                    }
                    trigger={
                      <Icon
                        size="1x"
                        style={{ marginLeft: 4 }}
                        icon={regular('question-circle')}
                        color="mediumGray"
                      />
                    }
                  />
                }
              />
            </GridRowColumn>
            <GridRowColumn>
              <Divider />
            </GridRowColumn>
            {error && (
              <GridRowColumn>
                <Text color="red">{error}</Text>
              </GridRowColumn>
            )}
            <GridRowColumn
              short
              columnStyle={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 20,
              }}
            >
              <Button
                onClick={onCancel}
                style={{ border: 0 }}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                loading={isUpdating || isSubmitting}
                variant="secondary"
                onClick={submitForm}
                disabled={!isValid || isUpdating || isSubmitting}
              >
                Save
              </Button>
            </GridRowColumn>
          </Grid>
        </Card>
      )}
    </FormikProvider>
  )
}

const DependentsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1040DetailsProps) => {
  const dispatch = useAppDispatch()
  const [dependents, setDependents] = useState<Partial<UserDependent>[]>([])
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()
  const allDependentResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    dependentQuestionIds,
    Number(formId)
  )

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPSERT_DEPENDENTS_KEY,
    DELETED_DEPENDENTS_KEY,
  ])

  const isFetching = useReselector(selectIsFetchingForKeys, [
    FETCH_DEPENDENTS_KEY,
    FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear),
  ])

  const questionClaimingDependents = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.has_dependents,
    taxYear
  )
  const responseClaimingDependents = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.has_dependents,
    Number(formId)
  )
  // Derived response
  const responseNumberOfDependents = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_dependents,
    Number(formId)
  )
  const responseChildTaxCredit = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.child_tax_credit,
    Number(formId)
  )

  const loadDependents = useCallback(async () => {
    const existing = await fetchDependents()(dispatch)
    if (existing?.length && existing.length > 0) {
      setDependents(existing)
    }
  }, [dispatch])

  const dependentsOnInitialLoad = useFetchResponse(fetchDependents)

  const hasDependentsUnder17 = useMemo(() => {
    return dependents.some((d) => {
      const endOfTaxYear = DateTime.fromFormat(`${taxYear}-12-31`, 'yyyy-LL-dd')
      const dob = d.dateOfBirth && DateTime.fromISO(d.dateOfBirth).toUTC()
      return dob && endOfTaxYear.diff(dob, 'years').years < 17
    })
  }, [dependents, taxYear])

  //this effect is meant to only happen on initial page load to account for the case where the therapist
  //has user dependents but has no checklist responses yet for those dependents.
  useEffect(() => {
    const loadInitialDependents = async () => {
      if (!dependentsOnInitialLoad || isFetching) {
        return
      }

      const responseData: Partial<TaxChecklistResponse>[] = []
      //if the dependent exists but there are no tax checklist responses, seed the responses
      for (const dependent of dependentsOnInitialLoad) {
        const responseName = allDependentResponses.find(
          (r) =>
            r.userDependentId === dependent.id &&
            r.questionId === TaxListQuestionId.dependent_full_name
        )
        const responseRelationship = allDependentResponses.find(
          (r) =>
            r.userDependentId === dependent.id &&
            r.questionId === TaxListQuestionId.dependent_relationship
        )
        const responseDob = allDependentResponses.find(
          (r) =>
            r.userDependentId === dependent.id &&
            r.questionId === TaxListQuestionId.dependent_date_of_birth
        )

        if (dependent.name && !responseName) {
          responseData.push({
            value: dependent.name,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.dependent_full_name,
            userDependentId: dependent.id,
          })
        }
        if (dependent.relationship && !responseRelationship) {
          responseData.push({
            value: dependent.relationship.toUpperCase(),
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.dependent_relationship,
            userDependentId: dependent.id,
          })
        }
        if (dependent.dateOfBirth && !responseDob) {
          responseData.push({
            value: DateTime.fromISO(dependent.dateOfBirth)
              .toUTC()
              .toFormat(DATE_FORMATS_LUXON.INPUT),
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.dependent_date_of_birth,
            userDependentId: dependent.id,
          })
        }
      }
      let responseSuccess = true
      if (responseData.length > 0) {
        responseSuccess = Boolean(
          await postUpdateTaxQuestionnaireResponses(responseData)(dispatch)
        )
      }
      if (responseSuccess) {
        setDependents(dependentsOnInitialLoad)
      }
    }

    loadInitialDependents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //we don't want this effect happening again, so we are not going to add a dependency to allDependentResponses
    dependentsOnInitialLoad,
    dispatch,
    formId,
    isFetching,
  ])

  const dependentsForm = useFormik({
    initialValues: {
      claimingDependents:
        responseClaimingDependents?.[0]?.value ??
        (dependents.flatMap((d) => (d.id ? [d] : [])).length > 0
          ? true
          : undefined),
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (
        values.claimingDependents !== responseClaimingDependents?.[0]?.value
      ) {
        responseData.push({
          id: responseClaimingDependents?.[0]?.id,
          value: values.claimingDependents,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.has_dependents,
        })
      }
      const numberOfDependents =
        values.claimingDependents !== true ? 0 : dependents.length
      if (numberOfDependents !== responseNumberOfDependents?.[0]?.value) {
        responseData.push({
          id: responseNumberOfDependents?.[0]?.id,
          value: values.claimingDependents !== true ? 0 : dependents.length,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_dependents,
        })
      }
      if (responseChildTaxCredit?.[0]?.value !== hasDependentsUnder17) {
        responseData.push({
          id: responseChildTaxCredit?.[0]?.id,
          value: hasDependentsUnder17,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.child_tax_credit,
        })
      }

      let deleteSuccess = true
      if (values.claimingDependents === false) {
        if (allDependentResponses.length > 0) {
          deleteSuccess = Boolean(
            await dispatch(
              deleteTaxQuestionnaireResponses(
                allDependentResponses.map((r) => r.id)
              )
            )
          )
        }
        if (deleteSuccess && dependents.filter((d) => d.id).length > 0) {
          deleteSuccess = Boolean(
            await dispatch(
              deleteDependents(dependents.flatMap((d) => d.id ?? []))
            )
          )
        }
      }
      if (deleteSuccess) {
        await goToNextStep(responseData, nextScreen ?? null, {
          completedSteps: [SubStepIdentifiers.spouseAndDependentInformation],
        })
      }
    },
  })

  const { values, submitForm, isValid, isSubmitting } = dependentsForm

  const removeDependent = useCallback(
    async ({ index, dependentId }: { index: number; dependentId?: number }) => {
      let success = true
      if (dependentId) {
        const responsesToDelete = allDependentResponses.flatMap((r) =>
          r.userDependentId === dependentId ? [r.id] : []
        )
        success = Boolean(await dispatch(deleteDependents([dependentId])))
        if (success) {
          success = Boolean(
            await dispatch(deleteTaxQuestionnaireResponses(responsesToDelete))
          )
        }
      }
      if (success) {
        setDependents((old) => {
          const newList = [...old]
          newList.splice(index, 1)
          return newList
        })
      }
      return true
    },
    [dispatch, allDependentResponses]
  )

  return (
    // skipcq: JS-0415
    <>
      <FormikProvider value={dependentsForm}>
        <Container style={{ marginTop: 56 }} text textAlign="left">
          <Grid>
            <GridRowColumn>
              <Text as="display2" textAlign="center">
                Your Dependents
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodyLg">
                Please look over the following and update anything that has
                changed. These answers will appear on your tax return.
              </Text>
            </GridRowColumn>
            <GridRowColumn width={12}>
              <FormikLabelError
                name={getFieldName<typeof values>('claimingDependents')}
                label={questionClaimingDependents?.question?.text}
                schema={makeReqBoolSchema()}
              />
            </GridRowColumn>
            <GridRowColumn short>
              <Text as="h3">
                (If you&apos;re married filing separately, you and your spouse
                cannot claim the same dependents).
              </Text>
            </GridRowColumn>
            <Grid.Row style={{ justifyContent: 'center' }}>
              <Grid.Column width={8} style={{ paddingRight: 0 }}>
                <FormikRadioToggleButton
                  fullWidth
                  name={getFieldName<typeof values>('claimingDependents')}
                  value
                >
                  Yes
                </FormikRadioToggleButton>
              </Grid.Column>
              <Grid.Column width={8}>
                <FormikRadioToggleButton
                  fullWidth
                  name={getFieldName<typeof values>('claimingDependents')}
                  value={false}
                >
                  No
                </FormikRadioToggleButton>
              </Grid.Column>
            </Grid.Row>
            {values.claimingDependents &&
              dependents.map((dep, index) => (
                <GridRowColumn key={dep.id ?? index}>
                  <DependentForm
                    index={index}
                    dependent={dep}
                    removeDependent={removeDependent}
                    goToNextStep={goToNextStep}
                    loadDependents={loadDependents}
                  />
                </GridRowColumn>
              ))}
            {values.claimingDependents === true && (
              <GridRowColumn
                columnStyle={{ display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  variant="secondary"
                  onClick={() => setDependents((old) => [...old, {}])}
                >
                  <Icon
                    size="1x"
                    icon={regular('plus')}
                    style={{ marginRight: 8 }}
                  />
                  <Text>Add Dependent</Text>
                </Button>
              </GridRowColumn>
            )}
            <Grid.Row />
            <GridRowColumn width={14} style={{ justifyContent: 'center' }}>
              <Accordion
                style={{
                  borderTop: '1px solid #E0E0E0',
                  borderBottom: '1px solid #E0E0E0',
                }}
                contentStyle={{ paddingTop: 0 }}
                backgroundColor="white"
                title="Who qualifies as a dependent?"
                content={
                  <Grid>
                    <GridRowColumn>
                      <Text as="bodyLg">
                        A child or relative of the taxpayer who meets the
                        IRS&apos;s qualifying tests. You can find those tests{' '}
                        <Link
                          as="secondaryLink"
                          newPage
                          size="large"
                          href="https://apps.irs.gov/app/vita/content/globalmedia/4491_dependency_exemptions.pdf"
                        >
                          here
                        </Link>
                        .
                      </Text>
                    </GridRowColumn>
                  </Grid>
                }
              />
            </GridRowColumn>
          </Grid>
        </Container>
      </FormikProvider>

      <Grid>
        <FormFlowFooter
          continueDisabled={
            !isValid ||
            isUpdating ||
            Boolean(values.claimingDependents && !dependents.length) ||
            isSubmitting
          }
          loading={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </>
  )
}

export default DependentsPanel
