import AhaMomentsBaseComponent from '../AhaMomentsBaseComponent'
import { Text } from '../../../../components/BaseComponents'
import { Image } from 'semantic-ui-react'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  QuarterlyTaxEstimateDetail,
  fetchAllQuarterlyTaxEstimateDetailsIfNeeded,
} from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { useEffect } from 'react'
import { selectCurrentAnnualTaxDetails } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { DateTime } from 'luxon'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { selectQuarterlyTaxEstimateDetailsByYearAndQuarter } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'

const TaxesTimeline = ({
  handleContinue,
  handleBack,
}: {
  handleContinue: () => void
  handleBack: () => void
}) => {
  const dispatch = useAppDispatch()
  const annualTaxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const taxSeasonKickoffStartAt = annualTaxDetails?.taxSeasonKickoffStartAt
  const calendarYear = annualTaxDetails?.taxYear
  const irsForm1040DueDate = annualTaxDetails?.irsFormDueDates.form_1040.irs
    .dueDate
    ? isoToUTCDateTime(
        annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate
      ).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)
    : 'April 15'
  const isTaxSeasonOpen =
    taxSeasonKickoffStartAt &&
    DateTime.fromISO(taxSeasonKickoffStartAt) < DateTime.now()

  const q1Details = useReselector(
    selectQuarterlyTaxEstimateDetailsByYearAndQuarter,
    calendarYear,
    '1'
  )
  const q2Details = useReselector(
    selectQuarterlyTaxEstimateDetailsByYearAndQuarter,
    calendarYear,
    '2'
  )
  const q3Details = useReselector(
    selectQuarterlyTaxEstimateDetailsByYearAndQuarter,
    calendarYear,
    '3'
  )
  const q4Details = useReselector(
    selectQuarterlyTaxEstimateDetailsByYearAndQuarter,
    calendarYear,
    '4'
  )

  const quarterDetails = [q1Details, q2Details, q3Details, q4Details]

  const getFormattedIrsPaymentDueDates = (
    quarterDetails: (QuarterlyTaxEstimateDetail | undefined)[]
  ) => {
    return quarterDetails
      .filter((quarter): quarter is QuarterlyTaxEstimateDetail => {
        return (
          quarter !== undefined &&
          typeof quarter.irsPaymentDueAt === 'string' &&
          quarter.irsPaymentDueAt.length > 0
        )
      })
      .map((quarter) => {
        const date = isoToUTCDateTime(quarter.irsPaymentDueAt)
        return date.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)
      })
  }

  const formattedDates = getFormattedIrsPaymentDueDates(quarterDetails)

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  return (
    <AhaMomentsBaseComponent
      title="Get ready for taxes"
      subtitle="When do I have to pay taxes?"
      description="As a self-employed therapist, you’re required to pay annual taxes once per year, and quarterly taxes every financial quarter."
      media={
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/calendar_block.svg"
          style={{
            width: '100%',
          }}
        />
      }
      features={[
        {
          imgSrc:
            'https://heard-images.s3.amazonaws.com/assets/calendar_day_april_15.svg',
          title: 'Annual taxes',
          description: (
            <>
              <Text>
                {isTaxSeasonOpen
                  ? 'Annual tax season is currently ongoing. You can get started on your taxes right away.'
                  : "If you choose to file annual taxes with Heard, we'll kick off the process in January."}
              </Text>
              <div
                style={{
                  alignItems: 'center',
                  gap: '8px',
                  marginTop: 6,
                }}
              >
                <Text as="h3">File by: {irsForm1040DueDate}</Text>
              </div>
            </>
          ),
        },
        {
          imgSrc: 'https://heard-images.s3.amazonaws.com/assets/quarters.svg',
          title: 'Quarterly taxes',
          description: (
            <>
              <Text>
                You&apos;ll also need to pay an estimate of how much you owe in
                taxes each quarter, throughout the year. Each time, you&apos;ll
                fill out Heard&apos;s Quarterly Tax Checklist to get a
                personalized estimate.
              </Text>
              <Text as="h3" style={{ marginTop: 6, marginBottom: 6 }}>
                Pay 4 times a year by:
              </Text>
              <Text>
                <ul
                  style={{
                    paddingLeft: '20px',
                    margin: 0,
                  }}
                >
                  {formattedDates.map((date) => (
                    <li key={date}>{date}</li>
                  ))}
                </ul>
              </Text>
            </>
          ),
        },
      ]}
      learnMoreItems={[
        {
          title: 'Why do I need to pay estimated taxes quarterly?',
          content: (
            <Text>
              If you are self-employed and will owe more than $1,000 in taxes at
              the end of the fiscal year, the IRS requires you to pay your taxes
              four times a year in estimated payments. These payments are
              standard for those not working for an employer and, therefore, not
              subject to tax withholding.
            </Text>
          ),
        },
        {
          title: 'What`s the difference between annual and quarterly taxes?',
          content: (
            <Text>
              You need to pay quarterly taxes four times a year. Annual taxes
              are determined by the IRS based on your income and are due once
              per year.
              <br />
              <br />
              As a private practice owner, the IRS and most state agencies
              require you to make quarterly tax estimated payments towards your
              annual tax liability. It&apos;s important to note that you might
              face penalties if your estimated tax payments are submitted late
              or underpayment, even if you&apos;re expecting a refund when you
              file your annual tax return.
            </Text>
          ),
        },
      ]}
      backButtonText="Back"
      continueButtonText="Continue"
      handleContinue={handleContinue}
      handleBack={handleBack}
    />
  )
}

export default TaxesTimeline
