import { Divider, Grid } from 'semantic-ui-react'
import {
  Card,
  GridRowColumn,
  Text,
  Icon,
  Button,
  Link,
} from '../../../../components/BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

const SupportForYou = () => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Text as="h1">Have questions around how Heard can help you?</Text>
          <Text as="bodyMd">Let us know how we can support you</Text>
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Card.Group itemsPerRow={3}>
        <Card
          type="section"
          backgroundColor="stone40"
          style={{ boxShadow: 'none', border: 'none' }}
        >
          <Card.Content>
            <Grid>
              <GridRowColumn>
                <Text as="h2">View our Support Center</Text>
                <br />
                <Text as="bodySm">
                  Have a quick question? View our Support Center to get
                  questions answered.
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Link newPage href={'https://support.joinheard.com/hc/en-us'}>
                  <Button onClick={() => {}} variant="secondary" size="small">
                    View Support Center
                    <Icon
                      icon={regular('arrow-right')}
                      style={{ marginLeft: 8 }}
                    />
                  </Button>
                </Link>
              </GridRowColumn>
            </Grid>
          </Card.Content>
        </Card>
        <Card
          type="section"
          backgroundColor="stone40"
          style={{ boxShadow: 'none', border: 'none' }}
        >
          <Card.Content>
            <Grid>
              <GridRowColumn>
                <Text as="h2">Learn from our Experts</Text>
                <br />
                <Text as="bodySm">
                  Discover free articles, calculators, and other resources
                  developed by our team of experts to help you better understand
                  and manage your private practice finances.
                </Text>
              </GridRowColumn>

              <GridRowColumn>
                <Link newPage href={'https://www.joinheard.com/resources'}>
                  <Button onClick={() => {}} variant="secondary" size="small">
                    Browse Resource Hub
                    <Icon
                      icon={regular('arrow-right')}
                      style={{ marginLeft: 8 }}
                    />
                  </Button>
                </Link>
              </GridRowColumn>
            </Grid>
          </Card.Content>
        </Card>
        <Card
          type="section"
          backgroundColor="stone40"
          style={{ boxShadow: 'none', border: 'none' }}
        >
          <Card.Content>
            <Grid>
              <GridRowColumn>
                <Text as="h2">Contact Us</Text>
                <br />
                <Text as="bodySm">
                  Have specific questions around how Heard can be beneficial for
                  you?{' '}
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Link newPage href="mailto: freetrial@joinheard.com">
                  <Button onClick={() => {}} variant="secondary" size="small">
                    Contact Us
                    <Icon
                      icon={regular('arrow-right')}
                      style={{ marginLeft: 8 }}
                    />
                  </Button>
                </Link>
              </GridRowColumn>
            </Grid>
          </Card.Content>
        </Card>
      </Card.Group>
      <GridRowColumn />
    </Grid>
  )
}

export default SupportForYou
