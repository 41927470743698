import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Grid, Loader, Divider } from 'semantic-ui-react'
import styled from 'styled-components'
import { sortBy } from 'lodash'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Colors } from '../../../styles/theme'
import {
  Button,
  GridRowColumn,
  Icon,
  Alert,
  Text,
  Link,
  Tab,
  Card,
} from '../../BaseComponents'
import { fetchUserTransactions } from '../../../features/Transactions/transactions.slice'
import { fetchTransactionCategoriesIfNeeded } from '../../../features/Reports/reports.slice'
import {
  fetchProfitsBreakdown,
  fetchExpensesBreakdown,
  fetchUncategorizedBreakdown,
  Expense,
  fetchOtherBreakdown,
} from '../../../actions/reportActions'
import { selectActiveReportByDate } from '../../../selectors/reportSelectors'
import { getTransactionCategorySelector } from '../../../features/Reports/reports.selectors'
import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import CashflowBarGraph from './CashflowBarGraph'
import BookkeepingReportTopExpensesCard from './BookkeepingReportTopExpensesCard'
import { markUserActionItemCompleteIfExists } from '../../../features/Dashboard/UserActionItems/service'
import {
  UserActionItemActionItemIdentifiers,
  fetchUserActionItemsIfNeeded,
} from '../../../features/Dashboard/UserActionItems/userActionItems.slice'
import { eoyReviewReviewPeriodNotActive } from '../../../features/Dashboard/UserActionItems/userActionItems.selectors'
import { selectCurrentAnnualTaxYear } from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useReselector } from '../../../utils/sharedHooks'
import { selectBookkeepingReportList } from '../../../selectors/bookkeepingReportsSelectors'
import { fetchBookkeepingReports } from '../../../actions/bookkeepingReportActions'
import {
  getUserStatementsForReport,
  selectDocuments,
} from '../../../features/UserDocuments/userDocuments.selector'
import { fetchUserDocuments } from '../../../features/UserDocuments/userDocuments.slice'
import { DATE_FORMATS, DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { MonthPicker } from './MonthPicker'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { ReportsTransactionTable } from './ReportsTransactionTable'
import {
  useAnalyticsTrack,
  useAnalyticsView,
} from '../../../features/Amplitude'
import PageHeader from '../../shared/PageHeader'
import { DateTime } from 'luxon'
import { BOOKKEEPING_REPORT_STATUSES } from '../../../constants/businessConstants'
import { selectTransactionsNeedReviewForMonth } from '../../../features/Transactions/transactions.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'
import MissingStatements from '../../../features/UserDocuments/sharedComponents/MissingStatements'
import { BookkeepingReport } from '../../../reducers/finances/bookkeepingReportsReducer'
import FreeTrialUnreviewedAlert from '../../shared/FreeTrialUnreviewedAlert'
import { isFreeTrialPromoCode } from '../../../selectors/user.selectors'

const CenteredHr = styled.hr`
  width: 100%;
  margin-inline-end: 10px;
  border: none;
  border-top: 1px solid ${Colors.gray};
`

const LineItem = ({
  item,
}: {
  item: {
    sum: number | string
    transactionCategoryId: number | null
  }
}) => {
  const category = useReselector(
    getTransactionCategorySelector,
    item.transactionCategoryId
  )

  return (
    <Grid.Row className="lineItemRow reports">
      <Grid.Column width={10} floated="left">
        <Text>{category?.name || 'To Be Categorized'}</Text>
      </Grid.Column>
      <Grid.Column
        width={6}
        floated="right"
        textAlign="right"
        className="lineItemSum"
      >
        <Text>
          <CurrencyFormatLabel value={item?.sum} />
        </Text>
      </Grid.Column>
    </Grid.Row>
  )
}

const TotalLineItem = ({
  label,
  sum,
}: {
  label: string
  sum: string | number
}) => {
  return (
    <Grid.Row className="lineItemRow reports">
      <Grid.Column computer={3} tablet={4} mobile={5} floated="left">
        <Text>{label}</Text>
      </Grid.Column>
      <Grid.Column computer={11} tablet={8} mobile={6}>
        <CenteredHr />
      </Grid.Column>
      <Grid.Column
        computer={2}
        tablet={4}
        mobile={5}
        floated="right"
        className="lineItemSum"
        textAlign="right"
      >
        <Text color="accentGreen">
          <CurrencyFormatLabel value={sum} />
        </Text>
      </Grid.Column>
    </Grid.Row>
  )
}

export const EoyAlertBanner = () => {
  const year = useReselector(selectCurrentAnnualTaxYear)
  const eoyPeriodInactive = useReselector(eoyReviewReviewPeriodNotActive, year)
  if (eoyPeriodInactive) {
    return null
  }
  return (
    <Alert style={{ borderRadius: 0 }} type="announcement">
      <Text as="h3">
        <b>Action Required:</b> Complete your {year} End-of-Year Review
        <Link to="/taxes/annual" style={{ marginLeft: 16 }} color="green">
          Get Started
          <Icon
            icon={solid('arrow-right')}
            color="green"
            style={{ marginLeft: 6 }}
          />
        </Link>
      </Text>
    </Alert>
  )
}

const EoyPromptCard = () => {
  const navigate = useNavigate()
  const year = useReselector(selectCurrentAnnualTaxYear)

  return (
    <Card backgroundColor="natural">
      <Grid>
        <Grid.Row>
          <Grid.Column width={13}>
            <Grid>
              <GridRowColumn>
                <Text as="h2">Complete your {year} End-of-Year Review</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  Take some time to review your {year} transactions and relevant
                  information.
                </Text>
              </GridRowColumn>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={3}
            style={{ display: 'flex', justifyContent: 'right' }}
            verticalAlign="middle"
          >
            <Button onClick={() => navigate('/taxes/annual')}>
              Get Started
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const TAB_INDEXES: { [key: number]: string } = {
  0: 'overview',
  1: 'profit-loss',
}

const ReportInProgress = ({
  activeReport,
}: {
  activeReport: BookkeepingReport
}) => {
  const enableLowerFrictionStatements = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )

  return (
    <Alert
      customIcon={<Icon icon={solid('refresh')} style={{ margin: 0 }} />}
      type="info"
    >
      <Text as="h3" style={{ textAlign: 'left' }}>
        Report in progress
      </Text>
      {enableLowerFrictionStatements ? (
        <Text>
          Your report will be ready once we&apos;ve categorized your
          transactions.
        </Text>
      ) : (
        <Text>
          We have your monthly statements for{' '}
          {DateTime.fromFormat(
            activeReport.date,
            DATE_FORMATS_LUXON.YEAR_MONTH
          ).toFormat(DATE_FORMATS_LUXON.MONTH_YEAR)}{' '}
          . Your report will be ready once we’ve categorized your transactions.
        </Text>
      )}
    </Alert>
  )
}

////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
// We expect startDate and endDate as: YYYY-MM-DD format
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

const BookkeepingReportPanel = () => {
  const dispatch = useAppDispatch()
  const { reportName, bookkeepingReportMonth, bookkeepingReportYear } =
    useParams()
  const navigate = useNavigate()
  const defaultActiveIndex = reportName === 'profit-loss' ? 1 : 0

  const pageView = useAnalyticsView()
  const enableLowerFrictionStatements = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableLowerFrictionStatements,
    false
  )

  useEffect(() => {
    if (reportName === 'overview') pageView('monthly bookkeeping report')
  }, [pageView, reportName])

  const bookkeepingReports = useReselector(selectBookkeepingReportList)
  const activeReport = useReselector(
    selectActiveReportByDate,
    `${bookkeepingReportYear}-${bookkeepingReportMonth}`
  )

  const userDocuments = useReselector(selectDocuments)
  const year = useReselector(selectCurrentAnnualTaxYear)
  const eoyPeriodInactive = useReselector(eoyReviewReviewPeriodNotActive, year)
  const startDate = activeReport ? moment.utc(activeReport.startDate) : null
  const endDate = activeReport ? moment.utc(activeReport.endDate) : null
  const currentStatus = activeReport?.statuses[0]
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  /**
   * Report Status: Only used for Paying users
   * For 14 day Trial Users: Always show report as there is no BK support
   */
  const reportReconciled = hasFreeTrialPromoCode
    ? true
    : currentStatus?.status &&
      [
        BOOKKEEPING_REPORT_STATUSES.Reconciled.name,
        BOOKKEEPING_REPORT_STATUSES.Done.name,
      ].includes(currentStatus?.status)

  const reportDone = hasFreeTrialPromoCode
    ? true
    : currentStatus?.status === BOOKKEEPING_REPORT_STATUSES.Done.name
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const track = useAnalyticsTrack()
  const needsReviewTransactions = useReselector(
    selectTransactionsNeedReviewForMonth,
    activeReport?.date
  )

  const [loading, setLoading] = useState(true)
  const [expensesObj, setExpensesObj] = useState<{
    expenses: Expense[]
    sum: number
  }>({
    expenses: [],
    sum: 0,
  })

  const [profitsObj, setProfitsObj] = useState<{
    profits: Expense[]
    sum: number
  }>({
    profits: [],
    sum: 0,
  })

  const [otherObj, setOtherObj] = useState<{
    otherExpenses: Expense[]
    sum: number | string
  }>({
    otherExpenses: [],
    sum: 0,
  })

  const [uncatObj, setUncatObj] = useState<{
    sum: number
    transactionCategoryId: number | null
  } | null>(null)

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        dispatch(fetchBookkeepingReports()),
        dispatch(fetchUserTransactions()),
        dispatch(fetchTransactionCategoriesIfNeeded()),
        dispatch(fetchUserDocuments()),
        dispatch(fetchUserActionItemsIfNeeded()),
      ])

      setLoading(false)
    }

    fetch()
  }, [dispatch])

  const markActionItemComplete = useCallback(async () => {
    const dateParts = activeReport?.date.split('-')
    if (dateParts) {
      // Make remove leading 0 if exists
      const month =
        dateParts[1] && dateParts[1][0] === '0'
          ? dateParts[1].substring(1)
          : dateParts[1]

      await markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.reviewBooks({
          month,
          year: dateParts[0],
        }),
        (event, properties) => track(event, properties)
      )
    }
  }, [activeReport?.date, track])

  useEffect(() => {
    const fetch = async () => {
      if (!activeReport) {
        return
      }

      setLoading(true)
      const params = {
        startDate: moment.utc(activeReport.startDate),
        endDate: moment.utc(activeReport.endDate),
      }
      const profits = await fetchProfitsBreakdown(params)()
      const expenses = await fetchExpensesBreakdown(params)(dispatch)
      const other = await fetchOtherBreakdown(params)(dispatch)
      const unCat = await fetchUncategorizedBreakdown(params)()

      setProfitsObj(profits)
      setExpensesObj(expenses)
      setOtherObj(other)
      setUncatObj(unCat)

      await markActionItemComplete()
      setLoading(false)
    }

    fetch()
  }, [activeReport, dispatch, markActionItemComplete])

  const updateTab = (newIndex: number) => {
    const newTab = TAB_INDEXES[newIndex]
    navigate(
      `/reports/bookkeeping/${bookkeepingReportMonth}/${bookkeepingReportYear}/${newTab}`,
      {
        replace: true,
      }
    )
  }

  const profitsAndLoss = useMemo(() => {
    const netIncome = Number(profitsObj?.sum) + Number(expensesObj?.sum) || 0

    return (
      <Grid className="bookkeepingReport">
        <GridRowColumn>
          <Text as="h2">Profit and Loss Statement</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text as="h3">Income</Text>
        </GridRowColumn>
        {profitsObj.profits.map((profit) => (
          <LineItem item={profit} key={profit.transactionCategoryId} />
        ))}
        <TotalLineItem label="Total Income" sum={profitsObj?.sum} />
        <GridRowColumn short>
          <Text as="h3">Expenses</Text>
        </GridRowColumn>
        {expensesObj.expenses.map((expense) => (
          <LineItem item={expense} key={expense.transactionCategoryId} />
        ))}
        <TotalLineItem label="Total Expenses" sum={expensesObj?.sum} />
        <Grid.Row />
        <TotalLineItem label="Net Profit" sum={netIncome} />
        <GridRowColumn />
        <GridRowColumn short>
          <Text as="h3">Other</Text>
        </GridRowColumn>
        {otherObj.otherExpenses.map((expense) => (
          <LineItem item={expense} key={expense.transactionCategoryId} />
        ))}
        <TotalLineItem label="Total Other" sum={otherObj?.sum} />
        <Divider section />
        <GridRowColumn>
          <Text as="h2">To Be Categorized</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            Don&apos;t worry about these— your bookkeeper will categorize these
            transactions each month.
          </Text>
        </GridRowColumn>
        {uncatObj && <LineItem item={uncatObj} />}
      </Grid>
    )
  }, [
    expensesObj.expenses,
    expensesObj?.sum,
    otherObj.otherExpenses,
    otherObj?.sum,
    profitsObj.profits,
    profitsObj?.sum,
    uncatObj,
  ])

  const reports = useMemo(() => {
    if (!startDate) {
      return null
    }

    const formattedMonth = startDate.format(DATE_FORMATS.MONTH_YEAR)

    return (
      <div className="bookkeepingReport">
        <Grid doubling stackable>
          <Grid.Row stretched verticalAlign="top">
            <Grid.Column stretched width={8}>
              <CashflowBarGraph
                monthView
                currentMonth={formattedMonth}
                dropdownDisabled
              />
            </Grid.Column>
            <Grid.Column stretched width={8}>
              <BookkeepingReportTopExpensesCard
                loading={loading}
                expensesObject={expensesObj}
              />
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn>
            <Text as="h2">{formattedMonth} Transactions</Text>
            <Divider />
            <br />
            {startDate && endDate && (
              <ReportsTransactionTable
                startDate={startDate}
                endDate={endDate}
              />
            )}
          </GridRowColumn>
        </Grid>
      </div>
    )
  }, [endDate, expensesObj, loading, startDate])

  const bookkeepingIncompleteStatusText = useMemo(() => {
    if (!startDate || !activeReport) {
      return null
    }

    if (enableLowerFrictionStatements) {
      return (
        <MissingStatements
          includeUploadButton
          whenNone={<ReportInProgress activeReport={activeReport} />}
        />
      )
    }

    const monthStatements = getUserStatementsForReport(userDocuments, startDate)

    if (monthStatements.length === 0) {
      return (
        <Alert
          customIcon={
            <Icon icon={solid('triangle-exclamation')} style={{ margin: 0 }} />
          }
          type="warn"
        >
          <Grid>
            <Grid.Row>
              <Grid.Column computer={12} tablet={8} mobile={8}>
                <Text as="h3" style={{ marginBottom: 4 }}>
                  Upload your monthly statements
                </Text>
                <Text>
                  {DateTime.fromFormat(
                    activeReport.date,
                    DATE_FORMATS_LUXON.YEAR_MONTH
                  ).toFormat(DATE_FORMATS_LUXON.MONTH_YEAR)}{' '}
                  statements for all connected accounts are needed to complete
                  your report. We use these to review your numbers for accuracy.{' '}
                  <br />
                </Text>
                <Text as="bodySm" style={{ color: Colors.darkGray }}>
                  Tired of manual uploads? Sign up for{' '}
                  <Link
                    to="/conversations?newConversation=true&limitedBankAccess=true"
                    size="small"
                  >
                    limited bank access.
                  </Link>
                </Text>
              </Grid.Column>
              <Grid.Column
                computer={4}
                tablet={8}
                mobile={8}
                textAlign="right"
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                }}
              >
                <Button onClick={() => navigate('/practice/documents')}>
                  Upload in Documents
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Alert>
      )
    } else {
      return <ReportInProgress activeReport={activeReport} />
    }
  }, [
    startDate,
    userDocuments,
    activeReport,
    navigate,
    enableLowerFrictionStatements,
  ])

  const reconciledReportAlert = useMemo(() => {
    if (!activeReport || reportDone) {
      return null
    }

    if (needsReviewTransactions?.count && needsReviewTransactions.count > 0) {
      return (
        <Alert
          customIcon={
            <Icon
              icon={solid('triangle-exclamation')}
              size="1x"
              style={{ margin: 0 }}
            />
          }
          type="warn"
        >
          <Grid>
            <Grid.Row>
              <Grid.Column computer={13} tablet={8} mobile={8}>
                <Text as="h3">Incomplete report</Text>
                <Text>
                  We need you to clarify <b>{needsReviewTransactions.count}</b>{' '}
                  transactions to complete your report for this month. Until
                  then, your numbers may not be fully accurate.
                </Text>
              </Grid.Column>
              <Grid.Column
                computer={3}
                tablet={8}
                mobile={8}
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => {
                    navigate(
                      `/transactions/review?startDate=${startDate?.format(DATE_FORMATS.INPUT)}&endDate=${endDate?.format(DATE_FORMATS.INPUT)}`
                    )
                  }}
                >
                  Clarify Now
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Alert>
      )
    }
    return (
      <Alert
        customIcon={
          <Icon
            icon={solid('triangle-exclamation')}
            size="1x"
            style={{ margin: 0 }}
          />
        }
        type="warn"
      >
        <Text as="h3">Report in progress</Text>
        <Text>
          The bookkeeping team is still reviewing your{' '}
          {DateTime.fromFormat(
            activeReport.date,
            DATE_FORMATS_LUXON.YEAR_MONTH
          ).toFormat(DATE_FORMATS_LUXON.MONTH_YEAR)}{' '}
          report. Your numbers may not be fully accurate until then.
        </Text>
      </Alert>
    )
  }, [
    activeReport,
    endDate,
    navigate,
    needsReviewTransactions?.count,
    reportDone,
    startDate,
  ])

  // Show loader if we have no data
  if (loading) {
    return (
      <div id="bookkeepingReportPanel">
        <Loader active inline />
      </div>
    )
  }
  // If user has no bookkeeping reports (new user), show a not yet screen
  else if (bookkeepingReports?.length === 0) {
    return (
      <div id="bookkeepingReportPanel">
        <Text as="h1">No Bookkeeping Reports...yet!</Text>
        <br />
        <Alert
          customIcon={
            <Icon icon={solid('clock')} size="1x" style={{ margin: 0 }} />
          }
          type="announcement"
        >
          Once our team finishes your books from your first month with Heard,
          you will see a monthly report here! Please note: in order for us to
          accurately close your books and release your monthly report each
          month, your monthly bank account statement(s) must be uploaded to your
          profile and all request transactions must be reviewed.
        </Alert>
      </div>
    )
  } else if (!bookkeepingReportMonth || !bookkeepingReportYear) {
    // If there is no explicit start and end date, let's pull the most recent report and redirect
    const sortedReports = sortBy(bookkeepingReports, 'date')
    const lastReport = sortedReports[sortedReports.length - 1]
    const lastReportDate = DateTime.fromFormat(
      lastReport.date,
      DATE_FORMATS_LUXON.YEAR_MONTH
    )
    return (
      <Navigate
        to={`/reports/bookkeeping/${lastReportDate.toFormat('MM')}/${lastReportDate.toFormat('yyyy')}/overview`}
        replace
      />
    )
  }

  // Otherwise, we are at a stage where we can display the report
  // We want to render report only if the report is ready
  return (
    <>
      {!eoyPeriodInactive && <EoyPromptCard />}
      <div id="bookkeepingReportPanel">
        {startDate && (
          <PageHeader
            header={startDate.format(DATE_FORMATS.MONTH_YEAR)}
            extraContent={
              activeReport && <MonthPicker activeMonth={startDate} />
            }
          />
        )}
        <div style={isMobile ? { padding: '1.5em 1em 0' } : undefined}>
          {reportReconciled && currentStatus && (
            <>
              <Text color="forest">
                This report was compiled on{' '}
                {moment
                  .utc(currentStatus.createdAt)
                  .format(DATE_FORMATS.DISPLAY_LONG)}
              </Text>
              <br />
              {reconciledReportAlert}
            </>
          )}
        </div>
        <br />
        <FreeTrialUnreviewedAlert />
        <br />
        {reportReconciled && (
          <Tab
            defaultActiveIndex={defaultActiveIndex}
            onTabClick={updateTab}
            menu={{ secondary: true, pointing: true }}
            panes={[
              {
                menuItem: 'Overview',
                render: () => reports,
              },
              {
                menuItem: 'Profit and Loss',
                render: () => profitsAndLoss,
              },
            ]}
          />
        )}
        {!reportReconciled && currentStatus && (
          <div style={{ marginTop: 24 }}>{bookkeepingIncompleteStatusText}</div>
        )}
      </div>
    </>
  )
}

export default BookkeepingReportPanel
