import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { fetchUserTaxCalculationsIfNeeded } from '../../../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import { Loader } from '../../../../../components/BaseComponents'
import { useAnalyticsView } from '../../../../Amplitude'
import { getActiveTaxEstimateWithinActiveQuarter } from '../../userTaxEstimates.selector'
import { fetchUserTaxEstimates } from '../../userTaxEstimates.slice'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { useReselector } from '../../../../../utils/sharedHooks'
import EstimateBreakdownV2 from './EstimateBreakdownV2'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import {
  BreakdownVersion,
  selectCalculatorVersionByTaxQuarter,
} from '../../../../../selectors/userTaxCalculation.selectors'
import DefaultBreakdown from './DefaultBreakdown'
import EstimateBreakdownV3Wrapper from './EstimateBreakdownV3Wrapper'

const EstimateBreakdown = () => {
  const { taxQuarter } = useParams()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)

  const dispatch = useAppDispatch()
  const pageView = useAnalyticsView()

  const estimateVersion = useReselector(
    selectCalculatorVersionByTaxQuarter,
    taxQuarter
  )
  const estimates = useReselector(getActiveTaxEstimateWithinActiveQuarter)
  const estimate = estimates.find((e) => e.filingState)

  useEffect(() => {
    async function batchFetch() {
      setLoading(true)
      await Promise.all([
        dispatch(fetchUserTaxEstimates()),
        dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded()),
        dispatch(fetchUserTaxCalculationsIfNeeded(true)),
      ])
      setLoading(false)
    }
    batchFetch()
  }, [dispatch])

  useEffect(() => {
    pageView('qte calc breakdown', {
      qte_quarter: estimate?.taxQuarter || 'undefined',
      qte_states: estimate?.filingState || 'undefined',
    })
  }, [pageView, estimate?.taxQuarter, estimate?.filingState])

  if (loading) {
    return <Loader />
  } else if (
    estimateVersion === BreakdownVersion.defaultBreakdown ||
    searchParams.get('section') === 'state'
  ) {
    return <DefaultBreakdown />
  } else if (estimateVersion === BreakdownVersion.version2) {
    return <EstimateBreakdownV2 />
  }

  return <EstimateBreakdownV3Wrapper />
}

export default EstimateBreakdown
