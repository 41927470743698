import { useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'

import {
  Button,
  Card,
  GridRowColumn,
  Label,
  Link,
  GridColumnSpacer,
  Text,
  Popup,
} from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../../utils/dateHelpers'
import { formatCurrencyFromCents } from '../../../../../utils/currencyHelpers'
import {
  selectFederalEstimateByTaxQuarter,
  selectStatusForTaxEstimate,
} from '../../userTaxEstimates.selector'
import UpdateQuarterlyTaxEstimateModal from '../UpdateQuarterlyTaxEstimateModal'
import { EstimateStatus } from '../../service'
import { ZeroEstimateTooltip } from './EstimateBreakdownV3Tooltips'
import useTaxPaymentInfoFlyout from '../TaxPaymentInfoFlyout'
import { UserTaxCalculation } from '../../../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import { QuarterlyTaxEstimateDetail } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'

const HowMuchShouldIPayRow = () => (
  <GridRowColumn>
    <Link
      href="https://support.joinheard.com/hc/en-us/articles/22050337435415-How-to-manually-calculate-quarterly-estimate-tax"
      newPage
      as="secondaryLink"
    >
      How much should I pay?
    </Link>
  </GridRowColumn>
)

const PaymentCard = ({
  taxCalc,
  estimateDetails,
  isSample,
}: {
  taxCalc: UserTaxCalculation | undefined
  estimateDetails: QuarterlyTaxEstimateDetail | undefined
  isSample?: boolean
}) => {
  const [estimateModalOpen, setEstimateModalOpen] = useState(false)
  const quarter = estimateDetails?.taxQuarter

  const federalTaxEstimate = useReselector(
    selectFederalEstimateByTaxQuarter,
    `${estimateDetails?.taxYear}-${estimateDetails?.taxQuarter}`
  )

  const openFlyout = useTaxPaymentInfoFlyout({
    estimateId: federalTaxEstimate?.id,
    openQTEModal: () => setEstimateModalOpen(true),
  })

  const estimateStatus = useReselector(
    selectStatusForTaxEstimate,
    federalTaxEstimate?.id
  )

  const shownStatus = isSample ? EstimateStatus.readyForPayment : estimateStatus

  const conditionalContent = useMemo(() => {
    switch (shownStatus) {
      // Payment card shouldn't display for these states
      case EstimateStatus.joinedTooLate:
      case EstimateStatus.upcoming:
      case EstimateStatus.awaitingEstimate:
        return null
      case EstimateStatus.unpaidWithResponse:
        return {
          label: <Label color="gray">Unpaid</Label>,
          timeText: 'Estimated Payment',
          ctaButtons: (
            <>
              <GridRowColumn>
                <Text>
                  You’ve indicated that you didn’t pay federal taxes this
                  quarter.
                </Text>
              </GridRowColumn>
              <HowMuchShouldIPayRow />
              <GridRowColumn>
                <Button
                  fullWidth
                  onClick={() => setEstimateModalOpen(true)}
                  variant="secondary"
                >
                  Update Payment
                </Button>
              </GridRowColumn>
            </>
          ),
        }
      case EstimateStatus.paid: {
        // There is a sort of sub status here however this is the only component that seems to treat it differently
        if (!federalTaxEstimate?.userDocumentId) {
          return {
            label: <Label color="orange">Action Needed</Label>,
            timeText: `Estimated Payment paid ${federalTaxEstimate?.paidAt && DateTime.fromISO(federalTaxEstimate.paidAt).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}`,
            ctaButtons: (
              <>
                <GridRowColumn>
                  <Text color="darkGray">
                    You’re missing a receipt for your payment.
                  </Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Button
                    fullWidth
                    onClick={() => setEstimateModalOpen(true)}
                    variant="secondary"
                  >
                    Update Receipt
                  </Button>
                </GridRowColumn>
              </>
            ),
          }
        }

        return {
          label: <Label color="green">Paid</Label>,
          timeText: `Estimated Payment paid ${federalTaxEstimate.paidAt && DateTime.fromISO(federalTaxEstimate.paidAt).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}`,
          ctaButtons: (
            <GridRowColumn>
              <Button
                fullWidth
                onClick={() => setEstimateModalOpen(true)}
                variant="secondary"
              >
                Update Payment
              </Button>
            </GridRowColumn>
          ),
        }
      }
      case EstimateStatus.noIncomeTax:
      case EstimateStatus.estimateIsZero:
        return {
          label: <Label color="gray">No Action Needed</Label>,
          timeText: 'Estimated Payment',
          ctaButtons: (
            <>
              <GridRowColumn short>
                <Text color="darkGray">You’re set! No action is needed.</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Popup
                  trigger={
                    <Button variant="tooltipLink">
                      Why don’t I owe anything?
                    </Button>
                  }
                  content={<ZeroEstimateTooltip />}
                />
              </GridRowColumn>
              <GridRowColumn>
                <Button
                  fullWidth
                  onClick={() => setEstimateModalOpen(true)}
                  variant="secondary"
                >
                  Update Payment
                </Button>
              </GridRowColumn>
            </>
          ),
        }
      case EstimateStatus.unpaidWithoutResponse:
        return {
          label: <Label color="orange">Action Needed</Label>,
          timeText: 'Estimated Payment',
          ctaButtons: (
            <>
              <GridRowColumn>
                <Text>
                  Please update your payment, even if you didn&apos;t make any
                  payments in Q{quarter}.
                  <br />
                  <br />
                  We need this to provide accurate estimates for next quarter.
                </Text>
              </GridRowColumn>
              <HowMuchShouldIPayRow />
              <GridRowColumn>
                <Button
                  fullWidth
                  onClick={() => setEstimateModalOpen(true)}
                  variant="secondary"
                >
                  Update Payment
                </Button>
              </GridRowColumn>
            </>
          ),
        }

      case EstimateStatus.readyForPayment:
        return {
          label: <Label color="orange">Ready for Payment</Label>,
          timeText: `Estimated Payment due ${estimateDetails?.irsPaymentDueAt && isoToUTCDateTime(estimateDetails.irsPaymentDueAt).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}`,
          ctaButtons: (
            <Grid.Row>
              <Grid.Column computer={8} tablet={8} mobile={16}>
                <Button fullWidth onClick={openFlyout} disabled={isSample}>
                  View & Pay
                </Button>
              </Grid.Column>
              <GridColumnSpacer mobile />
              <Grid.Column computer={8} tablet={8} mobile={16}>
                <Button
                  fullWidth
                  variant="secondary"
                  onClick={() => setEstimateModalOpen(true)}
                  disabled={isSample}
                >
                  Mark as Paid
                </Button>
              </Grid.Column>
            </Grid.Row>
          ),
        }
      default:
        return shownStatus satisfies never
    }
  }, [
    isSample,
    estimateDetails?.irsPaymentDueAt,
    federalTaxEstimate?.paidAt,
    federalTaxEstimate?.userDocumentId,
    openFlyout,
    quarter,
    shownStatus,
  ])

  if (
    !estimateDetails ||
    (!federalTaxEstimate && !isSample) ||
    (!taxCalc && !isSample) ||
    conditionalContent === null
  ) {
    return null
  }

  return (
    <>
      <Card backgroundColor="natural" type="subsection" unsetMobileMargin={-20}>
        <Grid>
          <GridRowColumn>{conditionalContent.label}</GridRowColumn>
          <GridRowColumn short>
            <Text as="display">
              {taxCalc?.federalQuarterlyEstimateInCents
                ? formatCurrencyFromCents(
                    taxCalc.federalQuarterlyEstimateInCents
                  )
                : '--'}
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="bodySm" color="darkGray">
              {conditionalContent.timeText}
            </Text>
          </GridRowColumn>
          <Grid.Row className="short" />
          {conditionalContent.ctaButtons}
        </Grid>
        {federalTaxEstimate && (
          <UpdateQuarterlyTaxEstimateModal
            open={estimateModalOpen}
            close={() => setEstimateModalOpen(false)}
            estimate={federalTaxEstimate}
          />
        )}
      </Card>
      <Text color="darkGray">
        Updated on{' '}
        {taxCalc?.calculatedAt &&
          DateTime.fromISO(taxCalc.calculatedAt).toFormat(
            DATE_FORMATS_LUXON.DISPLAY_TIMEZONE
          )}
      </Text>
      <br />
      <br />
    </>
  )
}

export default PaymentCard
