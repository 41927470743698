import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import {
  useFetchResponse,
  useGetEndDateForFinancialInsights,
  useReselector,
} from '../../../utils/sharedHooks'
import {
  FETCH_INSIGHTS_UNCATEGORIZED_TRANSACTIONS_KEY,
  fetchInsightsUncategorizedTransactionsData,
} from './financeActions'
import { Alert, GridRowColumn, Link, Loader, Text } from '../../BaseComponents'
import { formatCurrencyFromCents } from '../../../utils/currencyHelpers'
import { getIsFetching } from '../../../reducers/fetch'
import { isFreeTrialPromoCode } from '../../../selectors/user.selectors'

export const UncategorizedTransactionBanner = ({
  inputDate,
}: {
  inputDate?: string
}) => {
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  const currentDate = inputDate
    ? DateTime.fromFormat(inputDate, DATE_FORMATS_LUXON.INPUT)
    : DateTime.now()

  const getEndDate = useGetEndDateForFinancialInsights(currentDate)

  const { endDate, month, year } = useMemo(() => {
    const endDate = getEndDate()

    return {
      endDate: endDate.toFormat(DATE_FORMATS_LUXON.TIMESTAMP),
      month: endDate.toFormat('LLLL'),
      year: endDate.year,
    }
  }, [getEndDate])

  const uncategorizedTransactionData = useFetchResponse(
    fetchInsightsUncategorizedTransactionsData,
    endDate
  )

  const isLoading = useReselector(
    getIsFetching,
    FETCH_INSIGHTS_UNCATEGORIZED_TRANSACTIONS_KEY
  )

  if (isLoading)
    return (
      <GridRowColumn>
        <Loader loading />
      </GridRowColumn>
    )

  if (!uncategorizedTransactionData?.total_transactions) return null

  const linkTo = uncategorizedTransactionData?.needs_review
    ? '/transactions/review'
    : '/transactions?categoryId=-1'
  const linkText = uncategorizedTransactionData?.needs_review
    ? 'Review Transactions'
    : 'View Transactions'

  const secondSentenceText = () => {
    if (hasFreeTrialPromoCode) {
      return 'Categorize your transactions to include them.'
    } else {
      return `These numbers might
          change after our bookkeeping team has categorized them. 
          ${
            uncategorizedTransactionData?.needs_review &&
            'There are some transactions that need your review.'
          }`
    }
  }
  return (
    <GridRowColumn>
      <Alert>
        <Text>
          You have{' '}
          <b>
            {uncategorizedTransactionData.total_transactions} uncategorized
            transaction(s)
          </b>{' '}
          (totaling{' '}
          {formatCurrencyFromCents(
            uncategorizedTransactionData.total_amount_in_cents
          )}
          ) up to {month} {year} not included on this page.{' '}
          {secondSentenceText()}
        </Text>
        <Link to={linkTo}>{linkText}</Link>
      </Alert>
    </GridRowColumn>
  )
}
