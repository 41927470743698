import { useEffect } from 'react'
import {
  getCurrentUser,
  selectMembershipIsIn,
} from '../selectors/user.selectors'
import { updateUser } from '../actions/userActions'
import { SIGNUP_PATHS } from '../features/Signup/helpers'
import {
  useNavigateWithPersistParams,
  useReselector,
} from '../utils/sharedHooks'
import { useAppDispatch } from '../utils/typeHelpers'
import { MembershipScopes } from '../reducers/auth/userReducer'

// This updates the current signup page for a user.  Split out here to cut down on shared code
export const useSignupPageUpdate = (page: string, isUpsell?: boolean) => {
  const dispatch = useAppDispatch()
  const currentUser = useReselector(getCurrentUser)

  useEffect(() => {
    if (currentUser?.id && !isUpsell) {
      dispatch(updateUser(currentUser.id, { lastOnboardingScreen: page }))
    }
  }, [currentUser?.id, dispatch, page, isUpsell])
}

export const searchParamsToRemove = ['event', 'source']

// Called by AnalyticsProvider and Signup steps
// Removing from the url prevents Amplitude events from firing multiple times
export const removeAmplitudeTrackingParams = () => {
  const urlParams = new URLSearchParams(window.location.search)

  for (const param of searchParamsToRemove) {
    urlParams.delete(param)
  }

  if ([...urlParams.keys()].length > 0) {
    // Preserve other params
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}?${urlParams.toString()}${
        window.location.hash
      }`
    )
  } else {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname + window.location.hash
    )
  }
}

// Built for the landing page of signup.  This navigates the user to the last page they were at on the previous session
export const useSignupLastPageNavigate = () => {
  const navigate = useNavigateWithPersistParams()
  const user = useReselector(getCurrentUser)
  const activeMembership = useReselector(
    selectMembershipIsIn,
    MembershipScopes.allActiveIncludingUnpaid
  )

  useEffect(() => {
    // If the user is active already navigate them to the dashboard
    if (activeMembership) {
      if (user?.admin) {
        navigate('/admin/finances')
      } else {
        navigate('/')
      }
      return
    }

    const lastScreen = user?.lastOnboardingScreen
    // If there IS a user they are on the wrong screen because they have already signed up
    if (lastScreen) {
      if (
        // Check if there is a bad value of `lastOnboardingScreen`
        user.lastOnboardingScreen &&
        Object.values(SIGNUP_PATHS).includes(user.lastOnboardingScreen) &&
        // The about page is never actually set but do a check for it anyway or there will be an infinite loop
        lastScreen !== SIGNUP_PATHS.aboutYou
      ) {
        navigate(lastScreen)
        return
      }

      // If the signup page can't be found or the about page is the about page navigate the second signup screen
      navigate(SIGNUP_PATHS.aboutYourPractice)
    }
  }, [navigate, user?.lastOnboardingScreen, user?.admin, activeMembership])
}
