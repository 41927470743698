import { Grid, Image } from 'semantic-ui-react'
import { Button, Icon, Text } from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import { Colors } from '../../../../styles/theme'
import {
  useIsDeviceWidth,
  DeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import styled from 'styled-components'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'

const StyledHeader = styled.div`
  display: flex;
  gap: 64px;
  padding-right: 64px;
  justify-content: space-between;
  &.mobile {
    flex-direction: column;
    gap: 32px;
    padding-right: 0px;
  }
  padding-bottom: 40px;
  width: 100%;
`
const StyledGrid = styled(Grid)<{
  mobile: boolean
}>`
  background-color: ${(props) =>
    props.completedOrSkipped ? Colors.stone40 : Colors.natural};
  border-radius: 8px;
  padding: ${(props) => (props.mobile ? '16px' : '12px 24px')}!important;
  .item-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 0 0;
  }
  .close-icon-wrapper {
    display: flex;
    justify-content: ${(props) => (props.mobile ? 'space-between' : 'center')};
    align-items: center;
    gap: 20px;
  }
  .ui.image {
    width: ${(props) => (props.mobile ? '45px' : '80px')};
  }
  &&&.ui.grid {
    margin: 0;
  }
`
const FreeTrialOBItemsList = () => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const navigate = useNavigate()
  return (
    <>
      <StyledGrid doubling stackable mobile={isMobile}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={3} textAlign="center">
            <div className="close-icon-wrapper">
              <Image
                src={
                  'https://heard-images.s3.amazonaws.com/assets/books_lightShadow.png'
                }
                size="tiny"
              />
            </div>
          </Grid.Column>
          <Grid.Column width={10}>
            <div className="item-content">
              <Text as="h3">Bookkeeping</Text>
              <br />
              <Text as="bodySm">
                Learn why bookkeeping matters, how we help you save time, and
                what you need to do on a monthly basis.
              </Text>
              <Text as="bodyXs" color="oak">
                <Icon icon={regular('timer')} style={{ marginRight: 4 }} />5
                minutes
              </Text>
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div className="close-icon-wrapper">
              <Button
                onClick={() => navigate('/onboarding-v2/learning/bookkeeping')}
                variant="primary"
                size="medium"
              >
                Review
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
      <br />
      <StyledGrid doubling stackable mobile={isMobile}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={3}>
            <div className="close-icon-wrapper">
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/tax_form.svg"
                size="tiny"
              />
            </div>
          </Grid.Column>
          <Grid.Column width={10}>
            <div className="item-content">
              <Text as="h3">Quarterly Tax Estimates</Text>
              <br />
              <Text as="bodySm">
                While we cannot offer you a payment estimate during this free
                trial, you will be able to review a sample of what this
                experience looks like.
              </Text>
              <Text as="bodyXs" color="oak">
                <Icon icon={regular('timer')} style={{ marginRight: 4 }} />5
                minutes
              </Text>
            </div>
          </Grid.Column>
          <Grid.Column width={3} floated="right">
            <div className="close-icon-wrapper">
              <Button
                onClick={() => navigate('/taxes/quarterly')}
                variant="primary"
                size="medium"
              >
                Review
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
      <br />

      <StyledGrid doubling stackable mobile={isMobile}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={3}>
            <div className="close-icon-wrapper">
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/insights_illustration.svg"
                size="tiny"
              />
            </div>
          </Grid.Column>
          <Grid.Column width={10}>
            <div className="item-content">
              <Text as="h3">Review your Income and Expenses</Text>
              <br />
              <Text as="bodySm">
                Take a peek at your income and expenses trends.
              </Text>
              <Text as="bodyXs" color="oak">
                <Icon icon={regular('timer')} style={{ marginRight: 4 }} />5
                minutes
              </Text>
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div className="close-icon-wrapper">
              <Button
                onClick={() => navigate('/insights/income')}
                variant="primary"
                size="medium"
              >
                Review
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
    </>
  )
}
const FreeTrialGettingStarted = () => {
  const currentUser = useReselector(getCurrentUser)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  // Show different GetStarted Dashboard for Free Trial Users
  return (
    <>
      <StyledHeader className={`${isMobile && 'mobile'}`}>
        <div>
          <Text as="eyebrow" style={{ color: Colors.forest, paddingBottom: 8 }}>
            Welcome {currentUser?.firstName}!
          </Text>
          <Text as="display2">Explore Heard</Text>
          <br />
          <Text as="bodyMd">
            Manage your bookkeeping, taxes, and accounting in one powerful
            platform, surpassing DIY methods or traditional accounting services.
            Built for therapist entrepreneurs, our expert guidance and
            innovative tools save you time and money while empowering you to
            build the practice of your dreams.
          </Text>
        </div>
        {!isMobile && (
          <Image src="https://heard-images.s3.amazonaws.com/assets/ledger-and-coffee.svg" />
        )}
      </StyledHeader>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          //  gap: '40px',
        }}
      >
        <Text as="eyebrow">Learn more about Heard</Text>
        <br />
        <FreeTrialOBItemsList />
      </div>
    </>
  )
}
export default FreeTrialGettingStarted
